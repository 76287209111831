import { clearLocalForageValue, getLocalForageValue, setLocalForageValue } from './localforage';

const DB_KEY = 'Face-session';

export const getStoredSession = async () => {
  try {
    const session = await getLocalForageValue(DB_KEY);
    if (typeof session === 'string') return session;
    return null;
  } catch (err) {
    console.debug('getStoredSession error', err);
    return null;
  }
};

export const storeSession = (session: string) => {
  setLocalForageValue(DB_KEY, session);
};

export const clearStoredSession = () => {
  clearLocalForageValue(DB_KEY);
};
