import FullHeightTable from '@/components/FullHeightTable';

import { DOCS_URLS } from '@/constants/urls';
import useAlert from '@/hooks/layouts/useAlert';
import { useGetDappNFTs, useDeleteDappNFT } from '@/hooks/queries/nfts';
import useAuthorizedRoutePaths from '@/hooks/useAuthorizedRoutePaths';
import useBlockchainNetworks from '@/hooks/useBlockchainNetworks';
import useModal from '@/hooks/useModal';
import ContentLayout from '@/layouts/ContentLayout';
import DeleteNftModal from '@/modals/DeleteNftModal';
import RegisterNftModal from '@/modals/RegisterNftModal';
import { NFTContractDTO } from '@/__generate__/dashboard-api';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { colors, Flexbox, IconExternalLink, IconTrash } from '@haechi-labs/face-design-system';
import { Network } from '@haechi-labs/face-types';
import { Button, Divider } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDappByIdQuery } from '@/hooks/queries/dapps';
import GuideGenerateCredentialModal from '@/modals/GuideGenerateCredentialModal';
import invariant from 'tiny-invariant';
import placeholderThumbnail from '@/assets/imgs/placeholder-thumbnail.svg';

const NftPage = () => {
  const { dappId = '' } = useParams();
  const { showAlert } = useAlert();
  const { data: dapp } = useDappByIdQuery(dappId);
  const authorizedRoutePath = useAuthorizedRoutePaths();
  const { open, openModal, closeModal } = useModal();
  const {
    open: isOpenGuideGenerateCredentialModal,
    openModal: openGuideGenerateCredentialModal,
    closeModal: closeGuideGenerateCredentialModal,
  } = useModal();
  const { data: originNfts = [], isFetching } = useGetDappNFTs(dappId);
  const { blockchainNetworkMap } = useBlockchainNetworks(dapp?.apiKey ?? '');
  const nfts = originNfts
    .filter((nft) => {
      // blockchainNetwork가 대문자로 오고 있어서 소문자로 변경함
      const lowerNetwork = nft.blockchainNetwork?.toLowerCase() as Network;
      if (!blockchainNetworkMap[lowerNetwork]) {
        console.error(`${lowerNetwork} is not supported network`);
      }
      return blockchainNetworkMap[lowerNetwork];
    })
    .map((nft) => {
      return {
        ...nft,
        blockchainNetwork: nft.blockchainNetwork?.toLowerCase() as Network,
      };
    });

  const {
    open: isOpenDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const { mutate: deleteDappNFT } = useDeleteDappNFT();
  const [removeTargetNft, setRemoveTargetNft] = useState<NFTContractDTO>();

  const handleRemoveClick = () => {
    if (removeTargetNft) {
      deleteDappNFT(
        {
          id: dappId,
          blockchainNetwork: (removeTargetNft as any).blockchainNetwork,
          contractAddress: removeTargetNft.contractAddress,
        },
        {
          onSuccess: () => {
            showAlert({
              type: 'success',
              target: 'contents',
              message: 'NFT deleted successfully.',
            });
            closeDeleteModal();
          },
        }
      );
    }
  };

  const columns: ColumnsType<NFTContractDTO> = [
    {
      title: 'Blockchain Network',
      key: 'blockchain',
      ellipsis: true,
      width: 240,
      render: (value: NFTContractDTO) => {
        const { blockchainNetwork } = value;
        invariant(blockchainNetwork);
        const network = blockchainNetworkMap[blockchainNetwork as Network];
        return (
          <div>
            <img
              src={network.icon}
              alt={`${network.name}-icon`}
              width={20}
              style={{ marginRight: '8px' }}
              onError={(e) => {
                e.currentTarget.src = placeholderThumbnail;
              }}
            />
            {network.name}
          </div>
        );
      },
    },
    {
      title: 'Token Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Token Standard',
      dataIndex: 'type',
      key: 'type',
      width: 146,
      render: (value: string) => value.toUpperCase(),
    },
    {
      title: 'Smart Contract Address',
      dataIndex: 'contractAddress',
      key: 'contractAddress',
      ellipsis: true,
    },
    {
      title: 'Delete',
      key: 'delete',
      width: 80,
      render: (nft: NFTContractDTO) => {
        return (
          <Button
            type="text"
            icon={<IconTrash />}
            onClick={() => {
              setRemoveTargetNft(nft);
              openDeleteModal();
            }}
          />
        );
      },
    },
  ];

  if (!dappId) {
    return <Navigate to={authorizedRoutePath.allDappsPage} />;
  }

  return (
    <ContentLayout>
      <FullHeightTable
        cardTitle={
          <>
            NFTs
            <Divider type="vertical" />
            <Button
              type="text"
              size="small"
              target="_blank"
              href={DOCS_URLS.nfts}
              style={{ marginLeft: '-4px' }}>
              <Flexbox gap={4}>
                Docs
                <IconExternalLink fill={colors.gray[600]} size="sm" />
              </Flexbox>
            </Button>
          </>
        }
        cardExtra={
          <Button
            type="text"
            icon={<PlusOutlined />}
            size="small"
            onClick={() => {
              dapp?.apiKey ? openModal() : openGuideGenerateCredentialModal();
            }}>
            Register NFT
          </Button>
        }
        locale={{ emptyText: 'Only registered NFTs will be shown in the user wallet.' }}
        columns={columns}
        dataSource={nfts.map((nft) => ({
          ...nft,
          key: nft.id,
        }))}
        loading={isFetching}
      />

      <RegisterNftModal
        id={dappId || ''}
        open={open}
        onCancel={closeModal}
        closeModal={closeModal}
      />

      <DeleteNftModal
        open={isOpenDeleteModal}
        onCancel={closeDeleteModal}
        onOk={handleRemoveClick}
      />

      <GuideGenerateCredentialModal
        open={isOpenGuideGenerateCredentialModal}
        onCancel={closeGuideGenerateCredentialModal}
        desc="You can register your NFT only AFTER generating the API Credentials."
      />
    </ContentLayout>
  );
};

export default NftPage;
