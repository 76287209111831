import { colors, typography } from '@haechi-labs/face-design-system';
import styled from '@emotion/styled';
import { tokenQuery } from '@/hooks/useFaceQuery.queries';
import { Network } from '@haechi-labs/face-types';
import { ReactComponent as CheckIcon } from '@/components/CheckIcon.svg';

export function AvailableTokenFunctions({
  network,
  contractAddress,
}: {
  network: Network;
  contractAddress: string;
}) {
  const supportConvert = tokenQuery.tokenSupportConvert(network);
  const supportSwap = tokenQuery.tokenSupportSwap(network);
  const supportOnRamp = tokenQuery.tokenSupportOnRamp(network);

  return (
    <AvailableTokenFunctionsContainer>
      <AvailableTokenFunction data-active={supportSwap}>
        Swap
        {supportSwap && <CheckIcon />}
      </AvailableTokenFunction>
      <AvailableTokenFunction data-active={supportConvert}>
        Bridge
        {supportConvert && <CheckIcon />}
      </AvailableTokenFunction>
      <AvailableTokenFunction data-active={supportOnRamp}>
        On-Ramp {supportOnRamp && <CheckIcon />}
      </AvailableTokenFunction>
    </AvailableTokenFunctionsContainer>
  );
}

const AvailableTokenFunctionsContainer = styled.div`
  background-color: #dce1e83d;
  display: flex;
  height: 44px;
`;

const AvailableTokenFunction = styled.div`
  flex: 1;
  flex-shrink: 0;
  ${typography.body2.medium};
  color: ${colors.gray[300]};
  padding-inline: 12px;
  display: flex;
  align-items: center;
  user-select: none;

  &[data-active='true'] {
    color: ${colors.gray[600]};
  }
`;
