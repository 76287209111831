import Modal, { ModalProps } from 'antd/lib/modal';
import NotificationBox from '@/components/NotificationBox';
import { Spacer } from '@haechi-labs/face-design-system';

const RollKeyModal = (props: ModalProps) => {
  return (
    <Modal title="Roll Key?" okText="Roll Key" centered okType="danger" closable={false} {...props}>
      Your API Credentials will be rolled. Your existing API Key, API Secret will become immediately
      useless. This cannot be undone.
      <Spacer y={16} />
      <NotificationBox
        type="error"
        title="Are you using Unity SDK that's a lower version than 1.3.12?">
        <ul>
          <li>
            Once you Roll Key, apps linked to Unity SDK version that's lower than 1.3.12 will not be
            able to support Facewallet.
          </li>
          <li>
            If you're using Unity SDK version that's lower than 1.3.12, please migrate all live apps
            to version 1.3.12 or higher before Roll Key.
          </li>
        </ul>
      </NotificationBox>
    </Modal>
  );
};
export default RollKeyModal;
