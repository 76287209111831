import { Blockchain } from '@haechi-labs/face-types';

export function getMoonPayCoinCode(blockchain: Blockchain, contractAddress = '0x0') {
  const result = Object.entries(supportedCoinMap).find(
    ([, coin]) => coin.blockchain === blockchain && coin.contractAddress === contractAddress
  );

  return result ? result[0].toLowerCase() : undefined;
}

export function getCoinByMoonPayCoinCode(code: string) {
  return supportedCoinMap[code];
}

export function isSupportedCoin(blockchain: Blockchain, contractAddress = '0x0') {
  return !!getMoonPayCoinCode(blockchain, contractAddress);
}

export const supportedCoinMap: {
  [key: string]: {
    blockchain: Blockchain;
    contractAddress: string;
  };
} = {
  /****** ETHEREUM ******/
  ETH: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0x0', // Platform Coin
  },
  DAI: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  },
  IMX: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF',
  },
  LINK: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
  },
  MATIC: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
  },
  MKR: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
  },
  SAND: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
  },
  UNI: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  },
  USDC: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  },
  USDT: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  },
  WBTC: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  },
  WETH: {
    blockchain: Blockchain.ETHEREUM,
    contractAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  },

  /****** POLYGON ******/
  MATIC_POLYGON: {
    blockchain: Blockchain.POLYGON,
    contractAddress: '0x0', // Platform Coin
  },
  ETH_POLYGON: {
    blockchain: Blockchain.POLYGON,
    contractAddress: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  USDC_POLYGON: {
    blockchain: Blockchain.POLYGON,
    contractAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  USDT_POLYGON: {
    blockchain: Blockchain.POLYGON,
    contractAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },

  /****** ETC ******/
  APT: {
    blockchain: Blockchain.APTOS,
    contractAddress: '0x0', // Platform Coin
  },
  BNB_BSC: {
    blockchain: Blockchain.BNB_SMART_CHAIN,
    contractAddress: '0x0', // Platform Coin
  },
  BUSD_BSC: {
    blockchain: Blockchain.BNB_SMART_CHAIN,
    contractAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  KLAY: {
    blockchain: Blockchain.KLAYTN,
    contractAddress: '0x0', // Platoform Coin
  },
  NEAR: {
    blockchain: Blockchain.NEAR,
    contractAddress: '0x0', // Platform Coin
  },
  SOL: {
    blockchain: Blockchain.SOLANA,
    contractAddress: '0x0', // Platform Coin
  },
};
