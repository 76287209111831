import useResize from '@/hooks/useResize';
import Card, { CardProps } from 'antd/lib/card';
import Table, { TableProps } from 'antd/lib/table';
import { useEffect, useRef, useState } from 'react';
import { Text } from '@haechi-labs/face-design-system';
import FullScreenSpinner from '../FullScreenLoader';

export interface FullHeightTableProps extends TableProps<any> {
  cardTitle: CardProps['title'];
  cardExtra: CardProps['extra'];
}

const FullHeightTable = ({ cardTitle, cardExtra, ...props }: FullHeightTableProps) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const [maxHeight, setMaxHeight] = useState(0);
  const PADDING = 32;

  const getHeight = () => {
    const tableHeaderHeight =
      cardRef.current?.getElementsByClassName('ant-table-header')[0]?.clientHeight || 0;
    const tablePaginatorHeight =
      cardRef.current?.getElementsByClassName('ant-pagination')[0]?.clientHeight || 0;

    const cardHeight = cardRef.current?.clientHeight || 0;
    const cardHeaderHeight =
      cardRef.current?.getElementsByClassName('ant-card-head')[0]?.clientHeight || 0;

    return cardHeight - cardHeaderHeight - tableHeaderHeight - tablePaginatorHeight - PADDING;
  };

  useResize(() => {
    setMaxHeight(getHeight());
  });

  useEffect(() => {
    setMaxHeight(getHeight());
  }, []);

  return (
    <Card ref={cardRef} className="full-height" title={cardTitle} extra={cardExtra}>
      <Table
        ref={tableRef}
        className="full-height"
        pagination={{
          size: 'small',
          position: ['bottomCenter'],
          showSizeChanger: false,
          pageSize: 50,
          showTotal: (total) => (
            <Text type="body2" weight="medium">{`Total ${total} ${
              total > 1 ? 'items' : 'item'
            }`}</Text>
          ),
        }}
        {...props}
        loading={
          typeof props.loading === 'boolean'
            ? { indicator: <FullScreenSpinner />, spinning: props.loading }
            : props.loading
        }
        scroll={{ y: maxHeight }}
      />
    </Card>
  );
};

export default FullHeightTable;
