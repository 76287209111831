import { ReactNode } from 'react';
import Label from '../Label';
import { Container } from './index.styled';

interface BoxProps {
  children?: ReactNode;
  title?: ReactNode;
}
const Box = ({ title, children }: BoxProps) => {
  return (
    <>
      {title && <Label type="bold">{title}</Label>}
      <Container>{children}</Container>
    </>
  );
};

export default Box;
