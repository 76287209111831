import { SIDEBAR_WIDTH } from '@/constants/layout';
import { css } from '@emotion/react';
import { colors } from '@haechi-labs/face-design-system';

const LayoutStyle = css`
  .ant-layout {
    .ant-layout-header {
      background: ${colors.white};
      padding: 0;
      padding-left: 24px;
      padding-right: 24px;
    }

    .sidebar.ant-menu-submenu-popup {
      width: ${SIDEBAR_WIDTH}px;
    }
  }
`;
export default LayoutStyle;
