import MessageButton from '@/components/MessageButton';
import NotificationBox from '@/components/NotificationBox';
import useAlert from '@/hooks/layouts/useAlert';
import { useRollApiKey } from '@/hooks/queries/dapps';
import useCurrentDappId from '@/hooks/stores/useCurrentDappId';
import useErrorMessage from '@/hooks/useErrorMessage';
import { copyText } from '@/utils/copy';
import { generateV1ApiKey } from '@/utils/encrypt';
import { Flexbox, IconCopy, IconKey, Spacer, Text } from '@haechi-labs/face-design-system';
import Modal, { ModalProps } from 'antd/lib/modal';
import { MouseEvent, useMemo, useState } from 'react';

const GenerateKeyModal = ({ onOk, ...props }: ModalProps) => {
  const currentDappId = useCurrentDappId();
  const [isCopied, setIsCopied] = useState(false);
  const { showAlert } = useAlert();
  const { mutate: rollApiKey, isLoading } = useRollApiKey();
  const { getErrorMessage } = useErrorMessage();
  const { apiKey, secret } = useMemo(() => {
    return generateV1ApiKey();
  }, []);

  // TODO: use `generateV2ApiKey()`(like below) instead of `generateV1ApiKey()` after front task done
  // const [keyInfo, setKeyInfo] = useState({ version: '', apiKey: '', secret: '' });
  //
  // useEffect(() => {
  //   const generateKey = async () => {
  //     const keyData = await generateV2ApiKey();
  //     setKeyInfo(keyData);
  //   };
  //
  //   generateKey();
  // }, []);
  //
  // const { version, apiKey, secret } = keyInfo;

  const handleClickCopy = () => {
    copyText(secret);
    setIsCopied(true);
    showAlert({
      type: 'success',
      target: 'global',
      message: 'Successfully copied the API Secret.',
      showIcon: true,
    });
  };

  const handleClickConfirm = (e: MouseEvent<HTMLButtonElement>) => {
    if (!apiKey) throw new Error('API key is not created yet.');
    rollApiKey(
      { id: currentDappId, apiKey },
      {
        onSuccess: () => {
          onOk?.(e);
          showAlert({
            type: 'success',
            target: 'contents',
            message: 'Successfully Rolled API Credentials.',
            showIcon: true,
          });
        },
        onError: (err) => {
          props.onCancel?.(e);
          showAlert({
            type: 'error',
            target: 'global',
            message: getErrorMessage(err),
            showIcon: true,
          });
        },
      }
    );
  };

  return (
    <Modal
      title="Copy the API Secret"
      okText="Confirm"
      centered
      okType="primary"
      closable={false}
      maskClosable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ disabled: !isCopied || isLoading, loading: isLoading }}
      onOk={handleClickConfirm}
      {...props}>
      <Flexbox gap={4}>
        <IconKey size="sm" />
        <Text type="body2" weight="bold">
          API Secret
        </Text>
      </Flexbox>
      <Spacer y={8} />
      <MessageButton icon={<IconCopy size="sm" />} onClick={handleClickCopy}>
        API Secret has been generated.
        <br />
        Please copy it and store in a safe place.
      </MessageButton>
      <Spacer y={16} />
      <NotificationBox type="error" title="Caution">
        <ul>
          <li>
            The API Secret can only be copied from this screen. Please be cautious as to not lose
            the API Secret.
          </li>
          <li>Copying the API Secret activates the Confirm button.</li>
          <li>
            If you lose the API Secret, you can renew it with the Roll Key button. (The API Key will
            also change)
          </li>
        </ul>
      </NotificationBox>
    </Modal>
  );
};

export default GenerateKeyModal;
