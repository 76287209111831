import RoutePath from '@/constants/routes';
import { useSubscribeAlertEvent } from '@/hooks/layouts/useAlert';
import useSession from '@/hooks/useSession';
import { GlobalAlertContainer } from '@/layouts/DefaultLayout/index.styled';
import { LoginPage } from '@/pages/LoginPage';
import { LoginEmailLockedPage } from '@/pages/LoginPage/LoginEmailLockedPage';
import { LoginEmailPage } from '@/pages/LoginPage/LoginEmailPage';
import { LoginEmailVerifyPage } from '@/pages/LoginPage/LoginEmailVerifyPage';
import { Alert } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';

const UnAuthorizedRoutes = () => {
  // Alert
  const { currentAlertEvent: currentGlobalAlertEvent } = useSubscribeAlertEvent('global');

  // 세션 있으면 홈으로 이동
  const { session } = useSession();
  if (session) {
    return <Navigate to={RoutePath.allDappsPage} />;
  }

  return (
    <>
      <GlobalAlertContainer>
        {currentGlobalAlertEvent && <Alert {...currentGlobalAlertEvent} />}
      </GlobalAlertContainer>

      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/email" element={<LoginEmailPage />} />
        <Route path="/login/email/verify" element={<LoginEmailVerifyPage />} />
        <Route path="/login/email/locked" element={<LoginEmailLockedPage />} />
      </Routes>
    </>
  );
};

export default UnAuthorizedRoutes;
