import {
  DEV_TESTNET,
  LOCAL_TESTNET,
  PRODUCT_MAINNET,
  PRODUCT_TESTNET,
  STAGING_MAINNET,
  STAGING_TESTNET,
} from '@/constants/urls';
import { Env, Network } from '@haechi-labs/face-types';

export const getEnv = () => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case PRODUCT_MAINNET:
      return Env.ProdMainnet;
    case PRODUCT_TESTNET:
      return Env.ProdTest;
    case STAGING_MAINNET:
      return Env.StageMainnet;
    case STAGING_TESTNET:
      return Env.StageTest;
    case LOCAL_TESTNET:
      return Env.Local;
    case DEV_TESTNET:
    default:
      // 멀티 스테이징 환경
      if (hostname.includes('.facewallet-test.xyz')) {
        return Env.StageTest;
      }
      return Env.Dev;
  }
};

export const getNetwork = () => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case PRODUCT_MAINNET:
    case STAGING_MAINNET:
      return Network.ETHEREUM;
    case PRODUCT_TESTNET:
    case STAGING_TESTNET:
    case LOCAL_TESTNET:
    case DEV_TESTNET:
    default:
      return Network.SEPOLIA;
  }
};

export const getIframeUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('.facewallet-test.xyz')) {
    return 'https://' + hostname.replace('face-dashboard', 'face-iframe');
  }
  return null;
};
