import styled from '@emotion/styled';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
  background: ${colors.gray['200-24%']};
  padding: 12px;
  color: ${colors.gray[600]};
  border-radius: ${radius.xs};
  ${typography.body2.medium};
`;

export const Label = styled.div`
  text-align: left;
`;

export const Value = styled.div`
  text-align: right;
`;
