export const UnAuthorizedRoutePath = {
  loginPage: '/auth/login',
  loginEmailPage: '/auth/login/email',
  loginEmailVerifyPage: '/auth/login/email/verify',
  loginEmailLockedPage: '/auth/login/email/locked',
} as const;

export const AuthorizedRoutePath = {
  allDappsPage: '/',
  ':dappId': {
    dappDetailPage: '',
    tokenPage: '/token',
    nftPage: '/nft',
    userInsightsPage: '/user-insights',
  },
} as const;

const RoutePath = {
  ...AuthorizedRoutePath,
  ...UnAuthorizedRoutePath,
};

export default RoutePath;
