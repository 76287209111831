import styled from '@emotion/styled';
import { colors, typography } from '@haechi-labs/face-design-system';

export const ErrorMessage = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 4px;
  color: ${colors.red[600]};
  ${typography.caption1.regular};
`;
