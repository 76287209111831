import { DashboardApiError } from '@/types/axios';
import { isAxiosError } from 'axios';

const allErrorMessages = {
  MA0001: 'This credential has already been associated with a different Domain.',
  MA0003: 'This credential has already been associated with a different App ID.',
  MA0011: 'This is already registered.',
  MA0012: 'This is not an ERC20 contract.',
  MA0013: 'This is not an ERC20 contract.',
  MA0014: 'This is not an ERC20 contract.',
  MA0015: 'This is not a valid contract address format.',
  MA0016: 'This is not an ERC721 contract.',
  MA0017: 'NFTs without metadata names are not supported.',
  MA0018: 'This is not an ERC1155 contract.',
  MA0019: 'This is already registered.',
};

type CodeMap = { [key in string]: string };

const getMessageByCode = (code: string = '', overwriteCode?: CodeMap) => {
  const codeMap = { ...allErrorMessages, ...overwriteCode };
  const keys = new Set(Object.keys(codeMap));
  if (keys.has(code)) {
    return codeMap[code as keyof typeof codeMap];
  }
  return null;
};

const useErrorMessage = (overwriteCode?: CodeMap) => {
  const getErrorMessage = (error: any, defaultMessage: string = 'Unknown error') => {
    if (!error) return defaultMessage;
    if (typeof error === 'string') return error || defaultMessage;

    if (error?.cause?.code) {
      return getMessageByCode((error as any)?.cause?.code, overwriteCode) || defaultMessage;
    }

    if (error?.response && isAxiosError(error)) {
      const code = (error?.response?.data as DashboardApiError).code;
      return getMessageByCode(code, overwriteCode) || defaultMessage;
    }

    if (!error?.response) return defaultMessage;
    return error?.message || defaultMessage;
  };

  return { getErrorMessage, allErrorMessages };
};
export default useErrorMessage;
