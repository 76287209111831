import { Network } from '@haechi-labs/face-types';

export const getTokenType = (network: Network) => {
  switch (network) {
    case Network.TEZOS:
    case Network.GHOSTNET:
      return `fa2`;
    case Network.ETHEREUM:
    case Network.SEPOLIA:
    case Network.POLYGON:
    case Network.AMOY:
    case Network.BNB_SMART_CHAIN:
    case Network.BNB_SMART_CHAIN_TESTNET:
    case Network.KLAYTN:
    case Network.BAOBAB:
    case Network.BORA:
    case Network.BORA_TESTNET:
    case Network.MEVERSE:
    case Network.MEVERSE_TESTNET:
    case Network.PSM:
    case Network.PSM_TESTNET:
    case Network.PSM_TESTNET_TEST:
    case Network.PSM_TESTNET_DEV:
    case Network.HOME_VERSE:
    case Network.HOME_VERSE_TESTNET:
    case Network.YOOLDO_VERSE:
    case Network.YOOLDO_VERSE_TESTNET:
    case Network.SAND_VERSE:
    case Network.OASYS:
    case Network.OASYS_TESTNET:
    case Network.MCH_VERSE:
    case Network.MCH_VERSE_TESTNET:
    case Network.HEDERA:
    case Network.HEDERA_TESTNET:
    case Network.DEFI_VERSE_TESTNET:
    case Network.DEFI_VERSE:
    case Network.ASM_DEV:
    case Network.ASM_TEST:
    case Network.ASM_QA:
    case Network.ASM_STAGE:
    case Network.LITH:
    case Network.KROMA:
    case Network.KROMA_SEPOLIA:
    case Network.LINEA:
    case Network.LINEA_GOERLI:
      // case Network.ASM:
      return `erc20`;
    default:
      throw new Error(`${network} is not support token`);
  }
};

type TokenType = 'erc20' | 'erc721' | 'erc1155' | 'fa2';

export const getNftTypes = (network: Network): TokenType[] => {
  switch (network) {
    case Network.TEZOS:
    case Network.GHOSTNET:
      return ['fa2'];
    case Network.ETHEREUM:
    case Network.SEPOLIA:
    case Network.POLYGON:
    case Network.AMOY:
    case Network.BNB_SMART_CHAIN:
    case Network.BNB_SMART_CHAIN_TESTNET:
    case Network.KLAYTN:
    case Network.BAOBAB:
    case Network.BORA:
    case Network.BORA_TESTNET:
    case Network.MEVERSE:
    case Network.MEVERSE_TESTNET:
    case Network.PSM:
    case Network.PSM_TESTNET:
    case Network.PSM_TESTNET_TEST:
    case Network.PSM_TESTNET_DEV:
    case Network.HOME_VERSE:
    case Network.HOME_VERSE_TESTNET:
    case Network.YOOLDO_VERSE:
    case Network.YOOLDO_VERSE_TESTNET:
    case Network.SAND_VERSE:
    case Network.OASYS:
    case Network.OASYS_TESTNET:
    case Network.MCH_VERSE:
    case Network.MCH_VERSE_TESTNET:
    case Network.HEDERA:
    case Network.HEDERA_TESTNET:
    case Network.DEFI_VERSE_TESTNET:
    case Network.DEFI_VERSE:
    case Network.LITH:
    case Network.ASM_DEV:
    case Network.ASM_TEST:
    case Network.ASM_QA:
    case Network.ASM_STAGE:
    case Network.KROMA:
    case Network.KROMA_SEPOLIA:
    case Network.LINEA:
    case Network.LINEA_GOERLI:
      // case Network.ASM:
      return ['erc721', 'erc1155'];
    default:
      throw new Error(`${network} is not support token`);
  }
};

export const getHumanReadableTokenType = (tokenType: TokenType) => {
  switch (tokenType) {
    case 'fa2':
      return 'FA2';
    case 'erc20':
      return 'ERC20';
    case 'erc721':
      return 'ERC721';
    case 'erc1155':
      return 'ERC1155';
    default: {
      console.log(`unhandled token type: ${tokenType}`);
      return tokenType;
    }
  }
};
