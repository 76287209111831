import styled from '@emotion/styled';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
  overflow-x: scroll;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1680px;
  padding-left: 160px;
  padding-right: 100px;
  margin: 0 auto;

  @media (max-width: 1500px) {
    max-width: 1280px;
    min-width: 1280px;
    padding-left: 68px;
    padding-right: 56px;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 56px;
  line-height: 60px;
  margin: 0;
  margin-bottom: 12px;
`;

export const SubTitle = styled.h2`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
`;

export const Description = styled.p`
  margin: 0;
  margin-top: 12px;
  color: ${colors.gray[400]};
  ${typography.caption1.regular};

  & > a {
    color: ${colors.blue[400]};
  }
`;

export const LoginButton = styled.button`
  height: 52px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: ${radius.lg};
  border: 1px solid ${colors.gray[200]};
  padding: 0 16px;
  background: ${colors.white};
  color: ${colors.gray[800]};
  transition: background 0.3s;
  ${typography.button1.medium};

  &:hover {
    background: ${colors.gray['200-24%']};
  }
`;

export const DashboardLink = styled.a`
  height: 44px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  border-radius: ${radius.lg};
  text-decoration: none;
  background: ${colors.white};
  color: ${colors.gray[800]};
  transition: background 0.3s;
  ${typography.button1.medium};

  &:hover {
    background: ${colors.gray['200-24%']};
  }
`;

export const FaceThumbnail = styled.img`
  width: 20px;
  height: 20px;
  border-radius: ${radius.xs};
`;

export const Graphic = styled.img`
  width: 727px;

  @media (max-width: 1500px) {
    width: 527px;
  }
`;
