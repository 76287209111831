import { Env } from '@haechi-labs/face-types';
import originAxios from 'axios';
import { getEnv } from './env';
import { useNavigate } from 'react-router-dom';
import useSession from '@/hooks/useSession';
import RoutePath from '@/constants/routes';

const getBaseURL = (env: Env) => {
  switch (env) {
    case Env.ProdMainnet:
      return 'https://api.facewallet.xyz';
    case Env.ProdTest:
      return 'https://api.test.facewallet.xyz';
    case Env.StageMainnet:
      return 'https://api.stage.facewallet.xyz';
    case Env.StageTest:
      return 'https://api.stage-test.facewallet.xyz';
    case Env.Local:
      return 'http://localhost:8881';
    case Env.Dev:
    default:
      return 'https://api.dev.facewallet.xyz';
  }
};

const axios = originAxios.create({
  baseURL: getBaseURL(getEnv()),
});

/**
 * session이 변경될 경우 useSession의 setSession()을 통해 세션이 업데이트됨
 */
export const setAxiosSession = (session: string) => {
  axios.interceptors.request.clear();
  axios.interceptors.request.use((config) => {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${session}`;
    }
    return config;
  });
  if (axios.defaults.headers.common) {
    if (session) {
      axios.defaults.headers.common.Authorization = `Bearer ${session}`;
    } else {
      axios.defaults.headers.common.Authorization = undefined;
    }
  }
};

/**
 * 리액트 처음 접근했을 때 axios 초기화
 */
export function useInitAxios() {
  const navigate = useNavigate();
  const { session, clearSession } = useSession();

  axios.interceptors.request.use(async (config) => {
    if (config.headers) {
      if (session) {
        config.headers['Authorization'] = `Bearer ${session}`;
      }
    }
    return config;
  });

  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response?.status === 401) {
        console.error('401 clear session');
        clearSession();
        navigate(RoutePath.loginPage);
      }
      throw error;
    }
  );
}

export default axios;
