import { Container, Icon } from './index.styled';

interface BlockchainStackProps {
  blockchainImages: string[];
}

const BlockchainStack = ({ blockchainImages }: BlockchainStackProps) => {
  return (
    <Container>
      {blockchainImages.map((item) => (
        <Icon key={item} src={item} size={20} />
      ))}
    </Container>
  );
};

export default BlockchainStack;
