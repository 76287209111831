export const getImageSize = async (src: string) => {
  const image = new Image();
  image.src = src;

  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    try {
      image.onload = () => {
        const { width, height } = image;
        resolve({
          width,
          height,
        });
      };
    } catch (err) {
      resolve({
        width: 0,
        height: 0,
      });
    }
  });
};
