import styled from '@emotion/styled';
import { colors, typography } from '@haechi-labs/face-design-system';
import { Button, Input, Modal } from 'antd';

export const StyledPageDiv = styled.div`
  height: 100vh;
  background-color: #f7f8f9;
`;
export const StyledLogoImg = styled.img`
  position: absolute;
  left: 50%;
  bottom: calc(48px + 32px);
  height: 20px;
  transform: translateX(-50%);
  color: #c1ccd6cc;
`;

export const StyledModal = styled(Modal)`
  border-radius: 16px;
  border: 1px solid ${colors.gray[100]};
  background: white;

  .ant-modal-content {
    padding: 0;
    border-radius: 16px;
    width: 392px;
    box-shadow: none;

    .ant-modal-header {
      padding: 0;
      text-align: center;
    }

    .ant-modal-body {
      padding: 0;
      .ant-form-item-explain {
        padding: 0px 12px;
        margin-top: 4px;
        ${typography.caption1.regular};
        color: ${colors.gray[500]};
      }
    }
    .ant-modal-footer {
      padding: 0;
    }
  }
`;

export const StyledInput = styled(Input)`
  :read-only {
    color: ${colors.gray[500]};
    background-color: ${colors.gray['200-24%']};
    &:hover,
    &:focus,
    &:active {
      border-color: #dce1e8;
    }
  }
  .ant-input-wrapper {
    border: 0;
  }
`;

export const StyledButton = styled(Button)`
  margin: 0;
  margin-top: 16px;
  width: 100%;
`;
