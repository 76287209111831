import styled from '@emotion/styled';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

export const Container = styled.div`
  width: 100%;
  background: ${colors.gray['200-24%']};
  color: ${colors.gray[700]};
  border-radius: ${radius.sm};
  padding: 20px;
  ${typography.body2.regular}
`;
