import { WalletDTO } from '@/__generate__/dashboard-api';
import { walletToAddressByBlockchain } from '@/utils/wallet';
import { Blockchain } from '@haechi-labs/face-types';
import ETH from '@/assets/imgs/tokens/ETH.svg';
import MATIC from '@/assets/imgs/tokens/MATIC.svg';
import BNB from '@/assets/imgs/tokens/BNB.svg';
import BORA from '@/assets/imgs/tokens/BORA.svg';
import KLAY from '@/assets/imgs/tokens/KLAY.svg';
import SOL from '@/assets/imgs/tokens/SOL.svg';
import BlockchainStack from './BlockchainStack';
import { Flexbox, Text, colors } from '@haechi-labs/face-design-system';

interface AddressItemProps {
  wallet: WalletDTO;
}

const AddressItem = ({ wallet }: AddressItemProps) => {
  const ethlikeBlockchainImages = [ETH, MATIC, KLAY, BNB, BORA];
  const solanaBlockchainImages = [SOL];

  return (
    <>
      <Flexbox gap={4}>
        <BlockchainStack blockchainImages={ethlikeBlockchainImages} />
        <Text type="body2" weight="medium" color={colors.gray[800]}>
          {walletToAddressByBlockchain(wallet, Blockchain.ETHEREUM)}
        </Text>
      </Flexbox>
      <Flexbox gap={4}>
        <BlockchainStack blockchainImages={solanaBlockchainImages} />
        <Text type="body2" weight="medium" color={colors.gray[800]}>
          {walletToAddressByBlockchain(wallet, Blockchain.SOLANA)}
        </Text>
      </Flexbox>
    </>
  );
};

export default AddressItem;
