import { Layout } from 'antd';
import styled from '@emotion/styled';
import { colors } from '@haechi-labs/face-design-system';
const { Header: AntHeader } = Layout;

export const Header = styled(AntHeader)`
  background: ${colors.white};
  padding: 0 24px;
  border-bottom: 1px solid ${colors.gray[100]};
`;

export const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

export const GlobalAlertContainer = styled(AlertContainer)`
  z-index: 10000;
`;
