import { getImageSize } from '@/utils/image';
import { PictureOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadProps } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { Container, ErrorContainer, ErrorFileName } from './index.styled';

interface ThumbnailUploadButtonProps extends UploadProps {
  size?: number;
  initialImageUrl?: string;
  onError?: (file: RcFile) => void;
  restrict?: {
    width?: number;
    height?: number;
  };
}

const ThumbnailUploadButton = ({
  size = 104,
  initialImageUrl,
  restrict,
  onError,
  ...props
}: ThumbnailUploadButtonProps) => {
  const [errorFile, setErrorFile] = useState<RcFile | null>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>(initialImageUrl);

  useEffect(() => {
    setImageUrl(initialImageUrl);
  }, [initialImageUrl]);

  return (
    <Upload
      listType="picture"
      showUploadList={false}
      accept=".png,.jpg,.webp,.svg"
      beforeUpload={async (file) => {
        const url = URL.createObjectURL(file);
        if (restrict) {
          setErrorFile(null);
          const { width, height } = await getImageSize(url);
          const isInvalidWidth = restrict.width && restrict.width < width;
          const isInvalidHeight = restrict.height && restrict.height < height;

          if (isInvalidWidth || isInvalidHeight) {
            setErrorFile(file);
            onError?.(file);
            return Upload.LIST_IGNORE;
          }
        }
        setImageUrl(URL.createObjectURL(file));
        return false;
      }}
      {...props}>
      <Container
        type="button"
        style={{ width: size, height: size, borderStyle: imageUrl ? 'solid' : 'dashed' }}
        aria-invalid={!!errorFile}>
        {errorFile ? (
          <ErrorContainer>
            <PictureOutlined size={30} />
            <ErrorFileName>{errorFile.name}</ErrorFileName>
          </ErrorContainer>
        ) : imageUrl ? (
          <img src={imageUrl} alt="uploaded-thumbnail" style={{ width: '100%', padding: '9px' }} />
        ) : (
          <div>Upload</div>
        )}
      </Container>
    </Upload>
  );
};

export default ThumbnailUploadButton;
