import Card from '@/components/Card';
import { colors, IconSearch } from '@haechi-labs/face-design-system';
import { useGetUserInsights } from '@/hooks/queries/userInsights';
import { useMemo, useState } from 'react';
import { Input, Table } from 'antd';
import AddressItem from './AddressItem';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

const PAGE_SIZE = 10;
const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 280,
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: 280,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

const UserInformation = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const { data, isLoading } = useGetUserInsights(
    { size: PAGE_SIZE, page, searchParam: search },
    { suspense: true }
  );
  const [form] = useForm();

  const tableData = useMemo(() => {
    if (!data?.userInsights) {
      return [];
    }

    return data.userInsights.map((item) => ({
      key: item.email,
      email: item.email,
      country: item.country,
      address: <AddressItem wallet={item.wallet} />,
    }));
  }, [data]);

  const handleSubmit = (value: { search: string }) => {
    if (value.search !== search) {
      setSearch(value.search);
      setPage(0);
    }
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Card
        className="no-padding"
        title={'User Information'}
        extra={
          <Form.Item name="search">
            <Input
              style={{ width: 300 }}
              placeholder="Search E-mail, Address"
              suffix={
                <IconSearch size={24} color={colors.gray[300]} onClick={() => form.submit()} />
              }
            />
          </Form.Item>
        }>
        <Table
          className="no-pagination-jump"
          columns={columns}
          dataSource={tableData}
          loading={isLoading}
          locale={{ emptyText: 'No user data' }}
          pagination={{
            current: page + 1,
            size: 'small',
            position: ['bottomCenter'],
            showSizeChanger: false,
            pageSize: PAGE_SIZE,
            total:
              (data?.totalPageSize ? data.totalPageSize - 1 : 0) * PAGE_SIZE + tableData.length,
          }}
          tableLayout="fixed"
          onChange={({ current: nextPage }) => {
            if (!nextPage) return;

            setPage(nextPage - 1);
          }}
        />
      </Card>
    </Form>
  );
};

export default UserInformation;
