import Modal, { ModalProps } from 'antd/lib/modal';

import NotificationBox from '@/components/NotificationBox';
import Input from '@/components/Input';
import { Spacer } from '@haechi-labs/face-design-system';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useAddUserInsightRequestsRequest } from '@/hooks/queries/userInsights';
import useAlert from '@/hooks/layouts/useAlert';
import useErrorMessage from '@/hooks/useErrorMessage';

interface UserInsightsRequestModalProps extends ModalProps {
  closeModal: () => void;
  dappId: string;
}

const UserInsightsRequestModal = ({
  dappId,
  closeModal,
  ...props
}: UserInsightsRequestModalProps) => {
  const [form] = useForm();
  const { showAlert } = useAlert();
  const [disabled, setDisabled] = useState(true);
  const { getErrorMessage } = useErrorMessage();
  const { mutate: addUserInsightRequestsRequest } = useAddUserInsightRequestsRequest();

  const handleSubmit = () => {
    addUserInsightRequestsRequest(
      { ...form.getFieldsValue(), id: dappId },
      {
        onSuccess: () => {
          closeModal();
        },
        onError: (err) => {
          showAlert({
            type: 'error',
            target: 'global',
            message: getErrorMessage(err),
            showIcon: true,
          });
        },
      }
    );
  };

  const handleFieldChange = () => {
    const isEveryFieldSet = Object.values(form.getFieldsValue()).every((v) => !!v);
    setDisabled(!isEveryFieldSet);
  };

  return (
    <Modal
      title="Request Access"
      okText="Request"
      centered
      closable={false}
      okButtonProps={{ disabled }}
      onOk={() => form.submit()}
      {...props}>
      <NotificationBox type="default" title="Notice">
        <ul>
          <li>The User Insights service is only available to legal entities.</li>
          <li>
            Legal entities using the service will be added to our{' '}
            <a
              href="https://docs.facewallet.xyz/page/face-wallet-provision-of-personal-information-to-third-party"
              target="_blank"
              rel="noreferrer">
              Terms of Provision of Personal Information to Third Parties
            </a>
            .
          </li>
        </ul>
      </NotificationBox>
      <Spacer y={16} />
      <Form form={form} onFinish={handleSubmit} onFieldsChange={handleFieldChange}>
        <Form.Item required name="userName">
          <Input requiredMark label="Name" placeholder="Enter Your Full Name" />
        </Form.Item>
        <Spacer y={16} />
        <Form.Item required name="companyName">
          <Input requiredMark label="Company Name" placeholder="Enter Your Company’s Name" />
        </Form.Item>
        <Spacer y={16} />
        <Form.Item required name="userTelegram">
          <Input
            requiredMark
            label="Telegram ID (if none, please input your email)"
            placeholder="Enter Your Telegram ID or Email"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserInsightsRequestModal;
