import { Loader } from '@haechi-labs/face-design-system';
import { Container } from './index.styled';

const FullScreenSpinner = () => {
  return (
    <Container>
      <Loader />
    </Container>
  );
};

export default FullScreenSpinner;
