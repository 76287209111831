import { useMutation, useQueryClient, useQuery, UseQueryOptions } from 'react-query';
import {
  addUserInsightRequestsRequest,
  getUserInsightsSummary,
  getUserInsights,
  UserInsightsParams,
  AddUserInsightRequestsParams,
} from '@/apis/userInsights';
import { useParams } from 'react-router-dom';
import { UserInsightsSummaryDTO, UserInsightsDTO } from '@/__generate__/dashboard-api';
import { QUERY_KEY as DAPP_QUERY_KEY } from '@/hooks/queries/dapps';

const QUERY_KEY = 'dashboard/v1/dapps/user-insights';

export const useAddUserInsightRequestsRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: AddUserInsightRequestsParams) => addUserInsightRequestsRequest(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DAPP_QUERY_KEY);
      },
    }
  );
};

export const useGetUserInsightsSummary = (options?: UseQueryOptions<UserInsightsSummaryDTO>) => {
  const { dappId = '' } = useParams();
  return useQuery<UserInsightsSummaryDTO>(
    [QUERY_KEY, 'summary'],
    () => getUserInsightsSummary(dappId),
    {
      enabled: !!dappId,
      ...options,
    }
  );
};

export const useGetUserInsights = (
  params: UserInsightsParams,
  options?: UseQueryOptions<UserInsightsDTO>
) => {
  const { dappId = '' } = useParams();

  return useQuery<UserInsightsDTO>([QUERY_KEY, params], () => getUserInsights(params, dappId), {
    enabled: !!dappId,
    ...options,
  });
};
