import {
  DEV_ENVS,
  DEV_URLS,
  LOCAL_URLS,
  MULTISTAGE_ENVS,
  PRODUCT_ENVS,
  PRODUCT_MAINNET,
  PRODUCT_URLS,
  STAGING_ENVS,
  STAGING_MAINNET,
  STAGING_URLS,
} from '@/constants/urls';

const getCurrentBlockchainNetworkPolicy = () => {
  const hostname = window.location.hostname;
  const mainnet = [PRODUCT_MAINNET, STAGING_MAINNET];
  const origin = window.location.origin;

  const [mainnetUrl, testnetUrl] = (() => {
    // @ts-expect-error should patch String.include type
    if (PRODUCT_ENVS.includes(hostname)) return PRODUCT_URLS;
    // @ts-expect-error should patch String.include type
    if (STAGING_ENVS.includes(hostname)) return STAGING_URLS;
    // @ts-expect-error should patch String.include type
    if (DEV_ENVS.includes(hostname)) return DEV_URLS;
    // @ts-expect-error should patch String.include type
    if (hostname.includes(MULTISTAGE_ENVS)) return [origin, origin];
    return LOCAL_URLS;
  })();

  const isMainnet = mainnet.includes(hostname);
  return {
    isMainnet,
    type: isMainnet ? 'mainnet' : 'testnet',
    mainnetUrl,
    testnetUrl,
  } as const;
};

export default getCurrentBlockchainNetworkPolicy;
