import { AuthorizedRoutePath } from '@/constants/routes';
import useCurrentDappId from './stores/useCurrentDappId';

const useAuthorizedRoutePaths = () => {
  const currentDappId = useCurrentDappId() || ':dappId';

  const getParams = (key: string) => {
    switch (key) {
      case ':dappId':
        return currentDappId;
      default:
        return key;
    }
  };

  const injectParamsToPath = (pathObject: object, params: string = '') => {
    const result = Object.entries(pathObject).map(([key, value]) => {
      if (typeof value === 'object') {
        return [key, Object.fromEntries(injectParamsToPath(value, getParams(key)))];
      }
      return [key, `${params}${value}`];
    }) as [string, string | object][];
    return result;
  };

  const convert = Object.fromEntries(injectParamsToPath(AuthorizedRoutePath)) as any;
  return convert as typeof AuthorizedRoutePath;
};

export default useAuthorizedRoutePaths;
