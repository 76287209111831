import { atom } from 'recoil';

export const otpInfoAtom = atom<
  | {
      trialCount: number;
      scope: 'LOGIN';
      createdAt: string;
      updatedAt: string;
    }
  | undefined
>({
  key: 'otpInfoAtom',
  default: undefined,
});
