import styled from '@emotion/styled';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

export const Container = styled.div`
  padding: 20px;
  border-radius: ${radius.sm};

  & > .title {
    ${typography.body2.bold};
  }

  & > .body {
    ${typography.body2.regular}

    & > ul {
      padding-left: 16px;
      margin: 0;

      & > li + li {
        margin-top: 4px;
      }
    }
  }

  &[data-type='error'] {
    background: ${colors.red[100]};
    color: ${colors.red[500]};

    & > .title {
      color: ${colors.red[600]};
    }
  }

  &[data-type='default'] {
    background: ${colors.gray['200-24%']};
    color: ${colors.gray[600]};

    & > .title {
      color: ${colors.gray[600]};
    }
  }
`;
