import { css } from '@emotion/react';
import { colors, radius, semantic, typography } from '@haechi-labs/face-design-system';

const ButtonStyle = css`
  *:not(:has(.without-global-override)) {
    .ant-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: ${radius.lg};
      ${typography.button1.medium}

      &:not(.ant-btn-icon-only) {
        padding: 16px 24px;
        height: 52px;
      }

      &.ant-btn-sm {
        padding: 4px 8px;
        height: auto;
      }
    }

    .ant-btn-text {
      color: ${colors.gray[600]};

      &:disabled {
        background: ${colors.gray[200]};
        color: ${colors.gray[400]};
      }

      &:not(:disabled) {
        &:hover {
          background: ${colors.gray['200-24%']};
          color: ${colors.gray[600]};
        }

        &:active {
          color: ${colors.gray['200-50%']} !important;
        }
      }
    }

    .ant-btn-primary {
      background: ${semantic.colors.primary.light};
      border: none;

      &:disabled {
        background: ${colors.gray[200]};
        color: ${colors.gray[400]};
      }

      &:not(:disabled) {
        &:hover {
          background: ${semantic.colors.primary.dark};
        }

        &:active {
          color: ${colors.gray['200-50%']} !important;
        }
      }
    }

    .ant-btn-default {
      border: 1px solid ${colors.gray[200]};

      &:disabled {
        background: ${colors.white};
        opacity: 0.6;
      }

      &:not(:disabled) {
        &:hover {
          background: ${colors.gray['200-24%']};
          border: 1px solid ${colors.gray[200]};
          color: ${colors.gray[800]};
        }

        &:active {
          color: ${colors.gray[800]};
          opacity: 0.6;
        }
      }
    }

    .ant-btn-default.ant-btn-dangerous {
      background: ${colors.red[600]};
      color: ${colors.white};

      &:disabled {
        background: ${colors.gray[200]};
        color: ${colors.gray[400]};
      }

      &:not(:disabled) {
        &:hover {
          background: ${colors.red[700]};
          color: ${colors.white};
        }

        &:active {
          color: ${colors.gray['200-50%']} !important;
        }
      }
    }
  }
`;

export default ButtonStyle;
