import { useLayoutEffect } from 'react';
import throttle from 'lodash.throttle';

const useResize = (callback: () => void, throttleTime: number = 100) => {
  const _callback = throttle(() => callback(), throttleTime);

  useLayoutEffect(() => {
    window.addEventListener('resize', _callback);
    return () => {
      window.removeEventListener('resize', _callback);
    };
  }, [_callback]);
};

export default useResize;
