import {
  AlertEvent,
  alertEventSelector,
  alertEventQueueAtom,
  globalAlertEventQueueAtom,
  globalAlertEventSelector,
} from '@/stores/layoutAtom';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const DEFAULT_TIME = 3 * 1000;

export const useSubscribeAlertEvent = (target: AlertEvent['target']) => {
  const alertAtom = useMemo(() => {
    return target === 'global' ? globalAlertEventQueueAtom : alertEventQueueAtom;
  }, [target]);

  const alertSelector = useMemo(() => {
    return target === 'global' ? globalAlertEventSelector : alertEventSelector;
  }, [target]);

  const [alertEventQueue, setAlertEventQueue] = useRecoilState(alertAtom);
  const alertEvent = useRecoilValue(alertSelector);

  useEffect(() => {
    if (alertEvent) {
      const timer = setTimeout(() => {
        setAlertEventQueue((cur) => cur.slice(1));
      }, alertEvent.time || DEFAULT_TIME);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alertEvent, setAlertEventQueue]);

  useEffect(() => {
    return () => {
      setAlertEventQueue([]);
    };
  }, [setAlertEventQueue]);

  return {
    alertEventQueue,
    currentAlertEvent: alertEvent,
  };
};

const useAlert = () => {
  const setAlertEventQueue = useSetRecoilState(alertEventQueueAtom);
  const setGlobalAlertEventQueue = useSetRecoilState(globalAlertEventQueueAtom);

  const showAlert = (event: AlertEvent) => {
    switch (event.target) {
      case 'global':
        setGlobalAlertEventQueue((cur) => [...cur, event]);
        break;
      case 'contents':
        setAlertEventQueue((cur) => [...cur, event]);
        break;
    }
  };

  const closeAlert = (target: AlertEvent['target']) => {
    switch (target) {
      case 'global':
        setGlobalAlertEventQueue((cur) => cur.slice(1));
        break;
      case 'contents':
        setAlertEventQueue((cur) => cur.slice(1));
        break;
    }
  };

  const clearQueue = () => {
    setAlertEventQueue([]);
    setGlobalAlertEventQueue([]);
  };

  return {
    showAlert,
    closeAlert,
    clearQueue,
  };
};

export default useAlert;
