import sessionAtom from '@/stores/sessionAtom';
import { setAxiosSession } from '@/utils/axios';
import { clearStoredSession, storeSession } from '@/utils/session';

import { useRecoilState } from 'recoil';

const useSession = () => {
  const [session, setSession] = useRecoilState(sessionAtom);

  const _setSession = (session: string) => {
    storeSession(session);
    setSession(session);
    setAxiosSession(session);
  };

  const clearSession = () => {
    clearStoredSession();
    setSession(null);
  };

  return {
    session,
    setSession: _setSession,
    clearSession,
  };
};

export default useSession;
