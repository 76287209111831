import { Spacer, colors, Text } from '@haechi-labs/face-design-system';
import userInsightsGuideImg from '@/assets/imgs/userInsights/img-userinsights.svg';
import guideDimImage from '@/assets/imgs/userInsights/guide-dim.jpg';
import { Button } from 'antd';
import styled from '@emotion/styled';
import useModal from '@/hooks/useModal';
import UserInsightsRequestModal from '@/modals/UserInsightsRequestModal';
import { DashboardDappDTO } from '@/__generate__/dashboard-api';
import GuideGenerateCredentialModal from '@/modals/GuideGenerateCredentialModal';

interface GuideContentsProps {
  dapp: DashboardDappDTO;
}

const GuideContents = ({ dapp }: GuideContentsProps) => {
  const { open, openModal, closeModal } = useModal();
  const {
    open: isOpenGuideGenerateCredentialModal,
    openModal: openGuideGenerateCredentialModal,
    closeModal: closeGuideGenerateCredentialModal,
  } = useModal();

  const hasSentRequest = dapp?.userInsightRequest?.opened === true;
  const handleRequestBtnClick = () => {
    if (!dapp?.apiKey) {
      openGuideGenerateCredentialModal();
      return;
    }
    openModal();
  };

  return (
    <Container>
      <div>
        <img src={userInsightsGuideImg} width={115} alt="user-insights-guide-img" />
        <Spacer y={24} />
        <Text type="body1" color={colors.black}>
          Upon requesting access to the User Insights service,
          <br />
          our team will contact you shortly.
        </Text>
        <Spacer y={24} />
        {hasSentRequest ? (
          <Button type="primary" style={{ width: 260 }} disabled>
            Requesting...
          </Button>
        ) : (
          <Button type="primary" style={{ width: 260 }} onClick={handleRequestBtnClick}>
            Request Access
          </Button>
        )}
      </div>
      <UserInsightsRequestModal
        dappId={dapp.id}
        open={open}
        onCancel={closeModal}
        closeModal={closeModal}
      />
      <GuideGenerateCredentialModal
        open={isOpenGuideGenerateCredentialModal}
        onCancel={closeGuideGenerateCredentialModal}
        desc="You can request User Insights service only AFTER generating the API Credentials."
      />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url(${guideDimImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

export default GuideContents;
