import { pubToAddress } from 'ethereumjs-util';
import { Blockchain } from '@haechi-labs/face-types';
import { isEthlikeBlockchain } from '@haechi-labs/shared';
import { WalletDTO } from '@/__generate__/dashboard-api';
import { PublicKey } from '@solana/web3.js';

export const getEthWalletAddress = (secp256k1PublicKey: Uint8Array): string => {
  return `0x${pubToAddress(Buffer.from(secp256k1PublicKey)).toString('hex').toLocaleLowerCase()}`;
};

export const walletToAddressByBlockchain = (wallet: WalletDTO, blockchain?: Blockchain) => {
  if (!blockchain || isEthlikeBlockchain(blockchain)) {
    return wallet.ecdsaPublicKey
      ? getEthWalletAddress(Buffer.from(wallet.ecdsaPublicKey.substring(2), 'hex'))
      : wallet.address || '';
  } else if (blockchain === Blockchain.NEAR) {
    return wallet.eddsaPublicKey!.substring(2) || '';
  } else if (blockchain === Blockchain.SOLANA) {
    const publicKey = new PublicKey(Buffer.from(wallet.eddsaPublicKey!.substring(2), 'hex'));
    return publicKey.toBase58() || '';
  } else if (blockchain === Blockchain.APTOS) {
    return wallet.eddsaPublicKey || '';
  } else {
    throw new Error('wrong blockchain type');
  }
};
