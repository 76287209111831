import FullScreenSpinner from '@/components/FullScreenLoader';
import { AuthorizedRoutePath } from '@/constants/routes';
import useSidebar from '@/hooks/layouts/useSidebar';
import DappDetailPage from '@/pages/DappDetailPage';
import UserInsightsPage from '@/pages/UserInsightsPage';
import NftPage from '@/pages/NftPage';
import TokenPage from '@/pages/TokenPage';
import currentDappIdAtom from '@/stores/currentDappIdAtom';

import { Suspense, useEffect } from 'react';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ErrorBoundary } from 'react-error-boundary';
import useAuthorizedRoutePaths from '@/hooks/useAuthorizedRoutePaths';

const DappRouter = () => {
  useSidebar(true);
  const { dappId = '' } = useParams();
  const authorizedPaths = useAuthorizedRoutePaths();
  const setCurrentDappId = useSetRecoilState(currentDappIdAtom);

  useEffect(() => {
    setCurrentDappId(dappId);

    return () => {
      setCurrentDappId('');
    };
  }, [dappId, setCurrentDappId]);

  return (
    <ErrorBoundary fallback={<Navigate to={authorizedPaths.allDappsPage} />}>
      <Suspense fallback={<FullScreenSpinner />}>
        <Routes>
          <Route
            path={AuthorizedRoutePath[':dappId'].dappDetailPage}
            element={<DappDetailPage />}
          />
          <Route path={AuthorizedRoutePath[':dappId'].tokenPage} element={<TokenPage />} />
          <Route path={AuthorizedRoutePath[':dappId'].nftPage} element={<NftPage />} />
          <Route
            path={AuthorizedRoutePath[':dappId'].userInsightsPage}
            element={<UserInsightsPage />}
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default DappRouter;
