import ThumbnailUploadButton from '@/components/IconUploadButton';
import Input from '@/components/Input';
import Label from '@/components/Label';

import { useDappByIdQuery, useUpdateDappInfo } from '@/hooks/queries/dapps';
import { DashboardDappDTO } from '@/__generate__/dashboard-api';
import { Spacer } from '@haechi-labs/face-design-system';
import { Form, UploadFile } from 'antd';
import { FormProps, useForm } from 'antd/lib/form/Form';
import Modal, { ModalProps } from 'antd/lib/modal';
import { UploadProps } from 'antd/lib/upload/interface';
import { useState } from 'react';

interface EditDappInfoModalProps extends ModalProps {
  dappId: DashboardDappDTO['id'];
  closeModal: () => void;
}

interface FormValues {
  name: string;
  iconImages: UploadFile[];
}

const EditDappInfoModal = ({ dappId, closeModal, ...props }: EditDappInfoModalProps) => {
  const [form] = useForm();
  const { data: dapp } = useDappByIdQuery(dappId, {
    onSuccess: (dapp) => {
      form.resetFields();
      form.setFieldValue('name', dapp.name);
    },
  });
  const { mutate: updateDappInfo } = useUpdateDappInfo();
  const [disabled, setDisabled] = useState(true);

  // TODO: form 데이터 적용 필요
  const handleSubmit = ({
    name,
    // @ts-expect-error
    blockchainNetworks,
    // @ts-expect-error
    launchDateTime,
    // @ts-expect-error
    domainUrl,
    iconImages,
  }: FormValues) => {
    if (dapp) {
      updateDappInfo({
        id: dapp?.id,
        name,
        blockchainNetworks,
        launchDateTime,
        domainUrl,
        iconImage:
          Array.isArray(iconImages) && iconImages.length > 0
            ? iconImages[iconImages.length - 1].originFileObj
            : undefined,
      });
    }
    closeModal();
  };

  const handleUpload: UploadProps['onChange'] = (e) => {
    form.setFields([
      {
        name: 'iconImages',
        errors: [],
      },
    ]);
    return e.fileList;
  };

  const handleUploadError = () => {
    form.setFields([
      {
        name: 'iconImages',
        errors: ['Image size is not 144*144px.'],
      },
    ]);
  };

  const handleFieldChange: FormProps['onFieldsChange'] = (_, fields) => {
    const errors = fields.flatMap((field) => field.errors);
    setDisabled(errors.length > 0);
  };

  return (
    <Modal
      title="Dapp Information"
      okText="Save"
      closable={false}
      okButtonProps={{ disabled }}
      onOk={() => {
        form.submit();
      }}
      centered
      {...props}>
      <Form form={form} onFinish={handleSubmit} onFieldsChange={handleFieldChange}>
        <Label description="Please input the OFFICIAL name of your Dapp" required>
          Dapp Name
        </Label>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              min: 1,
              transform: (value) => {
                if (typeof value === 'string') {
                  return value.length;
                }
                return 0;
              },
              validator: (_, value) => {
                if (typeof value === 'number') {
                  if (value <= 0) return Promise.reject();
                  return Promise.resolve();
                }
              },
              validateTrigger: 'onChange',
            },
          ]}>
          <Input placeholder="Enter your Dapp Name" />
        </Form.Item>
        <Spacer y={16} />
        <Label description="Size: 144*144px, Format: jpg, png, svg, webp">Dapp Logo</Label>
        <Spacer y={4} />
        <Form.Item name="iconImages" getValueFromEvent={handleUpload} valuePropName="fileList">
          <ThumbnailUploadButton
            initialImageUrl={dapp?.iconImage}
            restrict={{ width: 144, height: 144 }}
            onError={handleUploadError}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDappInfoModal;
