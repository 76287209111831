import { useNavigate } from 'react-router-dom';
import { StyledTitleDiv } from './loginEmailVerifyPage.styled';
import { LoginLayout } from './LoginLayout';
import ArrowLeftLine from '@/assets/imgs/arrow-left-line.svg';
import Lottie from 'react-lottie';
import lottieFailure from '@/assets/lotties/failure.json';
import logoGray from '@/assets/imgs/logo-gray.svg';
import { StyledModal } from './loginEmailPage.styled';
import useCounter, { secondToHourMin } from './useCounter';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { emailLockedAtAtom } from '@/stores/emailLockedAtAtom';
import { dateToRemainTime } from './loginUtils';
import {
  StyledContentDiv,
  StyledDescriptionDiv,
  StyledFooterLogoDiv,
  StyledTimerDiv,
} from './loginEmailLockedPage.styled';
import { Spacer } from '@haechi-labs/face-design-system';
import RoutePath from '@/constants/routes';
import { useEffect, useRef } from 'react';
import { emailAtom } from '@/stores/emailAtom';
import { useFaceMutation } from '@/hooks/useFaceQuery';
import { sendEmailVerificationCode } from '@/apis/users';
import { otpInfoAtom } from '@/stores/otpInfoAtom';
import useAlert from '@/hooks/layouts/useAlert';
import { DashboardApiError } from '@/types/axios';
import { DASHBOARD_ERROR } from '@/constants/dashboardError';

export function LoginEmailLockedPage() {
  const navigate = useNavigate();
  const recoilEmail = useRecoilValue(emailAtom);
  const setRecoilOtpInfo = useSetRecoilState(otpInfoAtom);
  // recoil에서 lockedAt 가져오기. lockedAt이 없으면 login 페이지로 이동
  const [recoilEmailLockedAt, setRecoilEmailLockedAt] = useRecoilState(emailLockedAtAtom);

  const remainSeconds = dateToRemainTime(recoilEmailLockedAt, 3600);
  const { remainTime } = useCounter(remainSeconds);
  const { showAlert } = useAlert();

  const flagResendingEmailOnlyOnceRef = useRef(false);
  const { commit: mutateSendEmail } = useFaceMutation(sendEmailVerificationCode, {
    onSuccess: (otpInfo) => {
      setRecoilOtpInfo(otpInfo);
      navigate(RoutePath.loginEmailVerifyPage);
    },
    onError: (error) => {
      const dashboardError = error as DashboardApiError;
      const isUserLocked =
        dashboardError.response.data.code === DASHBOARD_ERROR.DASHBOARD_USER_LOCKED;

      if (isUserLocked) {
        // 이메일 잠금 상태면 LoginEmailLockedPage 페이지로 이동
        try {
          const message: { lockedAt: string } = JSON.parse(dashboardError.response.data.message);
          setRecoilEmailLockedAt(message.lockedAt);
          navigate(RoutePath.loginEmailLockedPage);
          return;
        } catch (error) {
          console.error(error);
        }
      }
      // 기타 에러
      console.error(error);
      showAlert({
        type: 'error',
        target: 'global',
        message: 'Failed to sendEmailVerificationCode. Please try again.',
        showIcon: true,
      });
    },
  });

  useEffect(() => {
    // 인증코드 정보 제거
    setRecoilOtpInfo(undefined);
  }, [setRecoilOtpInfo]);

  useEffect(() => {
    // 리코일 상태에 따라 페이지 이동
    if (!recoilEmailLockedAt) {
      navigate(RoutePath.allDappsPage);
      return;
    } else if (remainTime <= 0 && !flagResendingEmailOnlyOnceRef.current) {
      // WARN: useEffect 내부에서 api 콜하는 부분이라 flag로 한번만 실행되도록 유의해야함!!!
      if (recoilEmail) {
        // 남은시간이 0이고 이메일이 있으면 이메일 다시 보내기
        flagResendingEmailOnlyOnceRef.current = true;
        mutateSendEmail({ receiver: recoilEmail });
        return;
      }
      navigate(RoutePath.loginEmailPage);
      return;
    }
  }, [navigate, recoilEmail, recoilEmailLockedAt, remainTime, mutateSendEmail]);

  // 남은시간 표시. 59초 ~ 1초 사이에는 1분 남은걸로 보여주기
  const hourMinString =
    remainTime <= 59 && remainTime >= 1 ? secondToHourMin(60) : secondToHourMin(remainTime);

  return (
    <LoginLayout>
      <StyledModal
        width={'auto'}
        open={true}
        closable={false}
        title={
          <StyledTitleDiv>
            <button className="prev-button" onClick={() => navigate(-1)}>
              <img src={ArrowLeftLine} alt="prev-button" />
            </button>
            <span className="title">Verification Locked</span>
            <span className="empty"></span>
          </StyledTitleDiv>
        }
        centered
        mask={false}
        footer={false}>
        <StyledContentDiv>
          <Lottie
            height={100}
            width={100}
            options={{ animationData: lottieFailure, loop: false }}
          />
          <StyledTimerDiv>{hourMinString}</StyledTimerDiv>
          <StyledDescriptionDiv>
            Your Email Verification has been locked for security reasons. Please try again after 1
            hour. You can contact us at{' '}
            <a href="mailto:support@facewallet.xyz">support@facewallet.xyz</a>
            {` `}
            with any questions.
          </StyledDescriptionDiv>
        </StyledContentDiv>

        <div>
          <Spacer y={32} />
          <StyledFooterLogoDiv>
            Powered by
            <img src={logoGray} alt="logo" />
          </StyledFooterLogoDiv>
          <Spacer y={32} />
        </div>
      </StyledModal>
    </LoginLayout>
  );
}
