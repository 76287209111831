// hostname
export const PRODUCT_MAINNET = 'dashboard.facewallet.xyz';
export const PRODUCT_TESTNET = 'testnet.dashboard.facewallet.xyz';
export const STAGING_MAINNET = 'dashboard.stage.facewallet.xyz';
export const STAGING_TESTNET = 'testnet.dashboard.stage.facewallet.xyz';
export const DEV_TESTNET = 'testnet.dashboard.dev.facewallet.xyz';
export const MULTISTAGE_TESTNET = 'facewallet-test.xyz';
export const LOCAL_TESTNET = 'localhost';

export const PRODUCT_ENVS = [PRODUCT_MAINNET, PRODUCT_TESTNET] as const;
export const STAGING_ENVS = [STAGING_MAINNET, STAGING_TESTNET] as const;
export const DEV_ENVS = [DEV_TESTNET] as const;
export const MULTISTAGE_ENVS = [MULTISTAGE_TESTNET] as const;
export const LOCAL_ENVS = [LOCAL_TESTNET] as const;

export const PRODUCT_MAINNET_URL = `https://${PRODUCT_MAINNET}`;
export const PRODUCT_TESTNET_URL = `https://${PRODUCT_TESTNET}`;
export const STAGING_MAINNET_URL = `https://${STAGING_MAINNET}`;
export const STAGING_TESTNET_URL = `https://${STAGING_TESTNET}`;
export const DEV_TESTNET_URL = `https://${DEV_TESTNET}`;
export const LOCAL_TESTNET_URL = `http://${LOCAL_TESTNET}:${process.env.REACT_APP_PORT}`;

export const PRODUCT_URLS = [PRODUCT_MAINNET_URL, PRODUCT_TESTNET_URL] as const;
export const STAGING_URLS = [STAGING_MAINNET_URL, STAGING_TESTNET_URL] as const;
export const DEV_URLS = [DEV_TESTNET_URL, DEV_TESTNET_URL] as const;
export const LOCAL_URLS = [LOCAL_TESTNET_URL, LOCAL_TESTNET_URL] as const;

export const DOCS_URLS = {
  apiKey: 'https://docs.facewallet.xyz/docs/dashboard-home#api-key',
  allowDomains: 'https://docs.facewallet.xyz/docs/dashboard-home#allowlist-domains',
  allowAppIds: 'https://docs.facewallet.xyz/docs/dashboard-home#allowlist-app-id',
  tokens: 'https://docs.facewallet.xyz/docs/dashboard-tokens',
  nfts: 'https://docs.facewallet.xyz/docs/dashboard-nfts',
};
