import axios from '@/utils/axios';

import {
  AddDappTokenRequest,
  DappCoinDTO,
  DashboardDappDTO,
  DeleteDappTokenRequest,
  UpdateDappTokenImageRequest,
  ValidateDappTokenRequest,
} from '@/__generate__/dashboard-api';
import { isSupportedCoin as isOnRampSupportedCoin } from '@haechi-labs/face-on-ramp';
import { ConvertModule } from '@haechi-labs/face-convert';
import { SwapModule } from '@haechi-labs/face-swap';
import { Network } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

type DerivedCoinDTO = Prettify<
  Omit<DappCoinDTO, 'blockchainNetwork' | 'blockchain'> & {
    blockchainNetwork: Network;
    contractAddress: string;
  }
>;

export const getDappTokens = async (id: string): Promise<DappCoinV2DTO[]> => {
  const { data } = await axios.get(`/dashboard/v2/dapps/tokens`, {
    headers: { 'X-Face-Dapp-Id': id },
  });
  return data;
};

export interface AddDappTokenParams extends AddDappTokenRequest {
  id: DashboardDappDTO['id'];
  blockchainNetwork: DappCoinDTO['blockchainNetwork'];
  tokenType: 'erc20' | 'fa2';
  contractAddress: string;
  symbolImage?: Blob;
}

export type DappCoinV2DTO = Omit<DerivedCoinDTO, 'blockchain'>;

export const addDappToken = async (params: AddDappTokenParams): Promise<DappCoinV2DTO> => {
  const { data } = await axios.post<DappCoinV2DTO>(`/dashboard/v2/dapps/tokens`, params, {
    headers: { 'Content-Type': 'multipart/form-data', 'X-Face-Dapp-Id': params.id },
  });
  return data;
};

export interface UpdateDappTokenImageParams extends UpdateDappTokenImageRequest {
  id: DashboardDappDTO['id'];
}

export const updateDappTokenImage = async (params: UpdateDappTokenImageParams): Promise<void> => {
  const { data } = await axios.post(
    `/dashboard/v1/dapps/tokens/images`,
    {
      ...params,
    },
    {
      headers: { 'Content-Type': 'multipart/form-data', 'X-Face-Dapp-Id': params.id },
    }
  );
  return data;
};

export interface DeleteDappTokenParams extends DeleteDappTokenRequest {
  id: DashboardDappDTO['id'];
  blockchainNetwork: DappCoinDTO['blockchainNetwork'];
  contractAddress: string;
}

export const deleteDappToken = async (params: DeleteDappTokenParams): Promise<DappCoinV2DTO[]> => {
  const { data } = await axios.delete(`/dashboard/v2/dapps/tokens`, {
    headers: { 'X-Face-Dapp-Id': params.id },
    data: {
      ...params,
    },
  });
  return data;
};

export interface ValidateDappTokenParams extends ValidateDappTokenRequest {
  id: DashboardDappDTO['id'];
  blockchainNetwork: Network;
  tokenType: 'erc20' | 'fa2';
  contractAddress: string;
}

type ValidateTokenResponse =
  | {
      valid: boolean;
      metadata?: {
        name: string;
        symbol: string;
        decimals: number;
      };
      reason?: {
        code: string;
        message: string;
      };
    }
  | {
      // duplicated token (already created)
      code: 'MA0011';
      message: string;
      status: number;
    }
  | {
      // invalid erc20 token
      code: 'I9999'; //에러 코드 (현재 I9999로 내려주고 있어서 똑같이 I9999로 내려드릴게요)
      message: string;
      status: number;
    }
  | {
      // Given blockchain network is not match with the server context
      code: 'MA0007';
      message: string;
      status: number;
    }
  | {
      // invalid erc20 token
      code: 'MA0008';
      message: string;
      status: number;
    }
  | {
      // invalid fa2 token
      code: 'MA0009';
      message: string;
      status: number;
    };

export const validateDappToken = async (
  params: ValidateDappTokenParams
): Promise<ValidateTokenResponse> => {
  const { data } = await axios.post(
    `/dashboard/v2/dapps/tokens/validate`,
    { ...params },
    {
      headers: { 'X-Face-Dapp-Id': params.id },
      validateStatus: (status) => status >= 200 && status < 500,
    }
  );
  return data;
};

export function tokenSupportOnRamp(network: Network) {
  return isOnRampSupportedCoin(networkToBlockchain(network));
}

export function tokenSupportConvert(network: Network) {
  return new ConvertModule().getConvertibleCoinList().find((x) => x.network === network) != null;
}

export function tokenSupportSwap(network: Network) {
  return new SwapModule().getSwappableCoinList().find((x) => x.network === network) != null;
}
