import { useForm, useWatch } from 'antd/lib/form/Form';
import { Form, InputRef } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { emailAtom } from '@/stores/emailAtom';
import ArrowLeftLine from '@/assets/imgs/arrow-left-line.svg';
import { Spacer } from '@haechi-labs/face-design-system';
import useCounter, { secondToMinSec } from './useCounter';
import useSession from '@/hooks/useSession';
import routes from '@/constants/routes';
import { dateToRemainTime } from './loginUtils';
import {
  StyledButton,
  StyledContentDiv,
  StyledInputWithTimerDiv,
  StyledResendButton,
  StyledTitleDiv,
} from './loginEmailVerifyPage.styled';
import { StyledInput, StyledModal } from './loginEmailPage.styled';
import { useAutoFocus } from './useAutoFocus';
import { useFaceMutation } from '@/hooks/useFaceQuery';
import { verify, sendEmailVerificationCode } from '@/apis/users';
import { LoginLayout } from './LoginLayout';
import useAlert from '@/hooks/layouts/useAlert';
import { emailLockedAtAtom } from '@/stores/emailLockedAtAtom';
import RoutePath from '@/constants/routes';
import { DashboardApiError } from '@/types/axios';
import { DASHBOARD_ERROR } from '@/constants/dashboardError';
import { otpInfoAtom } from '@/stores/otpInfoAtom';

type formSchema = {
  email: string;
  otp: string;
};

// 기본 타이머 시간 10분
const defaultRemainTime = 600;

export function LoginEmailVerifyPage() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  // 모달 인풋 포커스
  const otpInputRef = useRef<InputRef>(null);
  useAutoFocus(otpInputRef);

  const { setSession } = useSession();

  const [form] = useForm<formSchema>();
  const formValueOtp = useWatch('otp', form) || '';
  const [recoilEmail, setRecoilEmail] = useRecoilState(emailAtom);
  const setRecoilEmailLockedAt = useSetRecoilState(emailLockedAtAtom);
  const [recoilOtpInfo, setRecoilOtpInfo] = useRecoilState(otpInfoAtom);
  const { isTimeOver, remainTime, startTime, stopTime } = useCounter(
    dateToRemainTime(recoilOtpInfo?.createdAt)
  );
  const [isValidOtp, setIsValidOtp] = useState(true);

  // 카운터가 끝나거나 시도횟수가 5회 이상이면 재전송 상태
  const isResendStatus = isTimeOver || (!!recoilOtpInfo && recoilOtpInfo.trialCount >= 5);

  useEffect(() => {
    // 재전송상태이면 에러메시지 노출
    if (isResendStatus) {
      form.setFields([
        {
          name: 'otp',
          errors: ['Code expired. Please send again.'],
        },
      ]);
    }
  }, [form, isResendStatus]);

  const { commit: mutateVerifyEmail, isInFlight: isInFlightVerifyCode } = useFaceMutation(verify, {
    onSuccess: (success) => {
      if (success.idToken) {
        setIsValidOtp(true);
        setSession(success.idToken);
        navigate(routes.allDappsPage);
        setRecoilEmail('');
        return;
      } else {
        console.error(success);
        showAlert({
          type: 'error',
          target: 'global',
          message: 'Failed to verifyEmailCode. Please try again.',
          showIcon: true,
        });
      }
    },
    onError: (error) => {
      const dashboardError = error as DashboardApiError;
      const isReachedMaxVerificationTrial =
        dashboardError.response.data.code === DASHBOARD_ERROR.REACHED_MAX_VERIFICATION_TRIAL;
      const isUserLocked =
        dashboardError.response.data.code === DASHBOARD_ERROR.DASHBOARD_USER_LOCKED;

      if (isReachedMaxVerificationTrial) {
        // 시도횟수 5회 이상이면 재전송 상태로 변경
        stopTime(0);
        setRecoilOtpInfo((info) => {
          if (info) {
            return {
              ...info,
              trialCount: 5,
            };
          }
          return undefined;
        });
      }

      if (isUserLocked) {
        // 이메일 잠금 상태면 LoginEmailLockedPage 페이지로 이동
        try {
          const message: { lockedAt: string } = JSON.parse(dashboardError.response.data.message);
          setRecoilEmailLockedAt(message.lockedAt);
          navigate(RoutePath.loginEmailLockedPage);
          return;
        } catch (error) {
          console.error(error);
        }
      }

      form.setFields([
        {
          name: 'otp',
          errors: ['Invalid verification code.'],
        },
      ]);
      setIsValidOtp(false);
      otpInputRef.current?.focus();
    },
  });

  const { commit: mutateSendEmail, isInFlight: isInFlightSendEmail } = useFaceMutation(
    sendEmailVerificationCode,
    {
      onSuccess: (otpInfo) => {
        startTime(defaultRemainTime);
        setRecoilOtpInfo(otpInfo);
      },
      onError: (error) => {
        const dashboardError = error as DashboardApiError;
        const isUserLocked =
          dashboardError.response.data.code === DASHBOARD_ERROR.DASHBOARD_USER_LOCKED;

        if (isUserLocked) {
          // 이메일 잠금 상태면 LoginEmailLockedPage 페이지로 이동
          try {
            const message: { lockedAt: string } = JSON.parse(dashboardError.response.data.message);
            setRecoilEmailLockedAt(message.lockedAt);
            navigate(RoutePath.loginEmailLockedPage);
            return;
          } catch (error) {
            console.error(error);
          }
        }

        // 기타 에러
        console.error(error);
        showAlert({
          type: 'error',
          target: 'global',
          message: 'Failed to sendEmailVerificationCode. Please try again.',
          showIcon: true,
        });
      },
    }
  );

  if (recoilEmail) {
    if (!recoilOtpInfo) {
      // 이메일은 있으나 인증코드정보가 없으면 로그인 페이지로 이동
      return <Navigate to={routes.loginEmailPage} replace />;
    }
  } else {
    // 이메일이 없으면 홈으로 이동
    return <Navigate to={routes.allDappsPage} replace />;
  }

  return (
    <LoginLayout>
      <StyledModal
        width={'auto'}
        open={true}
        closable={false}
        title={
          <StyledTitleDiv>
            <button className="prev-button" onClick={() => navigate(-1)}>
              <img src={ArrowLeftLine} alt="prev-button" />
            </button>
            <span className="title">Verify Email</span>
            <span className="empty"></span>
          </StyledTitleDiv>
        }
        centered
        mask={false}
        footer={false}>
        <StyledContentDiv>
          <Form
            form={form}
            initialValues={{ email: recoilEmail, otp: '' }}
            onFinish={({ email, otp }) => {
              setIsValidOtp(true);
              mutateVerifyEmail({ receiver: email, code: otp }).catch(() => {});
            }}>
            <Form.Item required name="email">
              <StyledInput
                placeholder="john@facewallet.xyz"
                name="email"
                value={recoilEmail}
                readOnly
              />
            </Form.Item>

            <Spacer y={12} />

            <StyledInputWithTimerDiv
              remainTime={isResendStatus ? '' : secondToMinSec(remainTime)}
              isResendStatus={isResendStatus}>
              <Form.Item
                required
                name="otp"
                help={
                  isValidOtp && !isTimeOver ? 'Email verification code already sent.' : undefined
                }
                rules={[
                  {
                    required: true,
                    validator: () => {
                      setIsValidOtp(true);
                      return Promise.resolve();
                    },
                  },
                ]}>
                <StyledInput
                  autoFocus
                  placeholder="Verification code"
                  name="otp"
                  autoComplete="off"
                  ref={otpInputRef}
                  disabled={isResendStatus}
                />
              </Form.Item>
              {isResendStatus && (
                <StyledResendButton
                  htmlType="button"
                  disabled={isInFlightSendEmail}
                  onClick={() => {
                    stopTime(0);
                    mutateSendEmail({ receiver: recoilEmail }).catch(() => {});
                  }}>
                  Resend code
                </StyledResendButton>
              )}
            </StyledInputWithTimerDiv>

            <StyledButton
              type="primary"
              disabled={
                formValueOtp.length === 0 || !isValidOtp || isResendStatus || isInFlightVerifyCode
              }
              htmlType="submit">
              Verify
            </StyledButton>
          </Form>
        </StyledContentDiv>
      </StyledModal>
    </LoginLayout>
  );
}
