import MetaTag from './MetaTag';
import RootRoute from './routes';

function App() {
  return (
    <>
      <MetaTag />
      <RootRoute />
    </>
  );
}

export default App;
