import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { Container, Description, RequiredMark } from './index.styled';

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  type?: 'regular' | 'bold';
  children: ReactNode;
  required?: boolean;
  description?: ReactNode;
  htmlFor?: string;
}

const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ required, children, description, type = 'regular', ...rest }, ref) => {
    return (
      <Container data-type={type} ref={ref} {...rest}>
        <div>
          {children}
          {required && <RequiredMark />}
        </div>
        {description && <Description>{description}</Description>}
      </Container>
    );
  }
);

export default Label;
