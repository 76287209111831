const DB_NAME = 'face-dashboard';

export const getLocalForageValue = async (key: string) =>
  new Promise((resolve, reject) => {
    const indexedDB = window.indexedDB;
    if (!indexedDB) reject(new Error('IndexedDB not available'));

    const dbRequest = indexedDB.open(DB_NAME);

    dbRequest.onupgradeneeded = () => {
      dbRequest.result.createObjectStore('keyvaluepairs');
    };

    dbRequest.onsuccess = () => {
      const db = dbRequest.result;
      const tx = db.transaction('keyvaluepairs', 'readonly');
      const store = tx.objectStore('keyvaluepairs');
      const getKey = store.get(key);

      getKey.onsuccess = function () {
        resolve(getKey.result);
      };

      tx.onerror = function () {
        reject(tx.error);
      };

      tx.oncomplete = function () {
        db.close();
      };
    };
  });

export const setLocalForageValue = async (key: string, value: any) =>
  new Promise((resolve, reject) => {
    const indexedDB = window.indexedDB;
    if (!indexedDB) reject(new Error('IndexedDB not available'));

    const dbRequest = indexedDB.open(DB_NAME);

    dbRequest.onupgradeneeded = () => {
      dbRequest.result.createObjectStore('keyvaluepairs');
    };

    dbRequest.onsuccess = () => {
      const db = dbRequest.result;
      const tx = db.transaction('keyvaluepairs', 'readwrite');
      const store = tx.objectStore('keyvaluepairs');
      const putKey = store.put(value, key);

      putKey.onsuccess = function () {
        resolve(putKey.result);
      };

      tx.onerror = function () {
        reject(tx.error);
      };

      tx.oncomplete = function () {
        db.close();
      };
    };
  });

export const clearLocalForageValue = async (key: string) =>
  new Promise((resolve, reject) => {
    const indexedDB = window.indexedDB;
    if (!indexedDB) reject(new Error('IndexedDB not available'));

    const dbRequest = indexedDB.open(DB_NAME);

    dbRequest.onupgradeneeded = () => {
      dbRequest.result.createObjectStore('keyvaluepairs');
    };

    dbRequest.onsuccess = () => {
      const db = dbRequest.result;
      const tx = db.transaction('keyvaluepairs', 'readwrite');
      const store = tx.objectStore('keyvaluepairs');
      const deleteKey = store.delete(key);

      deleteKey.onsuccess = function () {
        resolve(deleteKey.result);
      };

      tx.onerror = function () {
        reject(tx.error);
      };

      tx.oncomplete = function () {
        db.close();
      };
    };
  });
