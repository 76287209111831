import styled from '@emotion/styled';
import { colors, typography } from '@haechi-labs/face-design-system';
import { Button } from 'antd';

export const StyledTitleDiv = styled.div`
  padding: 24px 32px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .prev-button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    outline: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .empty {
    width: 24px;
  }
`;

export const StyledContentDiv = styled.div`
  padding: 24px 32px 48px;
`;

export const StyledInputWithTimerDiv = styled.div(
  ({ remainTime, isResendStatus }: { remainTime: string; isResendStatus: boolean }) => `
  position: relative;
  input {
    padding-right: ${remainTime === '' ? '12px' : '60px'};
  }
  .ant-form-item-control-input-content{
    flex: none;
    width: ${isResendStatus ? '192px' : '100%'};
    position: relative;
    :after{
      content: '${remainTime}';
      position: absolute;
      right: 12px;
      top: calc(52px / 2);
      transform: translateY(-50%);
      ${typography.body2.medium};
      color: ${colors.blue[600]}; // secondary
    }
  }
`
);

export const StyledResendButton = styled(Button)`
  position: absolute;
  top: 0px;
  right: 8px;
  width: 120px;
  color: ${colors.blue[600]}; // brand/secondary
  border: 1px solid ${colors.blue[600]};
`;

export const StyledButton = styled(Button)`
  margin: 0;
  margin-top: 16px;
  width: 100%;
`;
