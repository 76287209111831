import { css } from '@emotion/react';
import { colors, semantic, typography } from '@haechi-labs/face-design-system';

const InputStyle = css`
  .ant-input-affix-wrapper {
    height: 52px;

    &:focus,
    &.ant-input-affix-wrapper-focused,
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: ${semantic.colors.secondary.light};
      box-shadow: none;
    }

    .ant-input {
      height: initial;
    }
  }

  .ant-input-wrapper {
    border: 1px solid ${colors.gray[200]};
  }

  .ant-input {
    height: 52px;
    padding-left: 12px;
    padding-right: 12px;
    border-color: ${colors.gray[200]};
    ${typography.body1.medium};

    &::placeholder {
      ${typography.body1.medium};
    }

    &:focus {
      border-color: ${semantic.colors.secondary.light};
      box-shadow: none;
    }
  }
`;

export default InputStyle;
