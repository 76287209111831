import { Route, Routes } from 'react-router-dom';

import UnAuthorizedRoutes from './UnAuthorizedRoutes';
import AuthorizedRoutes from './AuthorizedRoutes';
import { useEffect, useState } from 'react';
import { getStoredSession } from '@/utils/session';
import useSession from '@/hooks/useSession';
import { useInitAxios } from '@/utils/axios';

const RootRoute = () => {
  const { setSession, clearSession } = useSession();
  const [ready, setReady] = useState(false);

  // axios session 설정
  useInitAxios();

  // 최초 리액트 접근했을때 local 저장된 세션을 recoil session에 저장. 이후 useSession 사용
  useEffect(() => {
    getStoredSession().then((storedSession) => {
      try {
        if (storedSession) {
          setSession(storedSession);
        } else {
          clearSession();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setReady(true);
      }
    });
  }, [clearSession, setSession]);

  if (!ready) {
    return null;
  }

  return (
    <Routes>
      <Route path="/auth/*" element={<UnAuthorizedRoutes />} />
      <Route path="/*" element={<AuthorizedRoutes />} />
    </Routes>
  );
};

export default RootRoute;
