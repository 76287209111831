import { SIDEBAR_WIDTH } from '@/constants/layout';
import { css } from '@emotion/react';
import { colors, semantic, typography } from '@haechi-labs/face-design-system';

const MenuStyle = css`
  .ant-menu-submenu-title,
  .ant-menu-item {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    height: 60px !important;
    border-radius: 0 !important;
    padding: 0 24px !important;
    margin-inline: 0 !important;
    gap: 8px;

    ${typography.body1.medium};

    &:not(:disabled) {
      &.no-hover {
        &:hover {
          background: none !important;
        }
      }
      &:not(.no-hover) {
        &:hover {
          background-color: ${colors.gray['200-24%']} !important;
          transition: none !important;
        }
      }

      &:active {
        color: ${colors.gray[600]} !important;
        transition: none !important;
      }
    }

    &.ant-menu-item-selected {
      color: ${semantic.colors.primary.light};
      background: none !important;

      &:not(:disabled) {
        &:active {
          color: ${semantic.colors.secondary.light} !important;
          transition: none !important;
        }
      }
    }
  }

  .ant-menu-title-content {
    display: contents;
  }

  .ant-menu-item-divider {
    border: none !important;
    height: 1px !important;
    background: ${colors.gray[100]};
  }

  .ant-menu-sub {
    width: ${SIDEBAR_WIDTH}px;
  }

  .ant-menu {
    .ant-menu-submenu-arrow {
      transform: rotate(90deg);
    }
  }
`;

export default MenuStyle;
