import BlockchainNetworkSelect from '@/components/BlockchainNetworkSelect';
import Input from '@/components/Input';
import KeyValueTable from '@/components/KeyValueTable';
import Label from '@/components/Label';
import { useAddDappNFT, useValidateDappNFT } from '@/hooks/queries/nfts';
import useErrorMessage from '@/hooks/useErrorMessage';
import { DashboardDappDTO, NFTContractDTO } from '@/__generate__/dashboard-api';
import { colors, IconCheckboxCircle, Spacer } from '@haechi-labs/face-design-system';
import { Form, Select } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';

import Modal, { ModalProps } from 'antd/lib/modal';
import { useState } from 'react';
import { getHumanReadableTokenType, getNftTypes } from '@/utils/contracts';
import { useFaceQuery } from '@/hooks/useFaceQuery';
import { dappQuery } from '@/hooks/useFaceQuery.queries';
import { AxiosError } from 'axios';

interface FormValues {
  network: NFTContractDTO['blockchainNetwork'];
  tokenType: 'erc721' | 'erc1155';
  contractAddress: string;
}

interface RegisterNftModalProps extends ModalProps {
  id: DashboardDappDTO['id'];
  closeModal: () => void;
}

const RegisterNftModal = ({ id, closeModal, ...props }: RegisterNftModalProps) => {
  const [form] = useForm();
  const dapp = useFaceQuery(dappQuery.getDappById, id);
  const [disabled, setDisabled] = useState(true);
  const {
    mutateAsync: validateNft,
    isLoading: isValidating,
    data: validation,
    reset: resetValidation,
  } = useValidateDappNFT();
  const { mutate: addDappNFT } = useAddDappNFT();
  const { getErrorMessage } = useErrorMessage();
  const network = useWatch('network', form);

  const handleSubmit = ({ network, contractAddress, tokenType }: FormValues) => {
    addDappNFT(
      {
        id,
        blockchainNetwork: network,
        tokenType,
        contractAddress,
      },
      {
        onSuccess: () => {
          resetValidation();
          form.resetFields();
        },
      }
    );
    closeModal();
  };

  const handleValuesChange = ({ network, tokenType }: Partial<FormValues>) => {
    if (network || tokenType) {
      form.validateFields(['contractAddress']);
    }
  };

  return (
    <Modal
      title="Register NFT"
      okText="Submit"
      okType="primary"
      closable={false}
      okButtonProps={{
        disabled,
      }}
      onOk={() => form.submit()}
      centered
      {...props}>
      <Form form={form} onFinish={handleSubmit} onValuesChange={handleValuesChange}>
        <Label required>Blockchain Network</Label>
        <Form.Item required name="network">
          <BlockchainNetworkSelect
            apiKey={dapp.apiKey ?? ''}
            selectType="nft"
            placeholder="Select Blockchain Network"
            onChange={() => {
              form.resetFields(['tokenType']);
            }}
          />
        </Form.Item>
        <Spacer y={16} />

        <Label required>Token Standard</Label>
        <Form.Item required name="tokenType" key={network}>
          <Select
            style={{ display: 'block' }}
            options={
              network != null
                ? getNftTypes(network).map((type) => ({
                    value: type,
                    label: getHumanReadableTokenType(type),
                  }))
                : []
            }
            disabled={network == null}
            placeholder="Select Token Standard"
            key={network}
          />
        </Form.Item>
        <Spacer y={16} />

        <Form.Item
          required
          name="contractAddress"
          rules={[
            {
              required: true,
              validator: async (_, contractAddress) => {
                const { network, tokenType } = form.getFieldsValue(['network', 'tokenType']);
                if (network && tokenType && contractAddress) {
                  try {
                    const validation = await validateNft({
                      id,
                      blockchainNetwork: network,
                      tokenType,
                      contractAddress,
                    });
                    if (!validation.valid) {
                      throw new Error(validation.reason?.message, { cause: validation.reason });
                    }
                    setDisabled(false);
                    return Promise.resolve();
                  } catch (error) {
                    const axiosError = error as AxiosError<{
                      code: string;
                      message: string;
                      status: number;
                    }>;
                    setDisabled(true);
                    if (axiosError.response?.data.code === 'MA0007') {
                      return Promise.reject(
                        getErrorMessage(
                          error,
                          `Given blockchain network is not match with the server context: ${network}`
                        )
                      );
                    }
                    return Promise.reject(
                      getErrorMessage(error, `This is not a ${tokenType} contract.`)
                    );
                  }
                }
              },
            },
          ]}>
          <Input
            requiredMark
            label="Smart Contract Address"
            placeholder="Enter Smart Contract Address"
            isLoading={isValidating}
            suffix={
              validation?.metadata && <IconCheckboxCircle color={colors.green[900]} size="sm" />
            }
            allowClear
          />
        </Form.Item>

        {validation?.metadata && (
          <>
            <Spacer y={8} />
            <KeyValueTable
              items={[{ key: 'tokenName', label: 'Token Name', value: validation.metadata.name }]}
            />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default RegisterNftModal;
