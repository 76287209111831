import axios from 'axios';

import { MOON_PAY_API_KEY, MOON_PAY_BASE_URL } from './configs';
import { CurrencyBuyQuote, GetCurrencyBuyQuoteParams, MoonPayCurrency } from './types';

const moonPayApi = axios.create({
  baseURL: MOON_PAY_BASE_URL,
  params: {
    apiKey: MOON_PAY_API_KEY,
  },
});

export async function getCurrencies(): Promise<MoonPayCurrency[]> {
  const res = await moonPayApi.get('/v3/currencies');
  return res.data;
}

export async function getTransactionsByExternalId(externalTransactionId: string) {
  const res = await moonPayApi.get(`/v1/transactions/ext/${externalTransactionId}`);
  return res.data;
}

export async function getTransactionById(id: string) {
  const res = await moonPayApi.get(`/v1/transactions/${id}`);
  return res.data;
}

export async function getCurrencyBuyQuote({
  quoteCurrencyCode,
  ...params
}: GetCurrencyBuyQuoteParams): Promise<CurrencyBuyQuote> {
  const res = await moonPayApi.get(`v3/currencies/${quoteCurrencyCode}/buy_quote`, {
    params: { areFeesIncluded: true, ...params },
  });
  return res.data;
}
