import { Network } from '@haechi-labs/face-types';
import {
  BLOCKCHAIN_INFO_MAP,
  filterNexonNetworksWithNetworkGetter,
  networkConfigs,
  networkToBlockchain,
} from '@haechi-labs/shared';

const useBlockchainNetworks = (apiKey: string) => {
  let blockchainNetworks = filterNexonNetworksWithNetworkGetter(
    apiKey,
    Object.values(Network),
    (item) => item
  );

  const blockchainNetworkMap = blockchainNetworks.reduce((acc, cur) => {
    acc[cur] = {
      value: cur,
      name: networkConfigs.find((network) => network.network === cur)?.name ?? cur,
      icon: BLOCKCHAIN_INFO_MAP[networkToBlockchain(cur)].image,
    };
    return acc;
  }, {} as { [key in Network]: { value: Network; name: string; icon: string } });

  return { blockchainNetworks, blockchainNetworkMap };
};

export default useBlockchainNetworks;
