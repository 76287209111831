import styled from '@emotion/styled';
import { radius } from '@haechi-labs/face-design-system';

export const DappThumbnail = styled.img`
  width: 24px;
  height: 24px;
  border-radius: ${radius.sm};
`;

export const CurrentDappContainer = styled.div`
  display: contents;
  width: 100%;
`;

export const DappName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding-right: 8px;
`;
