import { ReactComponent as MainnetLogo } from '@/assets/imgs/logo.svg';

import { Spacer } from '@haechi-labs/face-design-system';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { ButtonList, Container, LogoLink } from './index.styled';
import useSession from '@/hooks/useSession';

const Gnb = () => {
  const { clearSession } = useSession();

  const handleLogout = () => {
    clearSession();
  };

  return (
    <Container>
      <LogoLink to="/">
        <MainnetLogo style={{ width: 'auto', height: '20px' }} />
        <Spacer x={12} />
      </LogoLink>
      <ButtonList>
        <li>
          <Link to="/auth/login" onClick={handleLogout}>
            <Button type="text" style={{ height: '44px' }}>
              Sign out
            </Button>
          </Link>
        </li>
      </ButtonList>
    </Container>
  );
};

export default Gnb;
