import ThumbnailUploadButton from '@/components/IconUploadButton';
import Input from '@/components/Input';
import Label from '@/components/Label';
import KeyValueTable from '@/components/KeyValueTable';

import { DappCoinDTO, DashboardDappDTO } from '@/__generate__/dashboard-api';
import { colors, IconCheckboxCircle, Spacer, typography } from '@haechi-labs/face-design-system';

import { Form, UploadFile, UploadProps } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';

import Modal, { ModalProps } from 'antd/lib/modal';
import { ReactNode, useDeferredValue, useState, useTransition } from 'react';

import BlockchainNetworkSelect from '@/components/BlockchainNetworkSelect';
import styled from '@emotion/styled';
import { createFaceQuery, useFaceMutation, useFaceQuery } from '@/hooks/useFaceQuery';
import { addDappToken } from '@/apis/tokens';
import { dappQuery, tokenQuery } from '@/hooks/useFaceQuery.queries';
import { AvailableTokenFunctions } from '@/components/AvailableTokenFunctions';
import { getHumanReadableTokenType, getTokenType } from '@/utils/contracts';
import { Network } from '@haechi-labs/face-types';
import invariant from 'tiny-invariant';

interface FormValues {
  network: DappCoinDTO['blockchainNetwork'];
  tokenType: string;
  contractAddress: string;
  iconImages: UploadFile[];
}

interface RegisterTokenModalProps extends ModalProps {
  id: DashboardDappDTO['id'];
  closeModal: () => void;
}

// backend api gives 500 when input is empty
// so we need to fallback if input is empty
const validateTokenWithOptionalInput = async (params: {
  id: string;
  blockchainNetwork: Network;
  tokenType?: 'erc20' | 'fa2';
  contractAddress: string;
}) => {
  // idk why TS cannot guard this correctly
  const tokenType = params.tokenType;
  if (!params.contractAddress || !params.blockchainNetwork || !tokenType) {
    return { valid: false, metadata: void 0, reason: void 0 };
  }
  return tokenQuery.validateDappToken({
    ...params,
    tokenType,
  });
};

const validateTokenWithOptionalInputQuery = createFaceQuery(validateTokenWithOptionalInput);

const RegisterTokenModal = ({ id, closeModal, ...props }: RegisterTokenModalProps) => {
  const [form] = useForm();
  const dapp = useFaceQuery(dappQuery.getDappById, id);
  const network = useWatch('network', form);
  const contractAddress = useWatch('contractAddress', form);
  const [image, setImage] = useState<File | null>(null);
  const deferredContractAddress = useDeferredValue(contractAddress);
  const deferredNetwork = useDeferredValue(network);
  const tokenType = network != null ? getTokenType(network) : void 0;
  const deferredTokenType = useDeferredValue(tokenType);
  const validnessSynced =
    network === deferredNetwork &&
    contractAddress === deferredContractAddress &&
    tokenType === deferredTokenType;
  const validness = useFaceQuery(validateTokenWithOptionalInputQuery, {
    id,
    blockchainNetwork: deferredNetwork,
    tokenType: deferredTokenType,
    contractAddress: deferredContractAddress,
  });
  const addDappTokenMutation = useFaceMutation(addDappToken, {
    onSuccess() {
      startTransition(() => {
        closeModal();
        form.resetFields();
      });
    },
  });
  const [isPending, startTransition] = useTransition();

  const isValid = 'code' in validness ? false : validness.valid;

  let tokenMeta: ReactNode = null;
  let addressSuffix: ReactNode = null;
  let addressError: string | undefined;

  if (validnessSynced && !!contractAddress && !!network) {
    if ('valid' in validness) {
      if (validness.valid) {
        addressSuffix = <IconCheckboxCircle color={colors.green[900]} size="sm" />;
      } else {
        addressError = `This is not an ${tokenType} contract`;
      }

      if (validness.metadata != null) {
        tokenMeta = (
          <>
            <Spacer y={8} />
            <KeyValueTable
              items={[
                {
                  key: 'tokenName',
                  label: 'Token Name',
                  value: validness.metadata.name,
                },
                {
                  key: 'tokenSymbol',
                  label: 'Token Symbol',
                  value: validness.metadata.symbol,
                },
                {
                  key: 'decimals',
                  label: 'Decimals',
                  value: validness.metadata.decimals,
                },
              ]}
            />
            <Spacer y={16} />
            <Label>Available function</Label>
            <AvailableTokenFunctions network={network} contractAddress={contractAddress} />
          </>
        );
      }
    } else {
      if (validness.code === 'MA0011') {
        addressError = 'This is already registered.';
      } else if (validness.code === 'I9999') {
        addressError = `This is not an ${tokenType} contract`;
      } else if (validness.code === 'MA0008') {
        addressError = `This is not an ${tokenType} contract`;
      } else if (validness.code === 'MA0007') {
        addressError = `Given blockchain network is not match with the server context: ${network}`;
      } else {
        addressError = `unknown error happened.`;
        console.error(validness);
      }
    }
  }

  const handleSubmit = ({ network, contractAddress }: FormValues) => {
    startTransition(() => {
      invariant(tokenType != null);
      return addDappTokenMutation.commit({
        id,
        blockchainNetwork: network,
        tokenType,
        contractAddress,
        symbolImage: image || void 0,
      });
    });
  };

  const handleUpload: UploadProps['onChange'] = (e) => {
    form.setFields([
      {
        name: 'iconImages',
        errors: [],
      },
    ]);
    const image = e.fileList[0]?.originFileObj;
    if (image == null) {
      return;
    }
    setImage(image);
  };

  const handleUploadError = () => {
    setImage(null);
    form.setFields([
      {
        name: 'iconImages',
        errors: ['Image size is not 144*144px.'],
      },
    ]);
  };

  return (
    <Modal
      title="Register Token"
      okText="Submit"
      okType="primary"
      closable={false}
      okButtonProps={{ disabled: !isValid, loading: isPending }}
      onOk={() => form.submit()}
      centered
      width={452}
      {...props}>
      <Form form={form} onFinish={handleSubmit} className="without-global-override">
        <Label required>Blockchain Network</Label>
        <Form.Item required name="network">
          <StyledBlockchainNetworkSelect
            apiKey={dapp?.apiKey ?? ''}
            selectType="token"
            placeholder="Select Blockchain Network"
          />
        </Form.Item>
        <Spacer y={16} />

        <Form.Item required>
          <StyledInput
            requiredMark
            label="Token Standard"
            disabled
            value={tokenType != null ? getHumanReadableTokenType(tokenType) : void 0}
          />
        </Form.Item>
        <Spacer y={16} />

        <Form.Item name="contractAddress" rules={[{ required: true }]}>
          <Input
            requiredMark
            label="Smart Contract Address"
            placeholder="Enter Smart Contract Address"
            isLoading={contractAddress !== deferredContractAddress}
            suffix={addressSuffix}
            allowClear
            errorMessage={addressError}
          />
        </Form.Item>
        {tokenMeta}
        <Spacer y={16} />
        <Label description="Size: 144*144px, Format: jpg, png, svg, webp">Token Symbol Image</Label>
        <Form.Item name="iconImages" getValueFromEvent={handleUpload} valuePropName="fileList">
          <ThumbnailUploadButton
            onError={handleUploadError}
            restrict={{ width: 144, height: 144 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegisterTokenModal;

const StyledBlockchainNetworkSelect = styled(BlockchainNetworkSelect)`
  height: 52px;

  .ant-select-selector {
    ${typography.body1.medium};
  }
`;

const StyledInput = styled(Input)`
  ${typography.body1.medium};
`;
