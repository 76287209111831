import {
  QueryClient as TanstackQueryClient,
  useSuspenseQuery as useTanstackSuspenseQuery,
  useMutation as useTanstackMutation,
  QueryClientProvider as TanstackQueryClientProvider,
  useQueryClient,
} from '@tanstack/react-query';
import { ReactNode } from 'react';

const queryClient = new TanstackQueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

export function FaceQueryProvider({ children }: { children?: ReactNode }) {
  return <TanstackQueryClientProvider client={queryClient}>{children}</TanstackQueryClientProvider>;
}

const faceQueryCacheKeyName = '__FACE_QUERY_CACHE_KEY__';

let queryCacheId = 0;

// this is for removing human cache key control
// current limitation is that you can't use this in SSR
// when you need to use this in SSR, need to sync client <> browser cache key
export function createFaceQuery<Variables extends any[], Data>(
  query: (...args: Variables) => Promise<Data> | Data
): FaceQuery<Variables, Data> {
  const faceQuery = (...args: Variables) => {
    return query(...args);
  };

  Object.defineProperty(faceQuery, faceQueryCacheKeyName, {
    value: queryCacheId,
    writable: false,
    enumerable: false,
    configurable: false,
  });

  queryCacheId += 1;

  return faceQuery as FaceQuery<Variables, Data>;
}

export type FaceQuery<Variables extends any[], Data> = ((
  ...args: Variables
) => Promise<Data> | Data) & {
  [faceQueryCacheKeyName]: string;
};

export function useFaceQuery<Variables extends any[], Data>(
  query: FaceQuery<Variables, Data>,
  ...args: Variables
) {
  if (query[faceQueryCacheKeyName] == null) {
    throw new Error('useFaceQuery must be used with createFaceQuery');
  }
  const queryHandler = useTanstackSuspenseQuery<Awaited<Data>, unknown, Awaited<Data>>({
    queryKey: [query[faceQueryCacheKeyName], ...args],
    queryFn: () => {
      return query(...(args as any)) as any;
    },
  });
  return queryHandler.data;
}

export function useFaceMutation<Variables extends any[], Data, Context>(
  mutation: (...args: Variables) => Promise<Data> | Data,
  options?: {
    onCommit?: (variables: Variables) => Context | Promise<Context>;
    onSuccess?: (data: Data, variables: Variables, context: Context) => void;
    onError?: (error: unknown, variables: Variables, context: Context) => void;
    onSettle?: (variables: Variables, context: Context) => void;
  }
) {
  const refresh = useRefresh();
  const handle = useTanstackMutation<Data, unknown, Variables, Context>({
    // @ts-ignore
    mutationFn: async (...args: Variables) => {
      try {
        const result = await mutation(...(args as Variables));
        return result;
      } finally {
        await refresh();
      }
    },
    onMutate: options?.onCommit,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context!);
    },
    onError: (error, variables, context) => {
      options?.onError?.(error, variables, context!);
    },
    onSettled: (_, __, variables, context) => {
      options?.onSettle?.(variables, context!);
    },
  });

  async function commit(...args: Variables) {
    // @ts-ignore
    await handle.mutateAsync(...args);
  }

  return {
    commit,
    previousError: handle.error,
    previousData: handle.data,
    isInFlight: handle.isPending,
    reset: handle.reset,
  };
}

export function useRefresh() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.removeQueries({ type: 'inactive' });
    return queryClient.invalidateQueries();
  };
}
