import { Fragment, ReactNode } from 'react';
import { Container, Label, Value } from './index.styled';

export type KeyValueItem = {
  key: string;
  label: ReactNode;
  value: ReactNode;
};

interface KeyValueTableProps {
  items: KeyValueItem[];
}

const KeyValueTable = ({ items }: KeyValueTableProps) => {
  return (
    <Container>
      {items.map((item) => (
        <Fragment key={item.key}>
          <Label>{item.label}</Label>
          <Value>{item.value}</Value>
        </Fragment>
      ))}
    </Container>
  );
};

export default KeyValueTable;
