import { getDappNFTs, addDappNFT, validateDappNFT, deleteDappNFT } from '@/apis/nfts';
import { DashboardDappDTO, NFTContractDTO } from '@/__generate__/dashboard-api';
import { UseQueryOptions, useQuery, useMutation, useQueryClient } from 'react-query';

const QUERY_KEY = 'dashboard/v1/dapps/nfts';

export const useGetDappNFTs = (
  id: DashboardDappDTO['id'],
  options?: UseQueryOptions<NFTContractDTO[]>
) => {
  return useQuery<NFTContractDTO[]>([QUERY_KEY, id], () => getDappNFTs(id), {
    enabled: !!id,
    initialData: [],
    ...options,
  });
};

export const useAddDappNFT = () => {
  const queryClient = useQueryClient();

  return useMutation(addDappNFT, {
    onSuccess: (_, params) => {
      queryClient.invalidateQueries([QUERY_KEY, params.id]);
    },
  });
};

export const useDeleteDappNFT = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteDappNFT, {
    onSuccess: (_, params) => {
      queryClient.invalidateQueries([QUERY_KEY, params.id]);
    },
  });
};

export const useValidateDappNFT = () => {
  return useMutation(validateDappNFT);
};
