import { css } from '@emotion/react';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

const CardStyle = css`
  .ant-card {
    border: 1px solid ${colors.gray[100]};
    border-radius: ${radius.xl};
    overflow: hidden;

    .ant-card-head {
      padding: 0 32px !important;
      padding-top: 24px !important;
      padding-bottom: 16px !important;
      border-bottom: none !important;
      color: ${colors.gray[900]} !important;
    }

    .ant-card-head-title {
      display: flex;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      ${typography.h4};
    }

    .ant-card-extra {
      padding: 0;

      .ant-btn {
        padding: 12px 16px !important;
        height: 44px !important;
      }
    }

    .ant-card-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0px 32px 32px !important;
    }

    &[data-type='only-header'] {
      .ant-card-head {
        padding: 24px 32px !important;
      }

      .ant-card-body {
        display: none;
      }
    }

    &.full-height {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 200px;

      .ant-card-body {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    &.no-padding {
      .ant-card-body {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
`;

export default CardStyle;
