import Modal, { ModalProps } from 'antd/lib/modal';

const DeleteNftModal = (props: ModalProps) => {
  return (
    <Modal title="Delete NFT?" okText="Delete" okType="danger" closable={false} centered {...props}>
      This NFT is not shown in the user wallet. You can re-register, if necessary.
    </Modal>
  );
};

export default DeleteNftModal;
