import styled from '@emotion/styled';
import { colors, radius } from '@haechi-labs/face-design-system';

export const Button = styled.button`
  width: 100%;
  display: block;
  background: ${colors.white};
  padding: 20px;
  border: 1px solid ${colors.gray[200]};
  border-radius: ${radius.sm};

  &:hover {
    background: ${colors.gray['200-24%']};
  }
`;

export const IconArea = styled.div`
  width: 32px;
  height: 32px;
  background: ${colors.white};
  border-radius: ${radius.sm};
  display: flex;
  align-items: center;
  justify-content: center;
`;
