import { useForm, useWatch } from 'antd/lib/form/Form';
import { Form } from 'antd';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePath from '@/constants/routes';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { emailAtom } from '@/stores/emailAtom';
import { otpInfoAtom } from '@/stores/otpInfoAtom';
import { StyledButton, StyledInput, StyledModal } from './loginEmailPage.styled';
import { useAutoFocus } from './useAutoFocus';
import { useFaceMutation } from '@/hooks/useFaceQuery';
import { EMAIL_REGEX } from '@/constants/regex';
import { sendEmailVerificationCode } from '@/apis/users';
import { LoginLayout } from './LoginLayout';
import { emailLockedAtAtom } from '@/stores/emailLockedAtAtom';
import { StyledContentDiv, StyledTitleDiv } from './loginEmailVerifyPage.styled';
import useAlert from '@/hooks/layouts/useAlert';
import { DashboardApiError } from '@/types/axios';
import { DASHBOARD_ERROR } from '@/constants/dashboardError';

type formSchema = {
  email: string;
};

export function LoginEmailPage() {
  const navigate = useNavigate();
  // 모달 인풋 포커스
  const inputRef = useRef(null);
  useAutoFocus(inputRef);

  const { showAlert } = useAlert();
  const [form] = useForm<formSchema>();
  const formValueEmail = useWatch('email', form) || '';

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [recoilEmail, setRecoilEmail] = useRecoilState(emailAtom);
  const setRecoilEmailLockedAt = useSetRecoilState(emailLockedAtAtom);
  const setRecoilOtpInfo = useSetRecoilState(otpInfoAtom);

  // mutations
  const { commit: mutateSendEmail, isInFlight } = useFaceMutation(sendEmailVerificationCode, {
    onSuccess: (otpInfo) => {
      setRecoilOtpInfo(otpInfo);
      navigate(RoutePath.loginEmailVerifyPage);
    },
    onError: (error) => {
      const dashboardError = error as DashboardApiError;
      const isUserLocked =
        dashboardError.response.data.code === DASHBOARD_ERROR.DASHBOARD_USER_LOCKED;

      if (isUserLocked) {
        // 이메일 잠금 상태면 LoginEmailLockedPage 페이지로 이동
        try {
          const message: { lockedAt: string } = JSON.parse(dashboardError.response.data.message);
          setRecoilEmailLockedAt(message.lockedAt);
          navigate(RoutePath.loginEmailLockedPage);
          return;
        } catch (error) {
          console.error(error);
        }
      }
      // 기타 에러
      showAlert({
        type: 'error',
        target: 'global',
        message: 'Failed to sendEmailVerificationCode. Please try again.',
        showIcon: true,
      });
    },
  });

  return (
    <LoginLayout>
      <StyledModal
        width={'auto'}
        open={true}
        closable={false}
        title={
          <StyledTitleDiv>
            <span className="empty"></span>
            <span className="title">Continue with Email</span>
            <span className="empty"></span>
          </StyledTitleDiv>
        }
        centered
        mask={false}
        footer={false}>
        <StyledContentDiv>
          <Form
            form={form}
            initialValues={{ email: recoilEmail }}
            onFinish={async ({ email }) => {
              setRecoilEmail(email);
              // 코드가 만료되기 전이면 기존 코드 재전송
              mutateSendEmail({ receiver: email }).catch(() => {});
            }}>
            <Form.Item
              required
              name="email"
              help={isValidEmail ? `The email address to use in Dashboard.` : undefined}
              rules={[
                {
                  required: true,
                  validator: async (_, email) => {
                    if (EMAIL_REGEX.test(email)) {
                      setIsValidEmail(true);
                      return Promise.resolve();
                    }
                    setIsValidEmail(false);
                    return Promise.reject(new Error('This is not a valid email address format.'));
                  },
                },
              ]}>
              <StyledInput placeholder="john@facewallet.xyz" name="email" ref={inputRef} />
            </Form.Item>

            <StyledButton
              type="primary"
              disabled={formValueEmail?.length === 0 || !isValidEmail || isInFlight}
              htmlType="submit">
              Send Code
            </StyledButton>
          </Form>
        </StyledContentDiv>
      </StyledModal>
    </LoginLayout>
  );
}
