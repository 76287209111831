import { useEffect, useRef, useState } from 'react';

// from iframe
const useCounter = (time: number) => {
  const [remainTime, setRemainTime] = useState<number>(time > 0 ? time : 0);
  const timer = useRef<NodeJS.Timer>();

  useEffect(() => {
    timer.current = setInterval(() => {
      setRemainTime((remainTime) => {
        if (remainTime > 0) {
          return remainTime - 1;
        }
        clearInterval(timer.current);
        return 0;
      });
    }, 1000);
    return () => clearInterval(timer.current);
  }, []);

  const startTime = (newTime?: number) => {
    stopTime();
    let time = newTime !== undefined ? newTime : remainTime;
    timer.current = setInterval(() => {
      time = time - 1;
      setRemainTime(time > 0 ? time : 0);
      if (time <= 0) {
        stopTime();
      }
    }, 1000);
  };

  const setTime = (time: number) => {
    setRemainTime(time > 0 ? time : 0);
  };

  const stopTime = (newTime?: number) => {
    clearInterval(timer.current);
    if (newTime !== undefined) {
      setRemainTime(newTime > 0 ? newTime : 0);
    }
  };

  return {
    remainTime,
    isTimeOver: remainTime === 0,
    setTime,
    startTime,
    stopTime,
  };
};

export default useCounter;

export function secondToMinSec(second: number) {
  const min = `${Math.floor(second / 60)}`.padStart(2, '0');
  const sec = `${second % 60}`.padStart(2, '0');
  return min + ':' + sec;
}

export function secondToHourMin(second: number) {
  const hour = Math.floor(second / 3600);
  const min = `${Math.floor((second % 3600) / 60)}`.padStart(2, '0');
  return `${hour} hr ${min} mins`;
}
