import { css } from '@emotion/react';
import { colors, typography } from '@haechi-labs/face-design-system';

const ListStyle = css`
  .ant-list-item {
    padding: 16px 12px !important;
    height: 52px;
    ${typography.body2.medium};

    &:hover {
      transition: background 0.3s;
      background: ${colors.gray['200-24%']};
    }
  }

  .ant-list-item-action {
    display: flex;
    align-items: center;

    .ant-btn-icon-only {
      background: ${colors.white};

      &:hover {
        background: ${colors.white};
      }
    }
  }

  .ant-spin-container {
    .ant-list-empty-text {
      ${typography.body2.regular};
      color: ${colors.gray[400]};
      text-align: left;
      padding: 16px 12px;
    }
  }

  .no-empty {
    .ant-list-empty-text {
      display: none;
    }
  }
`;
export default ListStyle;
