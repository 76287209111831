import {
  colors,
  Flexbox,
  IconExternalLink,
  IconTrash,
  Spacer,
} from '@haechi-labs/face-design-system';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import List from 'antd/lib/list';

import Divider from 'antd/lib/divider';
import Input from 'antd/lib/input';
import { DashboardDappDTO } from '@/__generate__/dashboard-api';
import { useAppendDappAllowedDomains, useRevokeDappAllowedDomains } from '@/hooks/queries/dapps';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useErrorMessage from '@/hooks/useErrorMessage';
import useAlert from '@/hooks/layouts/useAlert';

import { DOCS_URLS } from '@/constants/urls';
import { useState } from 'react';
import { validateUrl } from './validate';

interface AllowedDomainListSectionProps {
  dapp: DashboardDappDTO;
}

interface FormValues {
  allowedDomain: string;
}

const AllowedDomainListSection = ({ dapp }: AllowedDomainListSectionProps) => {
  const { getErrorMessage } = useErrorMessage();
  const { mutate: appendDappAllowedDomains } = useAppendDappAllowedDomains();
  const { mutate: revokeDappAllowedDomains } = useRevokeDappAllowedDomains();
  const { showAlert } = useAlert();
  const [disabled, setDisabled] = useState(true);

  const [form] = useForm();

  const handleSubmit = (values: FormValues) => {
    appendDappAllowedDomains(
      { id: dapp.id, allowedDomain: values.allowedDomain },
      {
        onSuccess: () => {
          form.resetFields(['allowedDomain']);
        },
        onError: (error) => {
          form.setFields([
            {
              name: 'allowedDomain',
              errors: [getErrorMessage(error, 'Failed to register Domain. Please try again.')],
            },
          ]);
        },
      }
    );
  };
  const handleRevokeDappAllowedDomains = (domain: string) => () => {
    revokeDappAllowedDomains(
      { id: dapp.id, allowedDomain: domain },
      {
        onSuccess: () => {
          showAlert({
            type: 'success',
            target: 'contents',
            message: 'Successfully deleted Domain.',
            showIcon: true,
          });
        },
        onError: () => {
          showAlert({
            type: 'error',
            target: 'contents',
            message: 'Failed to delete Domain.',
            showIcon: true,
          });
        },
      }
    );
  };

  const handleFormChange = () => {
    const allowedDomain = form.getFieldValue('allowedDomain');
    if (typeof allowedDomain === 'string') {
      setDisabled(allowedDomain.length <= 0);
    }
  };

  return (
    <Card
      title={
        <>
          Allowlist Domains <Divider type="vertical" />
          <Button
            type="text"
            size="small"
            target="_blank"
            href={DOCS_URLS.allowDomains}
            style={{ marginLeft: '-4px' }}>
            <Flexbox gap={4}>
              Docs
              <IconExternalLink fill={colors.gray[600]} size="sm" />
            </Flexbox>
          </Button>
        </>
      }
      style={{ width: 960 }}>
      <Form form={form} onFinish={handleSubmit} onFieldsChange={handleFormChange}>
        <Form.Item
          name="allowedDomain"
          style={{ flex: 1 }}
          rules={[
            {
              validator: async (_, url) => {
                if (validateUrl(url)) return Promise.resolve();
                return Promise.reject();
              },
              validateTrigger: 'onSubmit',
              message: 'This is not a valid Domain format.',
              required: true,
            },
          ]}>
          <Flexbox gap={16}>
            <Input placeholder="https://dev.facewallet.xyz, https://*.facewallet.xyz, http://localhost:8080" />
            <Button type="primary" htmlType="submit" disabled={!dapp.apiKey || disabled}>
              Add
            </Button>
          </Flexbox>
        </Form.Item>
      </Form>
      <Spacer y={16} />
      <List
        locale={{ emptyText: "Only registered Domains can use this app's API Key." }}
        dataSource={dapp.domains?.filter((domain) => domain)}
        renderItem={(domain) => (
          <List.Item
            actions={[
              <Button
                type="text"
                icon={<IconTrash style={{ color: colors.gray[500] }} />}
                onClick={handleRevokeDappAllowedDomains(domain)}
              />,
            ]}>
            {domain}
          </List.Item>
        )}
      />
    </Card>
  );
};

export default AllowedDomainListSection;
