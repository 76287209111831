import { css } from '@emotion/react';
import { colors, typography } from '@haechi-labs/face-design-system';

const SelectStyle = css`
  .ant-select-single {
    height: auto;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 52px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: ${colors.gray[800]};
    ${typography.body1.medium}
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-search-input {
    ${typography.body1.medium};
  }

  .ant-select {
    .ant-select-selection-item {
      ${typography.body1.medium};
    }
  }

  .ant-select-item.ant-select-item-option {
    display: flex;
    align-items: center;
    height: 48px;
    ${typography.body1.medium};

    &.ant-select-item-option-selected {
      background: ${colors.gray['200-24%']};
    }
  }

  .ant-select-selection-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .ant-select-dropdown {
    padding: 0;
  }
`;
export default SelectStyle;
