import useBlockchainNetworks from '@/hooks/useBlockchainNetworks';
import { Flexbox } from '@haechi-labs/face-design-system';
import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { forwardRef, useMemo } from 'react';
import { StyledNetworkIconImg } from './index.styled';
import { getNftTypes, getTokenType } from '@/utils/contracts';
import placeholderThumbnail from '@/assets/imgs/placeholder-thumbnail.svg';
import { getEnv } from '@/utils/env';
import { Env } from '@haechi-labs/face-types';

interface BlockchainNetworkSelectProps extends SelectProps {
  apiKey: string;
  selectType?: 'token' | 'nft';
}

const BlockchainNetworkSelect = forwardRef<BaseSelectRef, BlockchainNetworkSelectProps>(
  ({ apiKey, selectType, ...props }, ref) => {
    const { blockchainNetworks, blockchainNetworkMap } = useBlockchainNetworks(apiKey);

    if (getEnv() !== Env.ProdMainnet) {
      const face_debug: any = ((window as any).face_debug = (window as any).face_debug || {});
      face_debug.blockchainNetworks = blockchainNetworks;
      face_debug.apiKey = apiKey;
    }
    const availableNetworksByType = blockchainNetworks.filter((network) => {
      try {
        switch (selectType) {
          case 'token':
            return Boolean(getTokenType(network));
          case 'nft':
            return Boolean(getNftTypes(network));
          default:
            return true;
        }
      } catch (error) {
        if (getEnv() !== Env.ProdMainnet) {
          console.log('error in blockchain network select');
          console.error(error);
        }
        return false;
      }
    });
    if (getEnv() !== Env.ProdMainnet) {
      const face_debug: any = ((window as any).face_debug = (window as any).face_debug || {});
      face_debug.availableNetworksByType = availableNetworksByType;
    }

    const selectOptions = useMemo(() => {
      return [...availableNetworksByType]
        .sort((a, b) => {
          if (a > b) return 1;
          else if (a < b) return -1;
          return 0;
        })
        .map((network) => ({
          label: (
            <Flexbox gap={8}>
              <StyledNetworkIconImg
                src={blockchainNetworkMap[network].icon}
                size={24}
                onError={(e) => {
                  e.currentTarget.src = placeholderThumbnail;
                }}
              />
              {blockchainNetworkMap[network].name}
            </Flexbox>
          ),
          value: blockchainNetworkMap[network].value,
        }));
    }, [blockchainNetworkMap, availableNetworksByType]);

    return <Select ref={ref} style={{ display: 'block' }} options={selectOptions} {...props} />;
  }
);

export default BlockchainNetworkSelect;
