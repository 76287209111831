import styled from '@emotion/styled';

import { colors, typography } from '@haechi-labs/face-design-system';

export const Container = styled.label`
  display: inline-block;
  margin-bottom: 4px;
  ${typography.body2.medium};

  &[data-type='bold'] {
    color: ${colors.gray[700]};
    ${typography.body2.bold};
    margin-bottom: 8px;
  }
`;

export const RequiredMark = styled.span`
  ${typography.body2.medium};
  color: ${colors.red[500]};

  &::before {
    content: '*';
  }
`;

export const Description = styled.p`
  padding: 0;
  margin: 0;
  color: ${colors.gray[500]};
  ${typography.caption1.regular};
`;
