import { colors, Flexbox, Text } from '@haechi-labs/face-design-system';
import { HTMLAttributes, ReactNode } from 'react';
import { Button, IconArea } from './index.styled';

interface MessageButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  icon?: ReactNode;
}

const MessageButton = ({ children, icon, ...props }: MessageButtonProps) => {
  return (
    <Button {...props}>
      <Text type="body2" color={colors.gray[700]} align="left" style={{ width: '100%' }}>
        <Flexbox gap={12} style={{ width: '100%' }}>
          <div style={{ flex: 1 }}>{children}</div>
          {icon && <IconArea>{icon}</IconArea>}
        </Flexbox>
      </Text>
    </Button>
  );
};

export default MessageButton;
