import axios from '@/utils/axios';
import {
  NFTContractDTO,
  DashboardDappDTO,
  DeleteDappNFTRequest,
  ValidateDappTokenRequest,
  ValidateDappTokenResponse,
} from '@/__generate__/dashboard-api';
import { Network } from '@haechi-labs/face-types';

export type NFTContractV2DTO = Omit<NFTContractDTO, 'blockchain'>;

export const getDappNFTs = async (id: DashboardDappDTO['id']): Promise<NFTContractV2DTO[]> => {
  const { data } = await axios.get(`/dashboard/v2/dapps/nfts`, {
    headers: { 'X-Face-Dapp-Id': id },
  });
  return data;
};

export interface AddDappNFTParams {
  id: DashboardDappDTO['id'];
  blockchainNetwork: NFTContractDTO['blockchainNetwork'];
  tokenType: 'erc721' | 'erc1155' | 'fa2';
  contractAddress: string;
}

export const addDappNFT = async (params: AddDappNFTParams): Promise<NFTContractV2DTO> => {
  const { data } = await axios.post(
    `/dashboard/v2/dapps/nfts`,
    { ...params },
    {
      headers: { 'X-Face-Dapp-Id': params.id },
    }
  );
  return data;
};

export interface DeleteDappNFTParams extends DeleteDappNFTRequest {
  id: DashboardDappDTO['id'];
}

export const deleteDappNFT = async (params: DeleteDappNFTParams): Promise<NFTContractV2DTO> => {
  const { data } = await axios.delete(`/dashboard/v2/dapps/nfts`, {
    headers: { 'X-Face-Dapp-Id': params.id },
    data: {
      ...params,
    },
  });
  return data;
};

export interface ValidateDappTokenParams extends ValidateDappTokenRequest {
  id: DashboardDappDTO['id'];
  blockchainNetwork: Network;
  tokenType: 'erc721' | 'erc1155' | 'fa2';
  contractAddress: string;
}

export const validateDappNFT = async (
  params: ValidateDappTokenParams
): Promise<ValidateDappTokenResponse> => {
  const { data } = await axios.post(
    `/dashboard/v2/dapps/nfts/validate`,
    { ...params },
    {
      headers: { 'X-Face-Dapp-Id': params.id },
    }
  );
  return data;
};
