import axios from '@/utils/axios';
import {
  AddUserInsightRequestsRequest,
  UserInsightsSummaryDTO,
  UserInsightsDTO,
  DashboardDappDTO,
} from '@/__generate__/dashboard-api/api';

export type UserInsightsParams = {
  size?: number;
  page?: number;
  searchParam?: string;
};

export const getUserInsights = async (
  params: UserInsightsParams,
  id: DashboardDappDTO['id']
): Promise<UserInsightsDTO> => {
  const { data } = await axios.get(`/dashboard/v1/dapps/user-insights`, {
    params,
    headers: { 'X-Face-Dapp-Id': id },
  });
  return data;
};

export interface AddUserInsightRequestsParams extends AddUserInsightRequestsRequest {
  id: DashboardDappDTO['id'];
}

export const addUserInsightRequestsRequest = async (
  params: AddUserInsightRequestsParams
): Promise<void> => {
  return await axios.post(
    `/dashboard/v1/dapps/user-insights/request`,
    { ...params },
    {
      headers: { 'Content-Type': 'multipart/form-data', 'X-Face-Dapp-Id': params.id },
    }
  );
};

export const getUserInsightsSummary = async (
  id: DashboardDappDTO['id']
): Promise<UserInsightsSummaryDTO> => {
  const { data } = await axios.get(`/dashboard/v1/dapps/user-insights/summary`, {
    headers: { 'X-Face-Dapp-Id': id },
  });
  return data;
};
