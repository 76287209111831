import {
  appendDappAllowedAppIds,
  appendDappAllowedDomains,
  getDappById,
  revokeDappAllowedAppIds,
  revokeDappAllowedDomains,
  rollApiKey,
  getDapps,
  createDapp,
  updateDappInfo,
} from '@/apis/dapps';
import { DashboardDappDTO } from '@/__generate__/dashboard-api';

import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useRefresh } from '../useFaceQuery';

export const QUERY_KEY = 'dashboard/v1/dapps';

export const useDappsQuery = (options?: UseQueryOptions<DashboardDappDTO[]>) => {
  return useQuery<DashboardDappDTO[]>(QUERY_KEY, getDapps, options);
};

export const useDappByIdQuery = (
  id: DashboardDappDTO['id'],
  options?: UseQueryOptions<DashboardDappDTO>
) => {
  return useQuery<DashboardDappDTO>([QUERY_KEY, id], () => getDappById(id), {
    ...options,
    enabled: !!id,
  });
};

export const useCreateDapp = () => {
  const queryClient = useQueryClient();
  return useMutation(createDapp, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY);
    },
  });
};

export const useAppendDappAllowedDomains = () => {
  const queryClient = useQueryClient();
  return useMutation(appendDappAllowedDomains, {
    onSuccess: (result, params) => {
      queryClient.setQueryData([QUERY_KEY, params.id], result);
      queryClient.invalidateQueries([QUERY_KEY, params.id]);
    },
  });
};

export const useRevokeDappAllowedDomains = () => {
  const queryClient = useQueryClient();
  return useMutation(revokeDappAllowedDomains, {
    onSuccess: (result, params) => {
      queryClient.setQueryData([QUERY_KEY, params.id], result);
      queryClient.invalidateQueries([QUERY_KEY, params.id]);
    },
  });
};

export const useAppendDappAllowedAppIds = () => {
  const queryClient = useQueryClient();
  return useMutation(appendDappAllowedAppIds, {
    onSuccess: (result, params) => {
      queryClient.setQueryData([QUERY_KEY, params.id], result);
      queryClient.invalidateQueries([QUERY_KEY, params.id]);
    },
  });
};

export const useRevokeDappAllowedAppIds = () => {
  const queryClient = useQueryClient();
  return useMutation(revokeDappAllowedAppIds, {
    onSuccess: (result, params) => {
      queryClient.setQueryData([QUERY_KEY, params.id], result);
      queryClient.invalidateQueries([QUERY_KEY, params.id]);
    },
  });
};

export const useUpdateDappInfo = () => {
  const queryClient = useQueryClient();
  return useMutation(updateDappInfo, {
    onSuccess: (result, params) => {
      queryClient.setQueryData([QUERY_KEY, params.id], result);
      queryClient.invalidateQueries([QUERY_KEY, params.id]);
      queryClient.invalidateQueries([QUERY_KEY]);
    },
  });
};

export const useRollApiKey = () => {
  const refresh = useRefresh();
  const queryClient = useQueryClient();
  return useMutation(rollApiKey, {
    onSuccess: (_, params) => {
      queryClient.invalidateQueries([QUERY_KEY, params.id]);
      refresh();
    },
  });
};
