import ContentLayout from '@/layouts/ContentLayout';
import { Spacer } from '@haechi-labs/face-design-system';
import GuideContents from './GuideContents';
import { useDappByIdQuery } from '@/hooks/queries/dapps';
import { useParams } from 'react-router-dom';
import TotalUsers from './TotalUsers';
import UsersPerCountry from './UsersPerCountry';
import UserInformation from './UserInformation';

const UserInsightsPage = () => {
  const { dappId = '' } = useParams();
  const { data: dapp } = useDappByIdQuery(dappId, { suspense: true });

  const hasPermission = !!dapp?.userInsightRequest?.userInsightEnabled;
  if (!hasPermission) {
    return (
      <ContentLayout>
        <GuideContents dapp={dapp!} />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout>
      <TotalUsers />
      <Spacer y={24} />
      <UsersPerCountry />
      <Spacer y={24} />
      <UserInformation />
    </ContentLayout>
  );
};

export default UserInsightsPage;
