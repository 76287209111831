import axios from '@/utils/axios';
import {
  AppendDappAllowedAppIdRequest,
  AppendDappAllowedDomainRequest,
  CreateDappRequest,
  DashboardDappDTO,
  RevokeDappAllowedAppIdRequest,
  RevokeDappAllowedDomainRequest,
  RollApiKeyRequest,
  UpdateDappInfoRequest,
} from '@/__generate__/dashboard-api';
import { TurnOnOnRampRequest } from '../__generate__/dashboard-api';

export const getDapps = async (): Promise<DashboardDappDTO[]> => {
  const { data } = await axios.get('/dashboard/v1/dapps');
  return data;
};

export const getDappById = async (id: DashboardDappDTO['id']): Promise<DashboardDappDTO> => {
  if (!id) {
    throw new Error('dappId is required');
  }
  const { data } = await axios.get(`/dashboard/v1/dapps/${id}`, {
    headers: { 'X-Face-Dapp-Id': id },
  });
  return data;
};

export type CreateDappV2Request = {
  name: string;
  blockchainNetworks: string[];
  launchDateTime: number;
  domainUrl: string;
};

export const createDapp = (params: CreateDappV2Request): Promise<DashboardDappDTO> => {
  return axios.post(`/dashboard/v2/dapps`, { ...params });
};

export interface AppendDappAllowedDomainsParams extends AppendDappAllowedDomainRequest {
  id: DashboardDappDTO['id'];
}

export const appendDappAllowedDomains = async (
  params: AppendDappAllowedDomainsParams
): Promise<DashboardDappDTO> => {
  const { data } = await axios.patch(
    `/dashboard/v1/dapps/allowed-domains`,
    { ...params },
    {
      headers: { 'X-Face-Dapp-Id': params.id },
    }
  );
  return data;
};

export interface RevokeDappAllowedDomainParams extends RevokeDappAllowedDomainRequest {
  id: DashboardDappDTO['id'];
}

export const revokeDappAllowedDomains = async (
  params: RevokeDappAllowedDomainParams
): Promise<DashboardDappDTO> => {
  const { data } = await axios.delete(`/dashboard/v1/dapps/allowed-domains`, {
    headers: { 'X-Face-Dapp-Id': params.id },
    data: { ...params },
  });
  return data;
};

export interface AppendDappAllowedAppIdParams extends AppendDappAllowedAppIdRequest {
  id: DashboardDappDTO['id'];
}

export const appendDappAllowedAppIds = async (
  params: AppendDappAllowedAppIdParams
): Promise<DashboardDappDTO> => {
  const { data } = await axios.patch(
    `/dashboard/v1/dapps/allowed-app-ids`,
    { ...params },
    {
      headers: { 'X-Face-Dapp-Id': params.id },
    }
  );
  return data;
};

export interface RevokeDappAllowedAppIdParams extends RevokeDappAllowedAppIdRequest {
  id: DashboardDappDTO['id'];
}

export const revokeDappAllowedAppIds = async (
  params: RevokeDappAllowedAppIdParams
): Promise<DashboardDappDTO> => {
  const { data } = await axios.delete(`/dashboard/v1/dapps/allowed-app-ids`, {
    headers: { 'X-Face-Dapp-Id': params.id },
    data: { ...params },
  });
  return data;
};

export interface UpdateDappInfoParams extends UpdateDappInfoRequest {
  id: DashboardDappDTO['id'];
}

export type UpdateDappInfoParamsV2 = {
  id: string;
  name: string;
  blockchainNetworks: Array<string>;
  launchDateTime: number;
  domainUrl: string;
  iconImage?: any;
};
export const updateDappInfo = async (params: UpdateDappInfoParamsV2): Promise<DashboardDappDTO> => {
  const formData = new FormData();
  params.name && formData.append('name', params.name);
  if (params.blockchainNetworks) {
    params.blockchainNetworks.forEach((blockchainNetworks) => {
      formData.append('blockchains[]', blockchainNetworks);
    });
  }
  params.launchDateTime && formData.append('launchDateTime', params.launchDateTime.toString());
  params.domainUrl && formData.append('domainUrl', params.domainUrl);
  params.iconImage && formData.append('iconImage', params.iconImage);
  const { data } = await axios.patch(`/dashboard/v2/dapps`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', 'X-Face-Dapp-Id': params.id },
  });
  return data;
};

export interface RollApiKeyParams extends RollApiKeyRequest {
  id: DashboardDappDTO['id'];
  apiKey: string;
}

export const rollApiKey = async (params: RollApiKeyParams) => {
  const { data } = await axios.post(
    `/dashboard/v1/dapps/api-key`,
    { ...params },
    { headers: { 'X-Face-Dapp-Id': params.id } }
  );
  return data;
};

export const turnOnSwap = async (id: DashboardDappDTO['id']) => {
  await axios.post(`/dashboard/v1/dapps/swap-status/on`, null, {
    headers: { 'X-Face-Dapp-Id': id },
  });
};

export const turnOffSwap = async (id: DashboardDappDTO['id']) => {
  await axios.post(`/dashboard/v1/dapps/swap-status/off`, null, {
    headers: { 'X-Face-Dapp-Id': id },
  });
};

export const turnOnBridge = async (id: DashboardDappDTO['id']) => {
  await axios.post(`/dashboard/v1/dapps/bridge-status/on`, null, {
    headers: { 'X-Face-Dapp-Id': id },
  });
};

export const turnOffBridge = async (id: DashboardDappDTO['id']) => {
  await axios.post(`/dashboard/v1/dapps/bridge-status/off`, null, {
    headers: { 'X-Face-Dapp-Id': id },
  });
};

export const turnOnOnRamp = async (params: TurnOnOnRampRequest & { id: string }) => {
  await axios.post(`/dashboard/v1/dapps/on-ramp-status/on`, params, {
    headers: { 'Content-Type': 'multipart/form-data', 'X-Face-Dapp-Id': params.id },
  });
};

export const turnOffOnRamp = async (id: DashboardDappDTO['id']) => {
  await axios.post(`/dashboard/v1/dapps/on-ramp-status/off`, null, {
    headers: { 'X-Face-Dapp-Id': id },
  });
};
