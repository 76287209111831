import { css } from '@emotion/react';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

const ModalStyle = css`
  .ant-modal {
    .ant-modal-mask {
      background: ${colors['black-50%']};
    }

    .ant-modal-content {
      width: 452px;
      border-radius: ${radius.lg};
      overflow: hidden;
      padding: 0;
    }

    .ant-modal-header {
      border-radius: initial;
      border-bottom: none;
      padding-top: 40px;
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .ant-modal-title {
      ${typography.h5};
    }

    .ant-modal-body {
      padding: 40px 32px;
      color: ${colors.gray[800]};
      ${typography.body1.medium};
    }

    .ant-modal-footer {
      border-top: none;
      margin-top: 0;
      padding: 8px 32px 48px;
    }
  }
`;

export default ModalStyle;
