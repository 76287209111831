import { DashboardDappDTO } from '@/__generate__/dashboard-api';
import Placeholder from '@/assets/imgs/placeholder-thumbnail.svg';
import { Thumbnail } from './index.styled';

interface DappThumbnailProps {
  dapp: DashboardDappDTO;
  size?: number;
  radius?: number | string;
}

const DappThumbnail = ({ dapp, size = 64, radius }: DappThumbnailProps) => {
  return (
    <Thumbnail
      src={dapp.iconImage || Placeholder}
      alt={`${dapp.name}-thumbnail`}
      style={{ width: size, height: size, borderRadius: radius }}
      onError={(e) => {
        e.currentTarget.src = Placeholder;
      }}
    />
  );
};

export default DappThumbnail;
