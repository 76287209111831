import 'antd/dist/reset.css';

import { Global } from '@emotion/react';
import ButtonStyle from './styles/button';
import CardStyle from './styles/card';
import CommonStyle from './styles/common';
import DividerStyle from './styles/divider';
import InputStyle from './styles/input';
import ListStyle from './styles/list';
import MenuStyle from './styles/menu';
import ModalStyle from './styles/modal';
import LayoutStyle from './styles/layout';
import TableStyle from './styles/table';
import SelectStyle from './styles/select';
import FormStyle from './styles/form';
import AlertStyle from './styles/alert';

const GlobalStyle = () => {
  return (
    <Global
      styles={[
        AlertStyle,
        ButtonStyle,
        CardStyle,
        CommonStyle,
        DividerStyle,
        FormStyle,
        InputStyle,
        LayoutStyle,
        ListStyle,
        MenuStyle,
        ModalStyle,
        SelectStyle,
        TableStyle,
      ]}
    />
  );
};

export default GlobalStyle;
