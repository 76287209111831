import { Flexbox, IconErrorFill, Spacer } from '@haechi-labs/face-design-system';
import { ReactNode, useMemo } from 'react';
import { Container } from './index.styled';

interface NotificationBoxProps {
  type: 'default' | 'error';
  children?: ReactNode;
  title?: ReactNode;
}

const NotificationBox = ({ type, title, children }: NotificationBoxProps) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'default':
      case 'error': {
        return <IconErrorFill size="sm" />;
      }
      default:
        return null;
    }
  }, [type]);

  return (
    <Container data-type={type}>
      <div className="title">
        <Flexbox gap={4} align="start">
          {icon}
          {title}
        </Flexbox>
      </div>
      <Spacer y={8} />
      <div className="body">{children}</div>
    </Container>
  );
};

export default NotificationBox;
