import styled from '@emotion/styled';

const ContentLayout = styled.div`
  padding: 24px 32px 32px;
  height: 100%;
  overflow: scroll;

  & > .ant-card {
    min-width: 960px;
  }
`;

export default ContentLayout;
