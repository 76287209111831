import styled from '@emotion/styled';
import { colors, radius, shadows, typography } from '@haechi-labs/face-design-system';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  width: 240px;
  height: 240px;
  border: 1px solid ${colors.gray[200]};
  background: ${colors.white};
  border-radius: ${radius.xl};
  transition: box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  color: ${colors.gray[800]};
  ${typography.h4};

  &:hover {
    color: initial;
    box-shadow: ${shadows.sm};
  }
`;

export const DappName = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  padding: 0 24px;
`;
