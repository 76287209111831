import Card from '@/components/Card';
import { Title } from '@haechi-labs/face-design-system';
import { useGetUserInsightsSummary } from '@/hooks/queries/userInsights';

const TotalUsers = () => {
  const { data } = useGetUserInsightsSummary({ suspense: true });

  return (
    <Card title={'Total Users'}>
      <Title level="1">{data?.totalUsers?.toLocaleString('en-US')}</Title>
    </Card>
  );
};

export default TotalUsers;
