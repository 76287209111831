import Modal, { ModalProps } from 'antd/lib/modal';

import { useNavigate } from 'react-router-dom';
import useAuthorizedRoutePaths from '@/hooks/useAuthorizedRoutePaths';

interface GuideGenerateCredentialModalProps extends ModalProps {
  desc: string;
}

const GuideGenerateCredentialModal = ({
  desc,
  ...modalProps
}: GuideGenerateCredentialModalProps) => {
  const navigate = useNavigate();
  const authorizedPaths = useAuthorizedRoutePaths();
  return (
    <Modal
      title="Please generate API Credentials"
      okText="Continue"
      centered
      closable={false}
      onOk={() => {
        navigate(`/${authorizedPaths[':dappId'].dappDetailPage}`);
      }}
      {...modalProps}>
      {desc}
    </Modal>
  );
};

export default GuideGenerateCredentialModal;
