import { useEffect, useRef } from 'react';

/**
 * ref를 넘겨주면 마운트 후에 focus 시켜주는 훅(autoFocus 안될때 사용)
 */
export function useAutoFocus(ref: React.MutableRefObject<any>) {
  const isFocusedRef = useRef(false);

  useEffect(() => {
    if (ref?.current) {
      if (
        document.activeElement === ref.current ||
        // for antd input, select
        document.activeElement === ref.current?.input ||
        document.activeElement === ref.current?.select
      ) {
        isFocusedRef.current = true;
      }
      if (!isFocusedRef.current) {
        ref?.current?.focus?.();
      }
    }
  });
}
