import Card from '@/components/Card';
import { colors, Text } from '@haechi-labs/face-design-system';
import { useGetUserInsightsSummary } from '@/hooks/queries/userInsights';
import { useMemo } from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: 'Rank',
    dataIndex: 'no',
    key: 'no',
    width: 120,
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: 200,
  },
  {
    title: 'Number of Users',
    dataIndex: 'count',
    key: 'count',
    width: '100%',
  },
  { title: 'Rank', dataIndex: 'no2', key: 'no2', width: 120 },
  {
    title: 'Country',
    dataIndex: 'country2',
    key: 'country2',
    width: 200,
  },
  {
    title: 'Number of Users',
    dataIndex: 'count2',
    key: 'count2',
    width: '100%',
  },
];

const UsersPerCountry = () => {
  const { data } = useGetUserInsightsSummary({ suspense: true });
  const usersPerCountry = data?.usersPerCountry;

  const tableData = useMemo(() => {
    delete usersPerCountry?.['No Country'];
    const usersPerCountryEntries = Object.entries(usersPerCountry || {});
    if (!usersPerCountryEntries.length) {
      return [];
    }
    const sortedUsersPerCountry = usersPerCountryEntries
      .sort((a, b) => b[1] - a[1])
      .concat(Array(20).fill(['-', '-']))
      .slice(0, 20)
      .map(([key, value]) => (value ? { key, value } : { key: '-', value: '-' }));

    const result = [];
    for (let i = 0; i < 10; i++) {
      const arr = sortedUsersPerCountry;
      const secondIndex = i + 10;
      result.push({
        key: `${i}/${arr[i].key}/${arr[secondIndex].key}`,
        no: i + 1,
        country: arr[i].key,
        count: arr[i].value.toLocaleString('en-US'),
        no2: secondIndex + 1,
        country2: arr[secondIndex].key,
        count2: arr[secondIndex].value.toLocaleString('en-US'),
      });
    }
    return result;
  }, [usersPerCountry]);

  return (
    <Card
      title={'Number of Users by Country'}
      extra={
        <Text type="body2" color={colors.gray[500]}>
          Only SMS OTP registered users
        </Text>
      }
      className="no-padding">
      <Table
        columns={columns}
        dataSource={tableData}
        locale={{ emptyText: 'No user data' }}
        pagination={false}
        tableLayout="fixed"
      />
    </Card>
  );
};

export default UsersPerCountry;
