/**
 * 백엔드에서 관리하는 에러코드
 */
export const DASHBOARD_ERROR = {
  EMPTY_VERIFICATION_EMAIL: 'DS0001',
  OWNER_ONLY_PERMISSION: 'DS0002',
  IS_NOT_CONNECTED_MEMBER: 'DS0003',
  CANNOT_TRANSFER_OWNER_TO_OWNER: 'DS0004',
  REQUESTER_DAPP_DASHBOARD_USER_NOT_FOUND: 'DS0005',
  REQUESTER_DAPP_ID_NOT_FOUND: 'DS0006',
  REQUESTER_DASHBOARD_USER_ID_NOT_FOUND: 'DS0007',
  DAPP_DASHBOARD_USER_NOT_FOUND: 'DS0008',
  PERMISSION_REQUIRED: 'DS0009',
  IS_NOT_OWNER: 'DS0010',
  DASHBOARD_USER_NOT_FOUND: 'DS0011',
  DASHBOARD_INVALID_ID_TOKEN: 'DS0012',
  DASHBOARD_INVALID_INVITATION_TOKEN: 'DS0013',
  DASHBOARD_INVITATION_TOKEN_EXPIRED: 'DS0014',
  DAPP_DASHBOARD_USER_CONNECT_FAILED: 'DS0015',
  RESEND_INVITATION: 'DS0016',
  REACHED_MAX_VERIFICATION_TRIAL: 'DS0017',
  VERIFICATION_EXCEEDED_EXPIRATION: 'DS0018',
  DASHBOARD_ID_TOKEN_EXPIRED: 'DS0019',
  DASHBOARD_ID_TOKEN_NOT_PROVIDED: 'DS0020',
  DASHBOARD_VERIFICATION_CODE_NOT_MATCHED: 'DS0021',
  DASHBOARD_DAPP_ID_NOT_PROVIDED: 'DS0022',
  DASHBOARD_DAPP_ID_MISMATCHED: 'DS0023',
  DASHBOARD_USER_ID_MISMATCHED: 'DS0024',
  DASHBOARD_USER_INSIGHT_ALREADY_ENABLED: 'DS0025',
  DASHBOARD_USER_INSIGHT_NOT_ENABLED: 'DS0026',
  INVALID_BLOCKCHAIN: 'DS0027',
  DASHBOARD_USER_LOCKED: 'DS0028',
  INTERNAL_DASHBOARD_SERVER: 'DS9999',
} as const;

/* 
EMPTY_VERIFICATION_EMAIL("DS0001", "No verification email exists or already verified"),
OWNER_ONLY_PERMISSION("DS0002", "These permissions can only be updated by owner"),
IS_NOT_CONNECTED_MEMBER("DS0003", "Not dapp connected member"),
CANNOT_TRANSFER_OWNER_TO_OWNER("DS0004", "Owner cannot transfer ownership to another owner"),
REQUESTER_DAPP_DASHBOARD_USER_NOT_FOUND("DS0005", "DappDashboardUser does not exist in request attributes"),
REQUESTER_DAPP_ID_NOT_FOUND("DS0006", "DappId does not exist in request attributes"),
REQUESTER_DASHBOARD_USER_ID_NOT_FOUND("DS0007", "DashboardUserId does not exist in request attributes"),
DAPP_DASHBOARD_USER_NOT_FOUND("DS0008", "Can not find dapp dashboard user"),
PERMISSION_REQUIRED("DS0009", "You don't have required permissions"),
IS_NOT_OWNER("DS0010", "Not authorized as an owner"),
DASHBOARD_USER_NOT_FOUND("DS0011", "Dashboard user not found"),
DASHBOARD_INVALID_ID_TOKEN("DS0012", "Dashboard id token is not validated"),
DASHBOARD_INVALID_INVITATION_TOKEN("DS0013", "Dashboard invitation token is invalid"),
DASHBOARD_INVITATION_TOKEN_EXPIRED("DS0014", "Dashboard invitation token expired"),
DAPP_DASHBOARD_USER_CONNECT_FAILED("DS0015", "Failed to connect user with dapp"),
RESEND_INVITATION("DS0016", "Invitation token expired, resend invitation email"),
REACHED_MAX_VERIFICATION_TRIAL("DS0017", "Reached max trial counts"),
VERIFICATION_EXCEEDED_EXPIRATION("DS0018", "Exceeded expiration time"),
DASHBOARD_ID_TOKEN_EXPIRED("DS0019", "Dashboard id token expired"),
DASHBOARD_ID_TOKEN_NOT_PROVIDED("DS0020", "Id token not provided"),
DASHBOARD_VERIFICATION_CODE_NOT_MATCHED("DS0021", "Verification code not match"),
DASHBOARD_DAPP_ID_NOT_PROVIDED("DS0022", "Dapp id not provided"),
DASHBOARD_DAPP_ID_MISMATCHED("DS0023", "DappIds from path and header mismatched"),
DASHBOARD_USER_ID_MISMATCHED("DS0024", "UserId from path and header mismatched"),
DASHBOARD_USER_INSIGHT_ALREADY_ENABLED("DS0025", "UserInsights ticket is already requested"),
DASHBOARD_USER_INSIGHT_NOT_ENABLED("DS0026", "UserInsights is not enabled yet"),
INVALID_BLOCKCHAIN("DS0027", "Invalid blockchain name"),
DASHBOARD_USER_LOCKED("DS0028", "Dashboard user is locked"),
INTERNAL_DASHBOARD_SERVER("DS9999", "Internal dashboard server error"), 
*/
