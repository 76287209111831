import Modal, { ModalProps } from 'antd/lib/modal';
import { useTransition } from 'react';

const DeleteTokenModal = (props: ModalProps) => {
  const [isPending, startTransition] = useTransition();

  return (
    <Modal
      title="Delete Token?"
      okText="Delete"
      okType="danger"
      closable={false}
      centered
      okButtonProps={{ loading: isPending }}
      {...props}
      onOk={(event) => {
        startTransition(() => {
          return props.onOk?.(event);
        });
      }}>
      This token is not shown in the user wallet. You can re-register later, if necessary.
    </Modal>
  );
};

export default DeleteTokenModal;
