import Input from '@/components/Input';
import Label from '@/components/Label';
import useAlert from '@/hooks/layouts/useAlert';
import { useCreateDapp } from '@/hooks/queries/dapps';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal, { ModalProps } from 'antd/lib/modal';
import { useState } from 'react';

interface FormValues {
  name: string;
}

interface CreateDappModalProps extends ModalProps {
  closeModal: () => void;
}

const CreateDappModal = ({ closeModal, ...props }: CreateDappModalProps) => {
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);
  const { mutate: createDapp } = useCreateDapp();
  const { showAlert } = useAlert();

  const handleFormChange = () => {
    const name = form.getFieldValue('name');
    if (typeof name === 'string') {
      setDisabled(name.length <= 0);
    }
  };

  // TODO: 새로운 form 데이터 넣어줘야함
  // @ts-expect-error
  const handleSubmit = ({ name, blockchainNetworks, launchDateTime, domainUrl }: FormValues) => {
    setDisabled(true);
    createDapp(
      { name, blockchainNetworks, launchDateTime, domainUrl },
      {
        onSuccess: () => {
          setDisabled(false);
          closeModal();
        },
        onError: () => {
          setDisabled(false);
          showAlert({
            type: 'error',
            target: 'contents',
            message: 'Failed to create Dapp. Please try again.',
            showIcon: true,
          });
        },
      }
    );
  };

  return (
    <Modal
      title="Create Dapp"
      okText="Create"
      centered
      okButtonProps={{ disabled }}
      onOk={() => form.submit()}
      closable={false}
      {...props}>
      <Form form={form} onFinish={handleSubmit} onFieldsChange={handleFormChange}>
        <Label description="Please input the OFFICIAL name of your Dapp" required>
          Dapp Name
        </Label>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              validateTrigger: 'onSubmit',
            },
          ]}>
          <Input placeholder="Enter Dapp Name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateDappModal;
