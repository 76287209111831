import { useState } from 'react';

const useModal = <T extends string>(initialKey?: T) => {
  const [modalOpen, setModalOpen] = useState<boolean>(!!initialKey);
  const [openedKey, setOpenedKey] = useState<T | null>(null);

  const openModal = (key?: T) => {
    setModalOpen(true);
    key && setOpenedKey(key);
  };

  const closeModal = () => {
    setModalOpen(false);
    setOpenedKey(null);
  };

  const toggleModal = () => setModalOpen((value) => !value);

  return {
    open: modalOpen,
    openedKey,
    openModal,
    closeModal,
    toggleModal,
  };
};

export default useModal;
