import styled from '@emotion/styled';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

export const Container = styled.div`
  padding: 48px 56px;
  height: 100%;
  overflow-y: scroll;
`;

export const DappList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const CreateButton = styled.button`
  width: 240px;
  height: 240px;
  padding: 0;
  margin: 0;
  background: ${colors.gray[100]};
  border: 1px solid ${colors.gray[300]};
  border-radius: ${radius.xl};
  color: ${colors.gray[700]};
  transition: background 0.3s;

  ${typography.h6};

  &:hover {
    background: ${colors.gray[200]};
  }
`;
