import { sidebarAtom } from '@/stores/layoutAtom';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

const useSidebar = (show?: boolean) => {
  const [showSidebar, setShowSidebar] = useRecoilState(sidebarAtom);

  useEffect(() => {
    if (typeof show === 'boolean') {
      setShowSidebar(show);

      return () => {
        setShowSidebar(true);
      };
    }
  }, [setShowSidebar, show]);

  return showSidebar;
};

export default useSidebar;
