import { useDappsQuery } from '@/hooks/queries/dapps';

import useModal from '@/hooks/useModal';
import useSidebar from '@/hooks/layouts/useSidebar';
import CreateDappModal from '@/modals/CreateDappModal';
import DappItem from './DappItem';
import { DappList, Container, CreateButton } from './index.styled';
import { IconAdd } from '@haechi-labs/face-design-system';

const AllDappsPage = () => {
  useSidebar(false);
  const { open, closeModal, openModal } = useModal();

  const { data: dapps = [] } = useDappsQuery({ suspense: true });

  return (
    <Container>
      <DappList>
        {dapps.map((dapp) => (
          <li key={dapp.id}>
            <DappItem dapp={dapp} />
          </li>
        ))}
        <li>
          <CreateButton
            onClick={() => {
              openModal();
            }}>
            <div>
              <IconAdd style={{ marginBottom: '8px' }} />
            </div>
            Create Dapp
          </CreateButton>
        </li>
      </DappList>
      <CreateDappModal open={open} onCancel={closeModal} closeModal={closeModal} />
    </Container>
  );
};

export default AllDappsPage;
