import RoutePath, { AuthorizedRoutePath } from '@/constants/routes';
import useSidebar from '@/hooks/layouts/useSidebar';
import DefaultLayout from '@/layouts/DefaultLayout';
import AllDappsPage from '@/pages/AllDappsPage';

import { Navigate, Route, Routes } from 'react-router-dom';
import DappRouter from './DappRouter';
import useSession from '@/hooks/useSession';

const AuthorizedRoutes = () => {
  const showSidebar = useSidebar();

  // 세션 없으면 로그인 페이지로 이동
  const { session } = useSession();
  if (!session) {
    return <Navigate to={RoutePath.loginPage} />;
  }

  return (
    <DefaultLayout showSidebar={showSidebar}>
      <Routes>
        <Route path={AuthorizedRoutePath.allDappsPage} element={<AllDappsPage />} />
        <Route path=":dappId/*" element={<DappRouter />} />
      </Routes>
    </DefaultLayout>
  );
};

export default AuthorizedRoutes;
