import styled from '@emotion/styled';
import { colors, typography } from '@haechi-labs/face-design-system';

export const StyledContentDiv = styled.div`
  padding: 0 32px;
  text-align: center;
`;
export const StyledTimerDiv = styled.div`
  ${typography.h5};
  color: ${colors.gray[700]};
  padding-bottom: 4px;
`;
export const StyledDescriptionDiv = styled.div`
  ${typography.caption1.regular};
  a {
    color: ${colors.blue[400]};
  }
`;
export const StyledFooterLogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #c1ccd6;
  opacity: 0.8;
  ${typography.caption1.medium};

  img {
    height: 12px;
  }
`;
