import styled from '@emotion/styled';
import { typography } from '@haechi-labs/face-design-system';
import { Link } from 'react-router-dom';

export const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const ButtonList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  ${typography.body1.medium};
`;
