import * as dappApis from '@/apis/dapps';
import * as tokenApis from '@/apis/tokens';
import * as nftApis from '@/apis/nfts';
import * as userInsightApis from '@/apis/userInsights';
import { FaceQuery, createFaceQuery } from './useFaceQuery';

export const dappQuery = convertApisToQueries(dappApis);
export const tokenQuery = convertApisToQueries(tokenApis);
export const nftQuery = convertApisToQueries(nftApis);
export const userInsightQuery = convertApisToQueries(userInsightApis);

function convertApisToQueries<T extends { [key: string]: (...args: any[]) => any }>(apis: T) {
  const queries: { [K in keyof T]: FaceQuery<Parameters<T[K]>, ReturnType<T[K]>> } = {} as any;
  for (const key in apis) {
    queries[key] = createFaceQuery(apis[key]);
  }
  return queries;
}
