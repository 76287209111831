import Label from '@/components/Label';
import { copyText } from '@/utils/copy';
import { DashboardDappDTO } from '@/__generate__/dashboard-api';
import { colors, IconCopy, IconEye, IconEyeOff } from '@haechi-labs/face-design-system';
import Button from 'antd/lib/button';
import { useMemo, useState } from 'react';
import { ButtonArea, Container, KeyArea } from './index.styled';
import useAlert from '@/hooks/layouts/useAlert';

interface KeyBoxProps {
  apiKey: DashboardDappDTO['apiKey'];
}

const KeyBox = ({ apiKey: originKey = '' }: KeyBoxProps) => {
  const { showAlert } = useAlert();
  const [visible, setVisible] = useState(false);

  const key = useMemo(() => {
    return visible ? originKey : originKey.replaceAll(/./g, '•');
  }, [originKey, visible]);

  const handleToggleVisible = () => {
    setVisible((visible) => !visible);
  };

  const handleCopyClick = async () => {
    try {
      await copyText(originKey);
      showAlert({
        type: 'success',
        target: 'contents',
        message: 'Successfully copied the API Key.',
        showIcon: true,
      });
    } catch (error) {
      showAlert({
        type: 'error',
        target: 'contents',
        message: 'Failed to copy the API Key.',
        showIcon: true,
      });
    }
  };

  return (
    <>
      <Label type="bold">API Key</Label>
      <Container>
        <KeyArea>{key}</KeyArea>
        <ButtonArea>
          <Button
            type="text"
            icon={
              visible ? (
                <IconEyeOff color={colors.gray[700]} />
              ) : (
                <IconEye color={colors.gray[700]} />
              )
            }
            size="middle"
            onClick={handleToggleVisible}
          />
          <Button
            type="text"
            icon={<IconCopy color={colors.gray[700]} />}
            size="middle"
            onClick={handleCopyClick}
          />
        </ButtonArea>
      </Container>
    </>
  );
};
export default KeyBox;
