import { css } from '@emotion/react';

const AlertStyle = css`
  .ant-alert {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;

export default AlertStyle;
