import Button from 'antd/lib/button';
import KeyBox from './KeyBox';
import Divider from 'antd/lib/divider';
import {
  colors,
  Flexbox,
  IconExternalLink,
  IconKey,
  IconRefresh,
  Spacer,
  Text,
} from '@haechi-labs/face-design-system';
import RollKeyModal from '@/modals/RollKeyModal';
import useModal from '@/hooks/useModal';
import { DashboardDappDTO } from '@/__generate__/dashboard-api';

import { DOCS_URLS } from '@/constants/urls';
import Card from '@/components/Card';
import GenerateKeyModal from '@/modals/GenerateKeyModal';
import Box from '@/components/Box';

interface ApiKeySectionProps {
  dapp: DashboardDappDTO;
}

const ApiKeySection = ({ dapp }: ApiKeySectionProps) => {
  const { closeModal, openModal, openedKey } = useModal<'roll' | 'generate'>();

  return (
    <>
      <Card
        title={
          <>
            API Credentials
            <Divider type="vertical" />
            <Button
              type="text"
              size="small"
              target="_blank"
              href={DOCS_URLS.apiKey}
              style={{ marginLeft: '-4px' }}>
              <Flexbox gap={4}>
                Docs
                <IconExternalLink fill={colors.gray[600]} size="sm" />
              </Flexbox>
            </Button>
          </>
        }
        extra={
          dapp.apiKey ? (
            <Button
              type="default"
              icon={<IconRefresh size="sm" style={{ marginRight: '8px' }} />}
              onClick={() => {
                openModal('roll');
              }}>
              Roll Key
            </Button>
          ) : (
            <Button
              type="primary"
              icon={<IconKey size="sm" style={{ marginRight: '8px' }} />}
              onClick={() => {
                openModal('generate');
              }}>
              Generate
            </Button>
          )
        }
        style={{ width: 960 }}>
        {dapp.apiKey && (
          <>
            <KeyBox apiKey={dapp.apiKey} />
            <Spacer y={16} />
            <Box title="API Secret">
              <Flexbox gap={12}>
                <IconKey /> API Secret successfully generated.
              </Flexbox>
            </Box>
            <Spacer y={8} />
            <Text type="caption1" color={colors.gray[500]}>
              You can copy the API Secret only while you generate the API Credentials. If you lose
              the API Secret, please renew with the Roll Key button above.
            </Text>
          </>
        )}
      </Card>
      <RollKeyModal
        open={openedKey === 'roll'}
        onCancel={closeModal}
        onOk={() => {
          openModal('generate');
        }}
      />
      {openedKey === 'generate' && ( // 열릴 때마다 modal의 state를 초기화하기 위해서 이렇게 처리함
        <GenerateKeyModal open={openedKey === 'generate'} onOk={closeModal} onCancel={closeModal} />
      )}
    </>
  );
};

export default ApiKeySection;
