import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@haechi-labs/face-design-system';

export const Container = styled.div`
  white-space: nowrap;
`;

export const Icon = styled.img<{ size: number }>`
  border-radius: 100%;
  border: 1px solid ${colors.gray[100]};
  box-sizing: border-box;
  margin-right: -4px;
  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
    `};

  &:last-of-type {
    margin-right: 0;
  }
`;
