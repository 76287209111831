import styled from '@emotion/styled';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  border-radius: ${radius.xs};
  background: #fafafa;
  border: 1px;
  border-color: #d9d9d9;

  &[aria-invalid='true'] {
    border: 1px solid ${colors.red[600]};
  }
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 32px;
  color: ${colors.red[600]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ErrorFileName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding: 0 8px;
  text-align: center;
  margin-top: 8px;
  ${typography.body1.regular}
`;
