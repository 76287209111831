import jwt from 'jsonwebtoken';

/**
 * 세션 jwt에서 payload를 가져오는 함수
 */
export function getSessionUser(session: string) {
  try {
    const payload = jwt.decode(session);
    if (payload) {
      return payload;
    }
  } catch (error) {
    console.error(error);
  }
  return null;
}

/**
 * 코드 생성시간과 최대시간을 받아서 남은 시간을 계산하는 함수
 */
export function dateToRemainTime(dateString: string | undefined, max = 600) {
  if (!dateString) {
    return 0;
  }
  // 백엔드 타임은 항상 UTC without Timezone 이라서 Z를 추가해서 계산하기
  const dateTime = new Date(dateString + 'Z').getTime();
  if (isNaN(dateTime)) {
    throw new Error(`date parse fail: ${dateString}`);
  }
  let remainSeconds = new Date(new Date().toUTCString()).getTime() - dateTime;
  // 초 차이 계산
  remainSeconds = max - Math.floor(remainSeconds / 1000);
  remainSeconds = remainSeconds <= 0 ? 0 : remainSeconds >= max ? max : remainSeconds;
  return remainSeconds;
}
