import ThumbnailUploadButton from '@/components/IconUploadButton';
import Input from '@/components/Input';
import Label from '@/components/Label';
import KeyValueTable from '@/components/KeyValueTable';
import { DashboardDappDTO } from '@/__generate__/dashboard-api';
import { Spacer, typography } from '@haechi-labs/face-design-system';
import { Form, UploadProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal, { ModalProps } from 'antd/lib/modal';
import { useState, useTransition } from 'react';
import BlockchainNetworkSelect from '@/components/BlockchainNetworkSelect';
import styled from '@emotion/styled';
import { useFaceMutation, useFaceQuery } from '@/hooks/useFaceQuery';
import { dappQuery, tokenQuery } from '@/hooks/useFaceQuery.queries';
import invariant from 'tiny-invariant';
import { AvailableTokenFunctions } from '@/components/AvailableTokenFunctions';
import { updateDappTokenImage } from '@/apis/tokens';

interface RegisterTokenModalProps extends ModalProps {
  id: DashboardDappDTO['id'];
  dappId: string;
  closeModal: () => void;
}

const UpdateTokenModal = ({ id, dappId, closeModal, ...props }: RegisterTokenModalProps) => {
  const [form] = useForm();
  const dapp = useFaceQuery(dappQuery.getDappById, dappId);
  const tokens = useFaceQuery(tokenQuery.getDappTokens, dappId);
  const token = tokens.find((x) => x.id === id);
  invariant(token, 'token must exist');
  const [image, setImage] = useState<File | null>(null);

  const [isPending, startTransition] = useTransition();

  const updateTokenMutation = useFaceMutation(updateDappTokenImage);

  const handleSubmit = () => {
    startTransition(async () => {
      await updateTokenMutation.commit({
        coinId: id,
        id: dappId,
        symbolImage: image || void 0,
      });
      closeModal();
    });
  };

  const handleUpload: UploadProps['onChange'] = (e) => {
    form.setFields([
      {
        name: 'iconImages',
        errors: [],
      },
    ]);
    const image = e.fileList[0]?.originFileObj;
    if (image == null) {
      return;
    }
    setImage(image);
  };

  const handleUploadError = () => {
    setImage(null);
    form.setFields([
      {
        name: 'iconImages',
        errors: ['Image size is not 144*144px.'],
      },
    ]);
  };

  return (
    <Modal
      title="Token Information"
      okText="Save"
      okType="primary"
      closable={false}
      okButtonProps={{ loading: isPending, disabled: image == null }}
      onOk={() => form.submit()}
      centered
      width={452}
      {...props}>
      <Form form={form} onFinish={handleSubmit} className="without-global-override">
        <Label required>Blockchain Network</Label>
        <Form.Item>
          <StyledBlockchainNetworkSelect
            apiKey={dapp?.apiKey ?? ''}
            selectType="token"
            placeholder="Select Blockchain Network"
            value={token.blockchainNetwork}
            disabled
          />
        </Form.Item>
        <Spacer y={16} />
        <Form.Item>
          <StyledInput requiredMark value="ERC20" label="Token Standard" disabled />
        </Form.Item>
        <Spacer y={16} />
        <Form.Item>
          <Input
            requiredMark
            label="Smart Contract Address"
            disabled
            value={token.contractAddress}
            allowClear
          />
        </Form.Item>
        <Spacer y={8} />
        <KeyValueTable
          items={[
            {
              key: 'tokenName',
              label: 'Token Name',
              value: token.coinName,
            },
            {
              key: 'tokenSymbol',
              label: 'Token Symbol',
              value: token.symbol,
            },
            {
              key: 'decimals',
              label: 'Decimals',
              value: token.decimal,
            },
          ]}
        />
        <Spacer y={16} />
        <Label>Available function</Label>
        <AvailableTokenFunctions
          network={token.blockchainNetwork}
          contractAddress={token.contractAddress}
        />
        <Spacer y={16} />
        <Label description="Size: 144*144px, Format: jpg, png, svg, webp">Token Symbol Image</Label>
        <Form.Item name="iconImages" getValueFromEvent={handleUpload}>
          <ThumbnailUploadButton
            onError={handleUploadError}
            restrict={{ width: 144, height: 144 }}
            initialImageUrl={token.imageUrl}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateTokenModal;

const StyledBlockchainNetworkSelect = styled(BlockchainNetworkSelect)`
  height: 52px;

  .ant-select-selector {
    ${typography.body1.medium};
  }
`;

const StyledInput = styled(Input)`
  ${typography.body1.medium};
`;
