import {
  AccountInfoDTO,
  LoginResponse,
  SendCodeResponse,
  SendVerificationEmailRequest,
  VerifyVerificationEmailRequest,
} from '@/__generate__/dashboard-api';
import axios from '@/utils/axios';

export const setAccountInfo = async (request: AccountInfoDTO) => {
  await axios.patch('/dashboard/v1/users', request);
};

export const acceptInvitation = async (invitationToken: string): Promise<LoginResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/users/accept-invitation',
    {},
    {
      headers: {
        'X-Face-Dashboard-Invitation-Token': invitationToken,
      },
    }
  );
  return data;
};

export const sendEmailVerificationCode = async (
  request: SendVerificationEmailRequest
): Promise<SendCodeResponse> => {
  const { data } = await axios.post('/dashboard/v1/users/send-code', request);
  return data;
};

export const verify = async (request: VerifyVerificationEmailRequest): Promise<LoginResponse> => {
  const { data } = await axios.post('/dashboard/v1/users/verify', request);
  return data;
};
