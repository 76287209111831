import { AlertProps } from 'antd';
import { atom, selector } from 'recoil';

export const sidebarAtom = atom<boolean>({
  key: 'sidebarAtom',
  default: false,
});

export interface AlertEvent extends Pick<AlertProps, 'closable' | 'showIcon' | 'message' | 'type'> {
  target: 'global' | 'contents';
  time?: number;
}

export const alertEventQueueAtom = atom<AlertEvent[]>({
  key: 'alertEventQueueAtom',
  default: [],
});

export const alertEventSelector = selector({
  key: 'alertEventSelector',
  get: ({ get }) => {
    const queue = get(alertEventQueueAtom);
    if (queue.length > 0) return queue[0];
    return null;
  },
});

export const globalAlertEventQueueAtom = atom<AlertEvent[]>({
  key: 'globalAlertEventQueueAtom',
  default: [],
});

export const globalAlertEventSelector = selector({
  key: 'globalAlertEventSelector',
  get: ({ get }) => {
    const queue = get(globalAlertEventQueueAtom);
    if (queue.length > 0) return queue[0];
    return null;
  },
});
