import { Loader } from '@haechi-labs/face-design-system';
import OriginInput, { InputProps, InputRef } from 'antd/lib/input';
import { forwardRef, useId, useMemo } from 'react';
import Label from '../Label';
import { ErrorMessage } from './index.styled';

export interface Props extends InputProps {
  label?: string;
  errorMessage?: string;
  isLoading?: boolean;
  requiredMark?: boolean;
}

const Input = forwardRef<InputRef, Props>(
  ({ label, errorMessage, isLoading, requiredMark, ...rest }, ref) => {
    const id = useId();

    const suffix = useMemo(() => {
      if (isLoading) {
        return <Loader />;
      }
      return rest.suffix;
    }, [isLoading, rest.suffix]);

    return (
      <>
        {label && (
          <Label required={rest.required || requiredMark} htmlFor={id}>
            {label}
          </Label>
        )}
        <OriginInput id={id} ref={ref} {...rest} suffix={suffix} />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    );
  }
);

export default Input;
