import {
  Container,
  Description,
  FaceThumbnail,
  Graphic,
  LoginButton,
  SubTitle,
  Title,
  Wrapper,
} from './index.styled';
import MainImage from '@/assets/imgs/main-vis.png';
import { Flexbox, IconArrowRight, Spacer } from '@haechi-labs/face-design-system';
import faceIcon from '@/assets/imgs/face-icon.svg';
import { useNavigate } from 'react-router-dom';
import RoutePath from '@/constants/routes';

export const LoginPage = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <div>
          <Title>Face the new Web</Title>
          <SubTitle>Onboarding 3 Billion Web3 Gamers</SubTitle>
          <Spacer y={40} />

          <Flexbox gap={16}>
            <LoginButton
              onClick={() => {
                navigate(RoutePath.loginEmailPage);
              }}>
              <Flexbox gap={12}>
                <FaceThumbnail src={faceIcon} alt="face-wallet-logo" />
                Login to Dashboard
              </Flexbox>
              <IconArrowRight />
            </LoginButton>
          </Flexbox>

          <Description>
            By clicking the button, I acknowledge to Facewallet’s{' '}
            <a
              href="https://docs.facewallet.xyz/page/face-wallet-developer-sdk-license-terms-of-use"
              target="_blank"
              rel="noreferrer">
              Developer SDK License Agreement.
            </a>
          </Description>
          <Spacer y={80} />
        </div>

        <Graphic src={MainImage} alt="face graphic" width={727} />
      </Container>
    </Wrapper>
  );
};
