import FullHeightTable from '@/components/FullHeightTable';
import { DOCS_URLS } from '@/constants/urls';
import useAlert from '@/hooks/layouts/useAlert';
import useBlockchainNetworks from '@/hooks/useBlockchainNetworks';
import ContentLayout from '@/layouts/ContentLayout';
import DeleteTokenModal from '@/modals/DeleteTokenModal';
import RegisterTokenModal from '@/modals/RegisterTokenModal';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import {
  colors,
  Flexbox,
  IconExternalLink,
  IconTrash,
  radius,
  Spacer,
  typography,
} from '@haechi-labs/face-design-system';
import { Button, Divider, Modal, Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { startTransition, useState, useTransition } from 'react';
import { useParams } from 'react-router-dom';
import GuideGenerateCredentialModal from '@/modals/GuideGenerateCredentialModal';
import invariant from 'tiny-invariant';
import styled from '@emotion/styled';
import NotificationBox from '@/components/NotificationBox';
import { BusinessLicenseUpload, isValidBusinessLicense } from '@/components/BusinessLicenseUpload';
import { ReactComponent as CheckIcon } from '@/components/CheckIcon.svg';
import { useFaceMutation, useFaceQuery } from '@/hooks/useFaceQuery';
import { dappQuery, tokenQuery } from '@/hooks/useFaceQuery.queries';
import * as tokenApis from '@/apis/tokens';
import {
  turnOffBridge,
  turnOffOnRamp,
  turnOffSwap,
  turnOnBridge,
  turnOnOnRamp,
  turnOnSwap,
} from '@/apis/dapps';
import UpdateTokenModal from './UpdateTokenModal';
import getCurrentBlockchainNetworkPolicy from '@/utils/getCurrentBlockchainNetworkPolicy';
import { Network } from '@haechi-labs/face-types';
import placeholderThumbnail from '@/assets/imgs/placeholder-thumbnail.svg';

const TokenPage = () => {
  const { dappId } = useParams();
  invariant(dappId != null);
  const { showAlert } = useAlert();
  const dapp = useFaceQuery(dappQuery.getDappById, dappId);
  const { blockchainNetworkMap } = useBlockchainNetworks(dapp.apiKey ?? '');
  const originTokens = useFaceQuery(tokenQuery.getDappTokens, dappId);
  const tokens = originTokens
    .filter((token) => {
      // blockchainNetwork가 대문자로 오고 있어서 소문자로 변경함
      const lowerNetwork = token.blockchainNetwork.toLowerCase() as Network;
      if (!blockchainNetworkMap[lowerNetwork]) {
        console.error(`${lowerNetwork} is not supported network`);
      }
      return blockchainNetworkMap[lowerNetwork];
    })
    .map((token) => {
      return {
        ...token,
        blockchainNetwork: token.blockchainNetwork.toLowerCase() as Network,
      };
    });

  const [modal, setModal] = useState<
    | { type: 'none' }
    | { type: 'token-create' }
    | { type: 'token-update'; tokenId: string }
    | { type: 'token-delete'; tokenId: string }
    | { type: 'credential-generate-guide'; message: string }
  >({ type: 'none' });

  const removeTokenMutation = useFaceMutation(tokenApis.deleteDappToken, {
    onSuccess: () => {
      startTransition(() => {
        showAlert({
          type: 'success',
          target: 'contents',
          message: 'Token deleted successfully.',
        });
        setModal({ type: 'none' });
      });
    },
  });

  type Token = typeof tokens[number];
  const columns: ColumnsType<Token> = [
    {
      title: 'Blockchain Network',
      key: 'blockchain',
      ellipsis: true,
      width: 240,
      render: (value: Token) => {
        const { blockchainNetwork } = value;
        invariant(blockchainNetwork != null);
        const network = blockchainNetworkMap[blockchainNetwork];
        return <div>{network.name}</div>;
      },
    },
    {
      title: 'Token Symbol',
      key: 'blockchain',
      width: 144,
      render: (value: Token) => {
        const { blockchainNetwork, symbol } = value;
        invariant(blockchainNetwork != null);
        invariant(symbol != null);
        const network = blockchainNetworkMap[blockchainNetwork];
        return (
          <div style={{ display: 'flex', gap: 8 }}>
            <img
              src={network.icon}
              alt={`${network.name}-icon`}
              width={20}
              height={20}
              onError={(e) => {
                e.currentTarget.src = placeholderThumbnail;
              }}
            />
            {symbol}
          </div>
        );
      },
    },
    {
      title: 'Token Name',
      dataIndex: 'coinName',
      key: 'coinName',
      width: 200,
    },
    {
      title: 'Smart Contract Address',
      dataIndex: 'contractAddress',
      key: 'contractAddress',
      ellipsis: true,
    },
    {
      title: 'Swap',
      key: 'blockchain',
      align: 'center',
      width: 90,
      render: (token: Token) => {
        if (!tokenApis.tokenSupportSwap(token.blockchainNetwork)) {
          return null;
        }
        return (
          <CheckIconCenter>
            <CheckIcon />
          </CheckIconCenter>
        );
      },
    },
    {
      title: 'Bridge',
      key: 'blockchain',
      align: 'center',
      width: 90,
      render: (token: Token) => {
        if (!tokenApis.tokenSupportConvert(token.blockchainNetwork)) {
          return null;
        }
        return (
          <CheckIconCenter>
            <CheckIcon />
          </CheckIconCenter>
        );
      },
    },
    {
      title: 'On-Ramp',
      key: 'blockchain',
      align: 'center',
      width: 90,
      render: (token: Token) => {
        if (!tokenApis.tokenSupportOnRamp(token.blockchainNetwork)) {
          return null;
        }
        return (
          <CheckIconCenter>
            <CheckIcon />
          </CheckIconCenter>
        );
      },
    },
    {
      title: 'Delete',
      key: 'delete',
      width: 80,
      render: (token: Token) => {
        return (
          <Button
            className="legacy"
            type="text"
            icon={<IconTrash />}
            onClick={(event) => {
              event.stopPropagation();
              invariant(token.id != null);
              setModal({ type: 'token-delete', tokenId: token.id });
            }}
          />
        );
      },
    },
  ];

  return (
    <ContentLayout style={{ minWidth: 960 }}>
      <CardWrapper>
        <Card>
          <CardTitle>Swap</CardTitle>
          <CardDescription>
            The Swap function enables the exchange of one token for another in the Home Modal. Only
            token pairs registered in the token list of the Dashboard are eligible to swap.
          </CardDescription>
          <CardTopAction>
            <SwapSwitch
              dappId={dappId}
              openCredentialGuidance={() => {
                setModal({
                  type: 'credential-generate-guide',
                  message:
                    'You can activate additional functions AFTER generating the API Credentials.',
                });
              }}
            />
          </CardTopAction>
        </Card>
        <Card>
          <CardTitle>Bridge(Convert)</CardTitle>
          <CardDescription>
            The Bridge(Convert) function enables the conversion of tokens onto other networks in the
            Home Modal. Only token pairs registered in the token list of the Dashboard are eligible
            to bridge(convert).
          </CardDescription>
          <CardTopAction>
            <BridgeSwitch
              dappId={dappId}
              openCredentialGuidance={() => {
                setModal({
                  type: 'credential-generate-guide',
                  message:
                    'You can activate additional functions AFTER generating the API Credentials.',
                });
              }}
            />
          </CardTopAction>
        </Card>
        <Card>
          <CardTitle>
            On-Ramp(Buy)
            {dapp.onRampStatus === 'REQUESTING' && <CardChip>Requesting...</CardChip>}
          </CardTitle>
          <CardDescription>
            The On-Ramp(Buy) function enables token purchases using cards, easy payment services,
            etc. This feature can be accessed through the in-app modal(when funds are insufficient),
            the Home Modal(via the Top Up function) or the Buy Modal.
          </CardDescription>
          <CardTopAction>
            <OnRampSwitch
              dappId={dappId}
              openCredentialGuidance={() => {
                setModal({
                  type: 'credential-generate-guide',
                  message:
                    'You can activate additional functions AFTER generating the API Credentials.',
                });
              }}
            />
          </CardTopAction>
        </Card>
      </CardWrapper>

      <StyledFullHeightTable
        cardTitle={
          <>
            Token
            <Divider type="vertical" />
            <Button
              className="legacy"
              type="text"
              size="small"
              target="_blank"
              href={DOCS_URLS.tokens}
              style={{ marginLeft: '-4px' }}>
              <Flexbox gap={4}>
                Docs
                <IconExternalLink fill={colors.gray[600]} size="sm" />
              </Flexbox>
            </Button>
          </>
        }
        cardExtra={
          <Button
            className="legacy"
            type="text"
            icon={<PlusOutlined />}
            size="small"
            onClick={() => {
              if (dapp.apiKey == null) {
                setModal({
                  type: 'credential-generate-guide',
                  message: 'You can register your token only AFTER generating the API Credentials.',
                });
              } else {
                setModal({ type: 'token-create' });
              }
            }}>
            Register Token
          </Button>
        }
        locale={{ emptyText: 'Only registered tokens will be shown in the user wallet.' }}
        columns={columns}
        onRow={(_, rowIndex) => {
          if (rowIndex == null) {
            return {};
          }
          const token = tokens[rowIndex];
          invariant(token != null);
          const tokenId = token.id;
          invariant(tokenId != null);
          return {
            onClick() {
              setModal({ type: 'token-update', tokenId });
            },
          };
        }}
        dataSource={tokens.map((token) => ({
          ...token,
          key: token.id,
        }))}
      />

      <RegisterTokenModal
        id={dappId}
        open={modal.type === 'token-create'}
        onCancel={() => {
          setModal({ type: 'none' });
        }}
        closeModal={() => {
          setModal({ type: 'none' });
        }}
      />
      {modal.type === 'token-update' && (
        <UpdateTokenModal
          open
          onCancel={() => {
            setModal({ type: 'none' });
          }}
          closeModal={() => {
            setModal({ type: 'none' });
          }}
          dappId={dappId}
          id={modal.tokenId}
        />
      )}

      <DeleteTokenModal
        open={modal.type === 'token-delete'}
        onCancel={() => {
          setModal({ type: 'none' });
        }}
        onOk={() => {
          if (modal.type !== 'token-delete') {
            return;
          }
          const removeTargetToken = tokens.find((x) => x.id === modal.tokenId);
          invariant(removeTargetToken != null);
          return removeTokenMutation.commit({
            id: dappId,
            blockchainNetwork: removeTargetToken.blockchainNetwork,
            contractAddress: removeTargetToken.contractAddress,
          });
        }}
      />

      {modal.type === 'credential-generate-guide' && (
        <GuideGenerateCredentialModal
          open
          onCancel={() => {
            setModal({ type: 'none' });
          }}
          desc={modal.message}
        />
      )}
    </ContentLayout>
  );
};

export default TokenPage;

const CardWrapper = styled.div`
  display: flex;
  gap: 32px;
  margin-block-end: 32px;
`;

const Card = styled.div`
  flex: 1 1 0px;

  padding: 32px;
  padding-inline-end: 24px;
  background-color: ${colors.white};
  border-radius: ${radius.sm};

  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: min-content max-content;
  grid-template-areas:
    'title action'
    'content content';
`;

const CardTitle = styled.div`
  grid-area: title;
  ${typography.h4};
  font-weight: 600;
  color: ${colors.gray[900]};
  display: flex;
  white-space: pre;
`;

const CardChip = styled.div`
  ${typography.caption1.regular};
  color: ${colors.gray[500]};
  padding-inline: 6px;
  padding-block: 2px;
  border-radius: 4px;
  user-select: none;
  white-space: pre;
  background-color: ${colors.gray[100]};
  margin-inline: 10px;
`;

const CardDescription = styled.div`
  grid-area: content;
  margin-block-start: 10px;
  width: calc(100% - 60px);
  ${typography.caption1.regular};
  color: ${colors.gray[500]};
`;

const CardTopAction = styled.div`
  grid-area: action;
`;

const StyledFullHeightTable = styled(FullHeightTable)`
  .ant-table-row {
    cursor: pointer;
  }
`;

function OnRampRequestModal({
  open,
  onClose,
  dappId,
}: {
  open: boolean;
  onClose: () => void;
  dappId: string;
}) {
  const dapp = useFaceQuery(dappQuery.getDappById, dappId);
  const apiKey = dapp.apiKey;
  invariant(apiKey != null);
  const [isPending, startTransition] = useTransition();
  const [file, setFile] = useState<File | null>(null);
  const validBusinessLicense = file != null && isValidBusinessLicense(file);
  const turnOnOnRampMutation = useFaceMutation(turnOnOnRamp);

  return (
    <Modal
      title="Request to access On-Ramp(Buy)"
      open={open}
      onCancel={onClose}
      centered
      closable={false}
      footer={
        <>
          <Button
            onClick={() => {
              onClose();
            }}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!validBusinessLicense}
            loading={isPending}
            onClick={() => {
              invariant(file != null);
              startTransition(async () => {
                await turnOnOnRampMutation.commit({ businessLicense: file, id: dappId });
                onClose();
              });
            }}>
            Request
          </Button>
        </>
      }>
      <NotificationBox type="default" title="Notice">
        <ul>
          <li>
            The On-Ramp function can only be used by legal entities and requires KYB. Please upload
            your Business Registration Certificate (such as your Certificate of Incorporation).
          </li>
          <li>
            The Business Registration Certificate information will be provided to MoonPay, the
            On-Ramp service provider, for KYB purposes.
          </li>
          <li>
            Once KYB is completed, On-Ramp functionality will be activated. This may take 7+
            business days.
          </li>
        </ul>
      </NotificationBox>
      <Spacer y={16} />
      <BusinessLicenseField>
        <BusinessLicenseLabel>Upload Business Registration Certificate</BusinessLicenseLabel>
        <BusinessLicenseFormat>Format: jpg, png, pdf</BusinessLicenseFormat>
      </BusinessLicenseField>
      <Spacer y={4} />
      <BusinessLicenseUpload
        onSelectFile={(file) => setFile(file)}
        onUnselectFile={() => setFile(null)}
        file={file}
        invalid={!validBusinessLicense}
      />
    </Modal>
  );
}

const BusinessLicenseField = styled.label``;

const BusinessLicenseLabel = styled.div`
  ${typography.body2.medium};
  color: ${colors.gray[700]};
`;

const BusinessLicenseFormat = styled.div`
  ${typography.caption1.regular};
  color: ${colors.gray[500]};
`;

function OnRampSwitch({
  dappId,
  openCredentialGuidance,
}: {
  dappId: string;
  openCredentialGuidance: () => void;
}) {
  const [modal, setModal] = useState<'request' | 'turn-off' | null>(null);
  const dapp = useFaceQuery(dappQuery.getDappById, dappId);
  const turnOffOnRampMutation = useFaceMutation(turnOffOnRamp);
  const turnOnOnRampMutation = useFaceMutation(turnOnOnRamp);
  const { isMainnet } = getCurrentBlockchainNetworkPolicy();
  const [isPending, startTransition] = useTransition();

  return (
    <>
      <Switch
        disabled={isPending || dapp.onRampStatus === 'REQUESTING'}
        checked={
          dapp.onRampStatus === 'ON' ? true : dapp.onRampStatus === 'REQUESTING' ? true : false
        }
        onChange={(checked) => {
          // TODO: remove this when new api adopted
          if (dapp.apiKey == null) {
            openCredentialGuidance();
            return;
          }

          if (isMainnet) {
            if (checked) {
              setModal('request');
            } else {
              setModal('turn-off');
            }
          } else {
            if (checked) {
              startTransition(() => {
                return turnOnOnRampMutation.commit({ businessLicense: new Blob(), id: dappId });
              });
            } else {
              setModal('turn-off');
            }
          }
        }}
      />
      {dapp.apiKey != null && (
        <>
          <OnRampRequestModal
            open={modal === 'request'}
            onClose={() => setModal(null)}
            dappId={dappId}
          />
          <TurnOffOnRampModal
            open={modal === 'turn-off'}
            onClose={() => setModal(null)}
            turnOff={() => {
              return turnOffOnRampMutation.commit(dappId);
            }}
          />
        </>
      )}
    </>
  );
}

const CheckIconCenter = styled.div`
  display: grid;
  place-items: center;
`;

function SwapSwitch({
  dappId,
  openCredentialGuidance,
}: {
  dappId: string;
  openCredentialGuidance: () => void;
}) {
  const dapp = useFaceQuery(dappQuery.getDappById, dappId);
  const [modal, setModal] = useState<'turn-off' | null>(null);
  const [isPending, startTransition] = useTransition();
  const turnOnSwapMutation = useFaceMutation(turnOnSwap);
  const turnOffSwapMutation = useFaceMutation(turnOffSwap);

  return (
    <>
      <Switch
        checked={dapp.swapStatus === 'ON'}
        disabled={isPending}
        onChange={(checked) => {
          // TODO: remove this when new api adopted
          const { apiKey } = dapp;
          if (apiKey == null) {
            openCredentialGuidance();
            return;
          }

          if (!checked) {
            setModal('turn-off');
          } else {
            startTransition(() => {
              return turnOnSwapMutation.commit(dappId);
            });
          }
        }}
      />
      <TurnOffSwapModal
        open={modal === 'turn-off'}
        onClose={() => setModal(null)}
        turnOff={() => {
          return turnOffSwapMutation.commit(dappId);
        }}
      />
    </>
  );
}

function TurnOffSwapModal({
  open,
  onClose,
  turnOff,
}: {
  open: boolean;
  onClose: () => void;
  turnOff: () => Promise<void>;
}) {
  const [isPending, startTransition] = useTransition();

  return (
    <Modal
      open={open}
      title="Disable Swap"
      closable={false}
      centered
      footer={
        <>
          <Button
            onClick={() => {
              onClose();
            }}>
            Cancel
          </Button>
          <Button
            danger
            disabled={isPending}
            onClick={() => {
              startTransition(async () => {
                await turnOff();
                onClose();
              });
            }}>
            Confirm
          </Button>
        </>
      }>
      Do you really want to disable Swap? By disabling, the Swap feature will no longer appear in
      the Home modal.
    </Modal>
  );
}

function BridgeSwitch({
  dappId,
  openCredentialGuidance,
}: {
  dappId: string;
  openCredentialGuidance: () => void;
}) {
  const dapp = useFaceQuery(dappQuery.getDappById, dappId);
  const [modal, setModal] = useState<'turn-off' | null>(null);
  const [isPending, startTransition] = useTransition();
  const turnOnBridgeMutation = useFaceMutation(turnOnBridge);
  const turnOffBridgeMutation = useFaceMutation(turnOffBridge);

  return (
    <>
      <Switch
        checked={dapp.bridgeStatus === 'ON'}
        disabled={isPending}
        onChange={(checked) => {
          // TODO: remove this when new api adopted
          const { apiKey } = dapp;
          if (apiKey == null) {
            openCredentialGuidance();
            return;
          }

          if (!checked) {
            setModal('turn-off');
          } else {
            startTransition(() => {
              return turnOnBridgeMutation.commit(dappId);
            });
          }
        }}
      />
      <TurnOffBridgeModal
        open={modal === 'turn-off'}
        onClose={() => setModal(null)}
        turnOff={() => {
          return turnOffBridgeMutation.commit(dappId);
        }}
      />
    </>
  );
}

function TurnOffBridgeModal({
  open,
  onClose,
  turnOff,
}: {
  open: boolean;
  onClose: () => void;
  turnOff: () => Promise<void>;
}) {
  const [isPending, startTransition] = useTransition();

  return (
    <Modal
      open={open}
      title="Disable Bridge(Convert)"
      closable={false}
      centered
      footer={
        <>
          <Button
            onClick={() => {
              onClose();
            }}>
            Cancel
          </Button>
          <Button
            danger
            disabled={isPending}
            onClick={() => {
              startTransition(async () => {
                await turnOff();
                onClose();
              });
            }}>
            Confirm
          </Button>
        </>
      }>
      Do you really want to disable Bridge(Convert)? By disabling, the Bridge(Convert) feature will
      no longer appear in the Home modal.
    </Modal>
  );
}

function TurnOffOnRampModal({
  open,
  onClose,
  turnOff,
}: {
  open: boolean;
  onClose: () => void;
  turnOff: () => Promise<void>;
}) {
  const [isPending, startTransition] = useTransition();

  return (
    <Modal
      open={open}
      title="Disable On-Ramp(Buy)"
      closable={false}
      centered
      footer={
        <>
          <Button
            onClick={() => {
              onClose();
            }}>
            Cancel
          </Button>
          <Button
            danger
            loading={isPending}
            onClick={() => {
              startTransition(async () => {
                await turnOff();
                onClose();
              });
            }}>
            Confirm
          </Button>
        </>
      }>
      Do you really want to disable On-Ramp(Buy)? By disabling, the On-Ramp(Buy) feature will no
      longer appear in the Home, In-app and Buy modal.
    </Modal>
  );
}
