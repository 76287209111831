import Gnb from '@/components/Gnb';
import Sidebar from '@/components/Sidebar';
import { ReactNode, Suspense } from 'react';
import Layout from 'antd/lib/layout';
import { AlertContainer, GlobalAlertContainer, Header } from './index.styled';
import { SIDEBAR_WIDTH } from '@/constants/layout';

import { Alert } from 'antd';
import { useSubscribeAlertEvent } from '@/hooks/layouts/useAlert';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import FullScreenSpinner from '@/components/FullScreenLoader';

const { Content, Sider } = Layout;

interface DefaultLayoutProps {
  children?: ReactNode;
  showSidebar?: boolean;
}

const DefaultLayout = ({ children, showSidebar = false }: DefaultLayoutProps) => {
  const { currentAlertEvent } = useSubscribeAlertEvent('contents');
  const { currentAlertEvent: currentGlobalAlertEvent } = useSubscribeAlertEvent('global');

  return (
    <Layout style={{ height: '100vh', maxHeight: '100vh' }}>
      <GlobalAlertContainer>
        {currentGlobalAlertEvent && <Alert {...currentGlobalAlertEvent} />}
      </GlobalAlertContainer>
      <Header>
        <Gnb />
      </Header>
      <Layout style={{ flexDirection: 'row' }}>
        <Sider theme="light" width={SIDEBAR_WIDTH} collapsed={!showSidebar} collapsedWidth={0}>
          <Sidebar />
        </Sider>
        <Content
          style={{
            overflow: 'hidden',
            position: 'relative',
            background: '#F7F8F9',
          }}>
          <ErrorBoundary>
            <Suspense fallback={<FullScreenSpinner />}>
              <AlertContainer>
                {currentAlertEvent && <Alert {...currentAlertEvent} />}
              </AlertContainer>
              {children}
            </Suspense>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
