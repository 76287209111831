import styled from '@emotion/styled';
import { colors, radius, typography } from '@haechi-labs/face-design-system';

export const Container = styled.div`
  display: flex;
  background: ${colors.gray['200-24%']};
  border-radius: ${radius.sm};
  ${typography.body2.medium};
`;

export const KeyArea = styled.div`
  padding: 20px;
  word-break: break-all;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0 24px;

  .ant-btn-icon-only {
    &:hover {
      background: ${colors.white};
    }
  }
`;
