import ContentLayout from '@/layouts/ContentLayout';
import { Spacer } from '@haechi-labs/face-design-system';
import ApiKeySection from './ApiKeySection';
import AllowedDomainListSection from './AllowedDomainListSection';
import AllowedAppIdSection from './AllowedAppIdListSection';
import { Navigate, useParams } from 'react-router-dom';
import { useDappByIdQuery } from '@/hooks/queries/dapps';
import { AuthorizedRoutePath } from '@/constants/routes';
import useAlert from '@/hooks/layouts/useAlert';

const DappDetailPage = () => {
  const { dappId } = useParams();
  const { showAlert } = useAlert();
  const { data: dapp } = useDappByIdQuery(dappId as string, {
    suspense: true,
    onError: (error: any) => {
      if (!error?.silent) {
        showAlert({
          type: 'error',
          target: 'contents',
          message: 'Failed to load Dapp information. Please try again.',
          showIcon: true,
        });
      }
    },
  });

  if (!dapp || !dappId) {
    return <Navigate to={AuthorizedRoutePath.allDappsPage} />;
  }

  return (
    <ContentLayout>
      <ApiKeySection dapp={dapp} />
      <Spacer y={32} />
      <AllowedDomainListSection dapp={dapp} />
      <Spacer y={32} />
      <AllowedAppIdSection dapp={dapp} />
    </ContentLayout>
  );
};

export default DappDetailPage;
