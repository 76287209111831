export const MOON_PAY_BASE_URL = 'https://api.moonpay.com/';
export const MOON_PAY_SDK_URL = 'https://static.moonpay.com/web-sdk/v1/moonpay-web-sdk.min.js';
export const MOON_PAY_API_KEY = {
  SANDBOX: 'pk_test_Jn2vUZKW6bfIyFGf0VGVJ34sl3rLvqF',
  PRODUCTION: 'pk_live_JhKyxUbo7WMBsJ1YUEng9QUv0QtcLVTX',
};
export const MOON_PAY_DEFAULT_OPTIONS = {
  SANDBOX: {
    flow: 'buy', // buy, sell, nft
    variant: 'overlay',
    environment: 'sandbox',
  },
  PRODUCTION: {
    flow: 'buy', // buy, sell, nft
    variant: 'overlay',
    environment: 'production',
  },
};
