import { Helmet } from 'react-helmet';
import Mainnet from '@/assets/imgs/seo/img-og-mainnetmeta_img.png';

const MetaTag = () => {
  const title = 'Facewallet Dashboard';
  const description = 'Face the new Web - Onboarding 3 Billion Web3 Gamers';
  const image = Mainnet;
  const favicon = '/favicon.ico';
  const manifest = '/manifest.json';

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="icon" href={favicon} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="og:title" content={title} />
      <meta name="og:type" content="website" />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image} />
      <link rel="manifest" href={manifest} />
    </Helmet>
  );
};

export default MetaTag;
