import useAuthorizedRoutePaths from '@/hooks/useAuthorizedRoutePaths';
import { DashboardDappDTO } from '@/__generate__/dashboard-api';
import { Container, DappName } from './index.styled';
import DappThumbnail from '@/components/DappThumbnail';

interface DappItemProps {
  dapp: DashboardDappDTO;
}

const DappItem = ({ dapp }: DappItemProps) => {
  const authorizedPaths = useAuthorizedRoutePaths();

  return (
    <Container to={authorizedPaths[':dappId'].dappDetailPage.replace(':dappId', dapp.id)}>
      <DappThumbnail dapp={dapp} />
      <DappName>{dapp.name}</DappName>
    </Container>
  );
};

export default DappItem;
