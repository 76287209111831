import { Link, useLocation } from 'react-router-dom';
import Menu from 'antd/lib/menu';

import { SIDEBAR_WIDTH } from '@/constants/layout';
import useModal from '@/hooks/useModal';
import EditDappInfoModal from '@/modals/EditDappInfoModal';

import useAuthorizedRoutePaths from '@/hooks/useAuthorizedRoutePaths';
import { useDappByIdQuery, useDappsQuery } from '@/hooks/queries/dapps';
import { useMemo } from 'react';
import useCurrentDappId from '@/hooks/stores/useCurrentDappId';
import DappThumbnail from '../DappThumbnail';
import {
  IconEdit,
  IconHome,
  IconNft,
  IconToken,
  IconAccountBox,
  IconWeb,
  radius,
  Badge,
} from '@haechi-labs/face-design-system';
import { CurrentDappContainer, DappName } from './index.styled';
import { Button } from 'antd';

const Sidebar = () => {
  const location = useLocation();
  const { data: dapps = [] } = useDappsQuery();
  const { open, openModal, closeModal } = useModal();
  const authorizedPaths = useAuthorizedRoutePaths();
  const currentDappId = useCurrentDappId();
  const { data: currentDapp } = useDappByIdQuery(currentDappId);

  const appSwitchMenu = useMemo(() => {
    if (!currentDapp) return null;

    const subMenus = [
      {
        key: `${currentDapp.id}-current`,
        icon: <DappThumbnail dapp={currentDapp} size={24} radius={radius.sm} />,
        className: 'no-hover',
        label: (
          <CurrentDappContainer>
            <DappName style={{ flex: 1 }}>{currentDapp.name}</DappName>
            <Button type="text" icon={<IconEdit />} />
          </CurrentDappContainer>
        ),
        onClick: () => {
          openModal();
        },
      },
      {
        key: 'divider',
        type: 'divider',
      },
      {
        key: 'allDapps',
        label: <Link to={authorizedPaths.allDappsPage}>All Dapps</Link>,
        icon: <IconWeb />,
      },
      ...dapps.map((dapp) => ({
        key: `dapp-${dapp.id}`,
        label: (
          <DappName>
            <Link to={authorizedPaths[':dappId'].dappDetailPage.replace(currentDappId, dapp.id)}>
              {dapp.name}
            </Link>
          </DappName>
        ),
        icon: <DappThumbnail dapp={dapp} size={24} radius={radius.sm} />,
      })),
    ];

    return (
      currentDapp && {
        key: 'appSwitch',
        label: <DappName>{currentDapp.name}</DappName>,
        icon: <DappThumbnail dapp={currentDapp} size={24} radius={radius.sm} />,
        popupOffset: [16 - SIDEBAR_WIDTH, 16],
        children: subMenus,
      }
    );
  }, [authorizedPaths, currentDapp, currentDappId, dapps, openModal]);

  const items = useMemo(
    () => [
      appSwitchMenu,
      {
        key: authorizedPaths[':dappId'].dappDetailPage,
        icon: <IconHome />,
        label: <Link to={authorizedPaths[':dappId'].dappDetailPage}>Home</Link>,
      },
      {
        key: authorizedPaths[':dappId'].tokenPage,
        icon: <IconToken />,
        label: <Link to={authorizedPaths[':dappId'].tokenPage}>Tokens</Link>,
      },
      {
        key: authorizedPaths[':dappId'].nftPage,
        icon: <IconNft />,
        label: <Link to={authorizedPaths[':dappId'].nftPage}>NFTs</Link>,
      },
      {
        key: authorizedPaths[':dappId'].userInsightsPage,
        icon: <IconAccountBox />,
        label: (
          <Link to={authorizedPaths[':dappId'].userInsightsPage}>
            User Insights<Badge css={{ marginLeft: 8 }}>Beta</Badge>
          </Link>
        ),
      },
    ],
    [appSwitchMenu, authorizedPaths]
  );

  const selectedKey = useMemo(() => {
    return items.find((item) => location.pathname.slice(1) === item?.key)?.key;
  }, [items, location.pathname]);

  return (
    <div>
      <Menu
        items={items}
        triggerSubMenuAction="click"
        selectedKeys={selectedKey ? [selectedKey, `dapp-${currentDappId}`] : undefined}
      />
      <EditDappInfoModal
        dappId={currentDappId}
        open={open}
        onCancel={closeModal}
        closeModal={closeModal}
      />
    </div>
  );
};

export default Sidebar;
