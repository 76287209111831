import { StyledLogoImg, StyledPageDiv } from './loginEmailPage.styled';
import logoGray from '@/assets/imgs/logo-gray.svg';
import { ReactNode } from 'react';

export function LoginLayout({ children }: { children: ReactNode }) {
  return (
    <StyledPageDiv>
      {children}
      <StyledLogoImg alt={'face_wallet_logo_gray'} src={logoGray} />
    </StyledPageDiv>
  );
}
