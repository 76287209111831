import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons/lib/icons';
import { colors, Spacer, typography } from '@haechi-labs/face-design-system';
import { Button, Upload } from 'antd';
import styled from '@emotion/styled';

export function BusinessLicenseUpload({
  onSelectFile,
  onUnselectFile,
  file,
  invalid,
}: {
  onSelectFile?: (file: File) => void;
  onUnselectFile?: () => void;
  file?: File | null;
  invalid?: boolean;
}) {
  return (
    <div>
      <Upload
        onChange={(upload) => {
          if (upload.file.originFileObj == null) {
            return;
          }
          onSelectFile?.(upload.file.originFileObj);
        }}
        // disable default file list of <Upload />
        fileList={[]}
        customRequest={() => {}}>
        <StyledUploadButton
          className="without-global-override"
          icon={<UploadOutlined style={{ color: colors.gray[400] }} />}>
          Click to Upload
        </StyledUploadButton>
      </Upload>
      {file != null && (
        <>
          <Spacer y={8} />
          <SelectedItem data-file-upload-candidate-invalid={invalid}>
            <StyledPaperClip />
            <SelectedItemLabel>{file.name}</SelectedItemLabel>
            <DeleteButton
              aria-label="제거"
              onClick={() => {
                onUnselectFile?.();
              }}
              type="button">
              <StyledDeleteIcon />
            </DeleteButton>
          </SelectedItem>
        </>
      )}
    </div>
  );
}

export function isValidBusinessLicense(file: File) {
  const fileFormat = ['application/pdf', 'image/png', 'image/jpeg'];
  return fileFormat.includes(file.type);
}

const StyledUploadButton = styled(Button)`
  color: ${colors.gray[700]};
`;

const StyledPaperClip = styled(PaperClipOutlined)`
  margin-inline: 4px;
  color: ${colors.gray[400]};

  *:has([data-file-upload-candidate-invalid='true']) & {
    color: ${colors.red[500]};
  }
`;

const DeleteButton = styled.button`
  apperance: none;
  padding-inline: 8px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
`;

const StyledDeleteIcon = styled(DeleteOutlined)`
  color: ${colors.gray[400]};

  *:has([data-file-upload-candidate-invalid='true']) & {
    color: ${colors.red[500]};
  }
`;

const SelectedItem = styled.div`
  ${typography.caption1.regular};

  display: flex;
  align-items: center;
  gap: 4px;
`;

const SelectedItemLabel = styled.span`
  flex: 1;
  color: ${colors.blue[500]};

  *:has([data-file-upload-candidate-invalid='true']) & {
    color: ${colors.red[500]};
  }
`;
