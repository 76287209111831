import { pki, random } from 'node-forge';

export function urlEncode(pemKey: string) {
  const pemFormat = /-----(BEGIN|END) (RSA PRIVATE|EC PRIVATE|PRIVATE|PUBLIC) KEY-----/g;
  const base64Key = pemKey.replace(pemFormat, ''); // remove all whitespace characters from the key
  return base64Key
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/g, '')
    .replace(/ /g, '')
    .replace(/\r/g, '')
    .replace(/\n/g, '');
}

export const generateV1ApiKey = () => {
  const prng = random.createInstance();
  const pairKeys = pki.rsa.generateKeyPair({ bits: 1024, prng });
  const publicKeyPem = pki.publicKeyToPem(pairKeys.publicKey);
  const privateKeyPem = pki.privateKeyToPem(pairKeys.privateKey);

  const version = 'v1';
  const apiKey = `${urlEncode(publicKeyPem)}`;
  const secret = urlEncode(privateKeyPem);

  return { version, apiKey, secret };
};

export const generateV2ApiKey = async (): Promise<{
  version: string;
  apiKey: string;
  secret: string;
}> => {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'ECDSA',
      namedCurve: 'P-256',
    },
    true,
    ['sign', 'verify']
  );

  const publicKey = await window.crypto.subtle.exportKey('spki', keyPair.publicKey);
  const privateKey = await window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey);

  const version = 'v2';
  const apiKey = urlEncode(Buffer.from(publicKey).toString('base64'));
  const secret = urlEncode(Buffer.from(privateKey).toString('base64'));

  return { version, apiKey, secret };
};
