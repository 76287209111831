import { css } from '@emotion/react';
import { colors, typography } from '@haechi-labs/face-design-system';

const TableStyle = css`
  .ant-table {
    height: 100%;
  }

  .ant-pagination {
    flex-shrink: 0;
    flex-basis: 32px;
  }

  .ant-table-wrapper {
    .ant-table-thead {
      & > tr > th {
        background: none;
        border-top: 1px solid ${colors.gray[200]};
        border-bottom: 1px solid ${colors.gray[200]};
        padding: 18px 12px;
        ${typography.body2.bold};

        &::before {
          display: none;
        }
      }
    }

    .ant-table-cell {
      &:first-of-type {
        padding-left: 24px;
      }

      &:last-of-type {
        padding-right: 16px;
      }
    }

    .ant-table-tbody {
      tr > td.ant-table-cell {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .ant-spin-nested-loading {
    height: 100% !important;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    height: 100% !important;
  }

  .ant-table-container {
    display: flex;
    flex-direction: column;

    table > thead > tr:first-of-type {
      th:first-of-type {
        border-start-start-radius: 0 !important;
      }
    }

    table > thead > tr:last-of-type {
      th:last-of-type {
        border-start-end-radius: 0 !important;
      }
    }
  }

  .ant-table-body {
    display: flex;
    flex: 1;
  }

  .ant-table-wrapper {
    height: 100%;
  }

  .ant-table-row {
    height: 52px;
  }

  .ant-table-cell {
    .ant-btn-icon-only {
      background: ${colors.white};

      &:hover {
        background: ${colors.white};
      }
    }
  }

  .ant-table-placeholder {
    .ant-table-cell {
      color: ${colors.gray[400]};
      border-bottom: none !important;
      height: 140px;
    }
  }

  /* 페이지 건너뛰기 기능이 없는 스타일 */
  .no-pagination-jump {
    .ant-pagination-jump-next-custom-icon,
    .ant-pagination-jump-next-custom-icon + .ant-pagination-item {
      display: none !important;
    }
  }
`;
export default TableStyle;
