import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledNetworkIconImg = styled.img<{ size: number }>`
  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
    `}
`;
